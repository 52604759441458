import { memo, useCallback, useEffect, useState } from 'react';

import EditIcon from 'assets/svg/edit.svg';
import { RightOutlined } from '@ant-design/icons';

import { Col, notification, Row, Select, Switch } from 'antd';
import { gutter } from 'constants/styles';
import { translate } from 'config/i18n';
import { getCurrencyByLocale } from 'helpers/stringHelper';
import api from 'services/api';
import { EstablishmentPreferences } from 'interfaces/establishmentPreferences';
import { IOperationCategory, IOperationMode } from 'helpers/mapper';
import { Integration } from 'interfaces/integration';
import { useHistory } from 'react-router-dom';
import { Content } from '../styles';
import {
  Card,
  PriceField,
  Input,
  CustomSelect,
  SwitchContainer,
  IntegrationCard,
} from './styles';

interface IOperation {
  id: string;
  name: string;
}

function EstablishmentGeneralPreferences({
  establishmentId,
  readonly,
}: {
  establishmentId: string | undefined;
  readonly?: boolean;
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [savingLoading, setSavingLoading] = useState(false);
  const [integrations, setIntegrations] = useState<Integration[]>();
  const [
    establishmentPreferences,
    setEstablishmentPreferences,
  ] = useState<EstablishmentPreferences | null>(null);
  const [minValueInCardPayment, setMinValueInCardPayment] = useState(0);

  const [operationModesList, setOperationModeList] = useState<
    Array<IOperation>
  >([]);
  const [operationCategoriesList, setOperationCategoriesList] = useState<
    Array<IOperation>
  >([]);

  const [
    editingMinValueInCardPayment,
    setEditingMinValueInCardPayment,
  ] = useState(false);
  const [audienceCapacity, setAudienceCapacity] = useState(0);
  const [editingAudienceCapacity, setEditingAudienceCapacity] = useState(false);
  const [ageGroup, setAgeGroup] = useState(0);
  const [editingAgeGroup, setEditingAgeGroup] = useState(false);
  const [convenienceFeePercentage, setConvenienceFeePercentage] = useState(0);
  const [
    editingConvenienceFeePercentage,
    setEditingConvenienceFeePercentage,
  ] = useState(false);
  const [getNetRates, setGetNetRates] = useState<
    { creditRate: number; debitRate: number } | undefined
  >();

  const fillState = useCallback(data => {
    setEstablishmentPreferences({
      ...data.data,
      minValueInCardPayment: data.data.minValueInCardPayment / 100,
    });
    setMinValueInCardPayment(data.data.minValueInCardPayment / 100);
    setAudienceCapacity(data.data.audienceCapacity);
    setAgeGroup(data.data.ageGroup);
    setConvenienceFeePercentage(data.data.convenienceFeePercentage || 0);
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(
        `/trade/establishments/${establishmentId}/integrations/getnet`,
      );

      setGetNetRates(data.data);
    })();
  }, [establishmentId]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(
        `trade/establishments/${establishmentId}/integrations/`,
      );

      setIntegrations(data.data.filter((x: any) => !!x.config));
    })();
  }, [establishmentId]);

  useEffect(() => {
    async function getDataFromServer() {
      try {
        const [
          preferencesResponse,
          operationModesResponse,
          operationCategoriesResponse,
        ] = await Promise.all([
          api.get(`trade/establishments/${establishmentId}/preferences`),
          api.get('trade/operation-modes'),
          api.get('trade/operation-categories'),
        ]);

        fillState(preferencesResponse.data);

        setOperationModeList(
          operationModesResponse.data.data.map((x: IOperation) => ({
            ...x,
            name: IOperationMode[x.name],
          })),
        );

        setOperationCategoriesList(
          operationCategoriesResponse.data.data.map((x: IOperation) => ({
            ...x,
            name: IOperationCategory[x.name],
          })),
        );
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    }

    getDataFromServer();
  }, [establishmentId, fillState]);

  const handleChangePreferences = useCallback(
    async (param, callback?: () => void) => {
      setSavingLoading(true);
      try {
        const { data } = await api.put(
          `trade/establishments/${establishmentId}/preferences`,
          param,
        );

        fillState(data);
        if (callback) callback();
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setSavingLoading(false);
      }
    },
    [establishmentId, fillState],
  );

  const handleKeyDown = useCallback(
    (e: any, param: Record<string, unknown>, callback?: () => void) => {
      if (e.key === 'Enter') {
        handleChangePreferences(param, callback);
      }
    },
    [handleChangePreferences],
  );

  const getIntegrationName = (integration: Integration) => {
    return integration.type === 'external'
      ? integration.name
      : translate(`integration.${integration.slug}`);
  };

  return (
    <Content>
      <h1 className="title">{translate('establishment.general')}</h1>
      <Row gutter={gutter}>
        <Col md={24} lg={8}>
          <Card>
            <div>
              <span className="name">
                {translate('establishment.min_value_in_card_payment')}
              </span>
              {loading ? (
                <span className="loading-text">
                  {translate('general.loading')}
                  ...
                </span>
              ) : !editingMinValueInCardPayment ? (
                <span className="value">
                  {getCurrencyByLocale(minValueInCardPayment)}
                </span>
              ) : (
                <PriceField
                  disabled={savingLoading}
                  onKeyDown={(e: any) =>
                    handleKeyDown(e, { minValueInCardPayment }, () =>
                      setEditingMinValueInCardPayment(false),
                    )
                  }
                  onBlur={() =>
                    handleChangePreferences({ minValueInCardPayment }, () =>
                      setEditingMinValueInCardPayment(false),
                    )
                  }
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  className="ant-input"
                  defaultValue={
                    establishmentPreferences?.minValueInCardPayment || 0
                  }
                  onValueChange={({ value }: { value: string }) => {
                    const price = +(Number(value) * 100).toFixed();
                    setMinValueInCardPayment(price);
                  }}
                />
              )}
            </div>
            {!readonly && (
              <button
                className="btn-clean"
                type="button"
                onClick={() =>
                  setEditingMinValueInCardPayment(!editingMinValueInCardPayment)
                }
              >
                <img src={EditIcon} alt={translate('general.edit')} />
              </button>
            )}
          </Card>
        </Col>
        <Col md={24} lg={8}>
          <Card>
            <div>
              <span className="name">
                {translate('establishment.audience_capacity')}
              </span>
              {loading ? (
                <span className="loading-text">
                  {translate('general.loading')}
                  ...
                </span>
              ) : !editingAudienceCapacity ? (
                <span className="value">
                  {translate('establishment.audience_capacity_count', {
                    count: audienceCapacity,
                  })}
                </span>
              ) : (
                <Input
                  disabled={savingLoading}
                  onKeyDown={(e: any) =>
                    handleKeyDown(e, { audienceCapacity }, () =>
                      setEditingAudienceCapacity(false),
                    )
                  }
                  onBlur={() =>
                    handleChangePreferences({ audienceCapacity }, () =>
                      setEditingAudienceCapacity(false),
                    )
                  }
                  className="ant-input"
                  defaultValue={establishmentPreferences?.audienceCapacity || 0}
                  onChange={e => {
                    setAudienceCapacity(Number(e.target.value));
                  }}
                />
              )}
            </div>
            {!readonly && (
              <button
                className="btn-clean"
                type="button"
                onClick={() =>
                  setEditingAudienceCapacity(!editingAudienceCapacity)
                }
              >
                <img src={EditIcon} alt={translate('general.edit')} />
              </button>
            )}
          </Card>
        </Col>
        <Col md={24} lg={8}>
          <Card>
            <div>
              <span className="name">
                {translate('establishment.age_group')}
              </span>
              {loading ? (
                <span className="loading-text">
                  {translate('general.loading')}
                  ...
                </span>
              ) : !editingAgeGroup ? (
                <span className="value">
                  {`${ageGroup} ano${ageGroup > 1 ? 's' : ''}`}
                </span>
              ) : (
                <Input
                  disabled={savingLoading}
                  onKeyDown={(e: any) =>
                    handleKeyDown(e, { ageGroup }, () =>
                      setEditingAgeGroup(false),
                    )
                  }
                  onBlur={() =>
                    handleChangePreferences({ ageGroup }, () =>
                      setEditingAgeGroup(false),
                    )
                  }
                  className="ant-input"
                  defaultValue={establishmentPreferences?.ageGroup || 0}
                  onChange={e => {
                    setAgeGroup(Number(e.target.value));
                  }}
                />
              )}
            </div>
            {!readonly && (
              <button
                className="btn-clean"
                type="button"
                onClick={() => setEditingAgeGroup(!editingAgeGroup)}
              >
                <img src={EditIcon} alt={translate('general.edit')} />
              </button>
            )}
          </Card>
        </Col>
        <Col md={24} lg={8}>
          <Card>
            <div>
              <span className="name">
                {translate('establishment.convenience_fee_percentage')}
              </span>
              {loading ? (
                <span className="loading-text">
                  {translate('general.loading')}
                  ...
                </span>
              ) : !editingConvenienceFeePercentage ? (
                <span className="value">{`${convenienceFeePercentage} %`}</span>
              ) : (
                <Input
                  disabled={savingLoading}
                  onKeyDown={(e: any) =>
                    handleKeyDown(e, { convenienceFeePercentage }, () =>
                      setEditingConvenienceFeePercentage(false),
                    )
                  }
                  onBlur={() =>
                    handleChangePreferences({ convenienceFeePercentage }, () =>
                      setEditingConvenienceFeePercentage(false),
                    )
                  }
                  className="ant-input"
                  maxLength={2}
                  defaultValue={
                    establishmentPreferences?.convenienceFeePercentage || 0
                  }
                  value={convenienceFeePercentage}
                  onChange={e => {
                    const re = /^[0-9\b]+$/;

                    if (e.target.value === '' || re.test(e.target.value)) {
                      setConvenienceFeePercentage(Number(e.target.value));
                    }
                  }}
                />
              )}
            </div>
            {!readonly && (
              <button
                className="btn-clean"
                type="button"
                onClick={() =>
                  setEditingConvenienceFeePercentage(
                    !editingConvenienceFeePercentage,
                  )
                }
              >
                <img src={EditIcon} alt={translate('general.edit')} />
              </button>
            )}
          </Card>
        </Col>
        <Col md={24} lg={8}>
          <Card>
            <div>
              <span className="name">
                {translate('establishment.credit_rate')}
              </span>
              {loading ? (
                <span className="loading-text">
                  {translate('general.loading')}
                  ...
                </span>
              ) : (
                <span className="value disabled">{`${
                  getNetRates?.creditRate
                    ? `${getNetRates?.creditRate / 100} %`
                    : 'n/a'
                }`}</span>
              )}
            </div>
          </Card>
        </Col>
        <Col md={24} lg={8}>
          <Card>
            <div>
              <span className="name">
                {translate('establishment.debit_rate')}
              </span>
              {loading ? (
                <span className="loading-text">
                  {translate('general.loading')}
                  ...
                </span>
              ) : (
                <span className="value disabled">{`${
                  getNetRates?.debitRate
                    ? `${getNetRates?.debitRate / 100} %`
                    : 'n/a'
                }`}</span>
              )}
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={gutter} className="listContainer">
        <Col sm={24}>
          {loading ? (
            <span className="loading-text">
              {translate('general.loading')}
              ...
            </span>
          ) : (
            <>
              <SwitchContainer>
                <span>{translate('establishment.cash_management')}</span>
                <Switch
                  size="small"
                  disabled={readonly || savingLoading}
                  defaultChecked={establishmentPreferences?.register === true}
                  onChange={() => {
                    handleChangePreferences({
                      register: establishmentPreferences?.register !== true,
                    });
                  }}
                />
              </SwitchContainer>
              <SwitchContainer>
                <span>{translate('establishment.print_qrcode')}</span>
                <Switch
                  size="small"
                  disabled={readonly || savingLoading}
                  defaultChecked={
                    establishmentPreferences?.printQrCode === true
                  }
                  onChange={() => {
                    handleChangePreferences({
                      printQrCode:
                        establishmentPreferences?.printQrCode !== true,
                    });
                  }}
                />
              </SwitchContainer>
              <SwitchContainer>
                <span>{translate('establishment.card_machine_mode')}</span>
                <Switch
                  size="small"
                  disabled={readonly || savingLoading}
                  defaultChecked={
                    establishmentPreferences?.cardMachineMode === true
                  }
                  onChange={() => {
                    handleChangePreferences({
                      cardMachineMode:
                        establishmentPreferences?.cardMachineMode !== true,
                    });
                  }}
                />
              </SwitchContainer>
              <SwitchContainer>
                <span>{translate('establishment.reversal_in_cash')}</span>
                <Switch
                  size="small"
                  disabled={readonly || savingLoading}
                  defaultChecked={
                    establishmentPreferences?.reversalInCash === true
                  }
                  onChange={() => {
                    handleChangePreferences({
                      reversalInCash:
                        establishmentPreferences?.reversalInCash !== true,
                    });
                  }}
                />
              </SwitchContainer>
              <SwitchContainer>
                <span>{translate('establishment.sale_installments')}</span>
                <Switch
                  size="small"
                  disabled={readonly || savingLoading}
                  defaultChecked={
                    establishmentPreferences?.acceptProductSaleInstallments ===
                    true
                  }
                  onChange={() => {
                    handleChangePreferences({
                      acceptProductSaleInstallments:
                        establishmentPreferences?.acceptProductSaleInstallments !==
                        true,
                    });
                  }}
                />
              </SwitchContainer>

              <SwitchContainer>
                <span>{translate('establishment.show_brands_in_payment')}</span>
                <Switch
                  size="small"
                  disabled={readonly || savingLoading}
                  defaultChecked={
                    establishmentPreferences?.showBrandsInPayment === true
                  }
                  onChange={() => {
                    handleChangePreferences({
                      showBrandsInPayment:
                        establishmentPreferences?.showBrandsInPayment !== true,
                    });
                  }}
                />
              </SwitchContainer>

              <SwitchContainer>
                <span>{translate('establishment.device_will_transact')}</span>
                <Switch
                  size="small"
                  disabled={readonly || savingLoading}
                  defaultChecked={
                    establishmentPreferences?.deviceWillTransact === true
                  }
                  onChange={() => {
                    handleChangePreferences({
                      deviceWillTransact:
                        establishmentPreferences?.deviceWillTransact !== true,
                    });
                  }}
                />
              </SwitchContainer>
            </>
          )}
        </Col>
      </Row>

      <Row gutter={gutter} className="selectContainer">
        <Col sm={24} lg={6}>
          <span className="select-title">
            {translate('establishment.operation_category')}
          </span>
        </Col>
        <Col sm={24} lg={8}>
          {loading ? (
            <span className="loading-text">
              {translate('general.loading')}
              ...
            </span>
          ) : (
            <CustomSelect
              disabled={readonly || savingLoading}
              placeholder={translate('general.select')}
              defaultValue={establishmentPreferences?.operationCategoryId}
              onChange={value =>
                handleChangePreferences({ operationCategoryId: value })
              }
            >
              {operationCategoriesList.map(opCategory => (
                <Select.Option key={opCategory.id} value={opCategory.id}>
                  {opCategory.name}
                </Select.Option>
              ))}
            </CustomSelect>
          )}
        </Col>
      </Row>

      <Row gutter={gutter} className="selectContainer">
        <Col sm={24} lg={6}>
          <span className="select-title">
            {translate('establishment.operation_mode')}
          </span>
        </Col>
        <Col sm={24} lg={8}>
          {loading ? (
            <span className="loading-text">
              {translate('general.loading')}
              ...
            </span>
          ) : (
            <CustomSelect
              disabled={readonly || savingLoading}
              placeholder={translate('general.select')}
              defaultValue={establishmentPreferences?.operationModeId}
              onChange={value =>
                handleChangePreferences({ operationModeId: value })
              }
            >
              {operationModesList.map(opMode => (
                <Select.Option key={opMode.id} value={opMode.id}>
                  {opMode.name}
                </Select.Option>
              ))}
            </CustomSelect>
          )}
        </Col>
      </Row>

      <Row gutter={gutter} className="integrations">
        <h1 className="title">Aplicações habilitadas</h1>
        {integrations?.map(x => (
          <Col key={x.slug} sm={12} md={8}>
            <IntegrationCard>
              <div>
                <span>{getIntegrationName(x)}</span>
                <span>{x.category}</span>
              </div>
              {x.type === 'external' && (
                <button type="button">
                  <RightOutlined
                    onClick={() => {
                      history.push(`/integrations/${x.slug.replace('-', '')}`, {
                        integrationName: getIntegrationName(x),
                        config: x.config,
                      });
                    }}
                  />
                </button>
              )}
            </IntegrationCard>
          </Col>
        ))}
      </Row>
    </Content>
  );
}

EstablishmentGeneralPreferences.defaultProps = {
  readonly: true,
};

export default memo(EstablishmentGeneralPreferences);
