import styled from 'styled-components';
import {
  Input as AntdInput,
  Button as ShadowedButton,
  Select as Slct,
} from 'antd';

export const LoadingContainer = styled.div`
  text-align: center;
`;

export const IFrame = styled.iframe`
  width: 100%;
  margin-bottom: 40px;
  border: 0;

  &.sales {
    height: 1400px;
  }

  &.employees {
    height: 800px;
  }
`;

export const Input = styled(AntdInput)`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 18px;
  height: 40px;
`;

export const Button = styled(ShadowedButton)`
  text-shadow: none;
  box-shadow: none;
  width: 100%;
  margin-top: 13px;
`;

export const Select = styled(Slct)`
  font-size: 18px;
`;
