import { translate } from 'config/i18n';

import NotFoundImage from 'assets/svg/404.svg';

import { useHistory } from 'react-router-dom';
import { Container, Button } from './styles';
import { LoginLayout } from '../Layout';

function NotFound(): JSX.Element {
  const history = useHistory();

  return (
    <LoginLayout>
      <Container>
        <img src={NotFoundImage} alt="404" />
        <h1>{translate('general_messages.page_not_found')}</h1>
        <p>{translate('general_messages.url_does_not_exist')}</p>

        <Button onClick={() => history.push('/')} type="primary">
          {translate('auth.sign_in')}
        </Button>
      </Container>
    </LoginLayout>
  );
}

export default NotFound;
