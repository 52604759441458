import styled from 'styled-components';

interface CampaignTypeProps {
  selected: boolean;
}

export const Container = styled.div<CampaignTypeProps>`
  border: 1px solid #ececec;
  height: 86px;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${({ selected }) => (selected ? '#fe6a21' : '#FFF')};

  box-shadow: ${({ selected }) =>
    selected ? '0px 16px 30px -12px rgba(255, 117, 32, 0.7);' : 'none'};

  span {
    font-weight: 400;
    font-size: 23px;
    color: ${({ selected }) => (selected ? '#FFF' : '#67687a')};
    margin-left: 15px;
  }

  @media (max-width: 1024px) {
    img {
      width: 50px;
    }

    span {
      margin-left: 4px;
    }
  }
`;
