import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { ReduxStore } from 'interfaces/reduxStore';
import { Establishment } from 'interfaces/establishment';
import { useSelector } from 'react-redux';

import AuthRoute from 'routes/AuthRoute';

import SalesDashboard from './Dashboard/sales';
import EmployeesDashboard from './Dashboard/employees';
import Profile from './Profile';

import Employees from './Employee/List';
import EstablishmentInvites from './Employee/Invites';

import Establishments from './Establishment/List';
import EstablishmentsForm from './Establishment/Form';
import EstablishmentsDetail from './Establishment/Detail';
import EstablishmentsPreferences from './Establishment/Detail/preferences';
import EstablishmentRelationship from './Establishment/Relationship';

import Operations from './Operation/List';

import Products from './Product/List';
import ProductsForm from './Product/Form';
import Taxes from './Product/Taxes';

import Campaigns from './Campaign/List';
import CampaignsForm from './Campaign/Form';

import ProductCategories from './ProductCategory/List';

import Invites from './Invite/List';
import AcceptInvite from './Invite/Accept';

import Integrations from './Establishment/Integrations';
import ZoopConfiguration from './Establishment/Integrations/Configs/Zoop';
import FanAppConfiguration from './Establishment/Integrations/Configs/FanApp';
import ENotasConfiguration from './Establishment/Integrations/Configs/Enotas';
import GetnetConfiguration from './Establishment/Integrations/Configs/Getnet';

import Sales from './PDV/List';
import Reversal from './PDV/Reversal';

import NotFound from './NotFound';

function Authenticated(): JSX.Element {
  const location = useLocation();
  const establishment = useSelector<ReduxStore, Establishment | null>(
    state => state.establishment.currentEstablishment,
  );

  return (
    <Switch>
      <Redirect from="/" exact to="/establishments" />
      <Route path="/establishments" exact component={Establishments} />
      <Route path="/establishments/create" component={EstablishmentsForm} />
      <Route
        path="/establishments/relationship/:establishmentId"
        component={EstablishmentRelationship}
      />
      <Route path="/invites" exact component={Invites} />
      <Route path="/invites/:code" component={AcceptInvite} />

      <Route path="/profile" component={Profile} />

      {establishment ? (
        <Switch>
          <AuthRoute
            path="/integrations"
            exact
            requiredRoles={['admin']}
            component={Integrations}
          />
          <AuthRoute
            path="/integrations/zoop"
            exact
            requiredRoles={['admin']}
            component={ZoopConfiguration}
          />
          <AuthRoute
            path="/integrations/fanapp"
            exact
            requiredRoles={['admin']}
            component={FanAppConfiguration}
          />
          <AuthRoute
            path="/integrations/enotas"
            exact
            requiredRoles={['admin']}
            component={ENotasConfiguration}
          />
          <AuthRoute
            path="/integrations/getnet"
            exact
            requiredRoles={['admin']}
            component={GetnetConfiguration}
          />

          <AuthRoute
            path="/dashboard"
            exact
            requiredRoles={['admin', 'operationalManager', 'financialManager']}
            component={SalesDashboard}
          />
          <AuthRoute
            path="/dashboard/employees"
            requiredRoles={['admin', 'operationalManager', 'financialManager']}
            component={EmployeesDashboard}
          />

          <AuthRoute
            path="/employees"
            exact
            requiredRoles={['admin', 'operationalManager']}
            component={Employees}
          />
          <AuthRoute
            path="/employees/invites"
            requiredRoles={['admin', 'operationalManager']}
            component={EstablishmentInvites}
          />

          <AuthRoute
            path="/establishments/update/:establishmentId"
            requiredRoles={['admin']}
            component={EstablishmentsForm}
          />
          <AuthRoute
            path="/establishment"
            exact
            requiredRoles={['admin', 'operationalManager', 'financialManager']}
            component={EstablishmentsDetail}
          />
          <AuthRoute
            path="/establishment/preferences"
            requiredRoles={['admin']}
            component={EstablishmentsPreferences}
          />

          <AuthRoute
            path="/operations"
            exact
            requiredRoles={['admin', 'operationalManager']}
            component={Operations}
          />

          <AuthRoute
            path="/products"
            exact
            requiredRoles={['admin', 'operationalManager', 'stockist']}
            component={Products}
          />
          <AuthRoute
            path="/products/create"
            requiredRoles={['admin', 'operationalManager', 'stockist']}
            component={ProductsForm}
          />
          <AuthRoute
            path="/products/update/:productId"
            requiredRoles={['admin', 'operationalManager', 'stockist']}
            component={ProductsForm}
          />
          <AuthRoute
            path="/products/:productId/taxes"
            requiredRoles={['admin', 'operationalManager', 'stockist']}
            component={Taxes}
          />

          <AuthRoute
            path="/campaigns"
            exact
            requiredRoles={['admin']}
            component={Campaigns}
          />
          <AuthRoute
            path="/campaigns/create"
            requiredRoles={['admin']}
            component={CampaignsForm}
            exact
          />
          <AuthRoute
            path="/campaigns/create/:campaignId"
            requiredRoles={['admin']}
            component={CampaignsForm}
          />
          <AuthRoute
            path="/campaigns/update/:campaignId"
            requiredRoles={['admin']}
            component={CampaignsForm}
          />

          <AuthRoute
            path="/products/product-categories"
            exact
            requiredRoles={['admin', 'operationalManager']}
            component={ProductCategories}
          />

          <AuthRoute
            path="/pdv"
            exact
            requiredRoles={['admin', 'operationalManager', 'financialManager']}
            component={Sales}
          />

          <AuthRoute
            path="/pdv/reversal/:saleId"
            requiredRoles={['admin', 'operationalManager', 'financialManager']}
            component={Reversal}
          />

          <Route component={NotFound} />
        </Switch>
      ) : (
        <Redirect
          from="*"
          exact
          to={`/establishments?redirect=${location.pathname}`}
        />
      )}
    </Switch>
  );
}

export default Authenticated;
