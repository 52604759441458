import styled from 'styled-components';
import {
  Select as AntdSelect,
  Input as AntdInput,
  Button as ShadowedButton,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { isDark, isHexColor } from 'lib/color';
import Dropzone from 'react-dropzone';

export const FormContainer = styled.div`
  .ant-select-selector,
  textarea {
    font-family: 'Titillium Web', sans-serif;
    border-radius: 2px !important;
    border-width: 1px !important;
  }

  .ant-input-wrapper.ant-input-group input {
    font-family: 'Titillium Web', sans-serif;
    border-radius: 2px !important;
    border-width: 1px !important;
  }

  .dropzone {
    margin-bottom: 24px;
  }
`;

export const Section = styled.div`
  &:not(:last) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 30px 0 20px 0;
  }

  textarea {
    resize: none;
  }

  .ant-form-item-control-input-content > .ant-input {
    padding-top: 8.4px;
    padding-bottom: 8.4px;
  }
`;

export const ColorBoxInput = styled.div`
  position: relative;

  .ant-input-group-addon {
    width: 70px;
  }

  .picker-icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-input-group-addon {
    background: ${props => (props.color ? props.color : 'transparent')};
    border-top: 1px solid #d9d9d9 !important;
    border-left: 1px solid #d9d9d9 !important;
    border-bottom: 1px solid #d9d9d9 !important;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .twitter-picker.picker {
    top: calc(100%);
    left: 12px;
    position: absolute !important;
    z-index: 2;
  }

  span {
    font-size: 20px;
    cursor: pointer;

    color: ${props =>
      props.color &&
      (isHexColor(props.color, true) && isDark(props.color, 200)
        ? '#ffffff'
        : '#333333')};
  }
`;

export const SwitchContainer = styled.div`
  height: calc(100% - 24px);
  display: flex;
  align-items: center;

  span {
    font-family: 'Titillium Web', sans-serif;
    font-weight: bold;
    margin-left: 8px;
    font-size: 18px;
  }
`;

export const SectionTitle = styled.h1`
  font-family: 'Titillium Web', sans-serif;
  color: #000;
  font-weight: 600;
`;

export const Input = styled(AntdInput)`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 18px;
  height: 60px;
  border-radius: 2px;
  border-width: 1px;
`;

export const Select = styled(AntdSelect)``;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;

  max-width: 1500px;
  margin: 0 auto;

  button + button {
    margin-left: 20px;
  }
`;

export const Button = styled(ShadowedButton)`
  text-shadow: none;
  box-shadow: none;
`;

export const SearchIcon = styled(SearchOutlined)`
  color: #ff6d08;
  font-size: 20px;
  margin-right: 10px;
`;

export const ImageUpload = styled(Dropzone)`
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 30px;
    background: #ffffff;
    border-radius: var(--radius-size);
    box-shadow: var(--box-shadow);
    border: thick dashed;

    cursor: pointer;

    img {
      height: 180px;
      object-fit: contain;
    }

    p {
      margin: 20px 0 0;
    }
  }
`;

export const Label = styled.label`
  color: black;
`;
