import styled from 'styled-components';
import { Button as btn, Modal as antdModal } from 'antd';

export const Modal = styled(antdModal)`
  div.align-center {
    text-align: center;
  }

  width: 600px !important;

  span.ant-modal-close-x {
    color: #fe6a21;
  }

  div.modal-content {
    border-radius: 15px;
    padding: 15px;
    padding-top: 10px;
    margin: 15px 0;
  }

  div.modal-header {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    padding-bottom: 15px;
    color: #fe6a21;
  }

  .select-roles {
    width: 100%;
  }

  .ant-select-selection-item {
    opacity: 0.4;
  }

  .ant-select-selector {
    background: #f6f6f6;
  }

  .ant-select-arrow {
    color: #fe6a21;
  }

  .unit-cost-container {
    position: relative;
  }
`;

export const Button = styled(btn)`
  max-width: 240px;
  margin: 0 auto;
`;

export const Label = styled.label`
  font-size: 18px;
  margin-bottom: 5px;
  margin-left: 5px;
  display: block;

  input + & {
    margin-top: 20px;
  }
`;
