import styled, { css } from 'styled-components';

interface ImageUploadProps {
  isDragActive: boolean;
  hasLogoPreview: boolean;
  $width: number | undefined;
  rounded: boolean | undefined;
  $loading: boolean | undefined;
  hasPreview: boolean | undefined;
}

export const Indicator = styled.div`
  background: #ff6d08;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  transform: translateX(40%);
  transform: translateY(40%);

  img {
    width: 70% !important;
    height: 70% !important;
    margin: 0 !important;
  }
`;

export const ImageUpload = styled.div<ImageUploadProps>`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100px;
  padding: 30px;
  background: #fff0ea;
  position: relative;
  outline: 0;
  border-radius: 5px;

  span {
    color: #000;
    font-size: 18px;
  }

  .anticon.anticon-cloud-upload {
    font-size: 40px;
    color: #ff6d08;
    margin-right: 30px;
  }

  button {
    background: #ff6d08;
    margin-left: auto;
    color: #fff;
    border-radius: 5px;
    height: 40px;
    padding: 0 15px;
  }

  ${props =>
    props.isDragActive
      ? css`
          border: thick dashed #ff6d08;
        `
      : css`
          border: thick dashed transparent;
        `}

  ${props =>
    props.hasLogoPreview &&
    css`
      padding: 0;
      background: transparent;
    `}

  ${props =>
    props.$width &&
    css`
      width: ${props.$width}px;
    `}
  ${props =>
    props.$width &&
    css`
      height: ${props.$width}px;
    `}

  cursor: pointer;
  border: 2px dashed #ff6d08;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${props =>
      props.rounded
        ? css`
            border-radius: 50%;
          `
        : css`
            border-radius: 5px;
          `}

    ${props =>
      props.$loading
        ? css`
            opacity: 0.6;
          `
        : css`
            opacity: 1;
          `}
  }

  p {
  }

  button.image-clear-btn {
    outline: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #ff6d08;
    border: 1px solid #ff6d08 !important;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    position: absolute !important;
    top: -13px;
    right: -12px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

    span {
      top: -7%;
      position: absolute;
      left: 37%;
    }
  }

  button.image-clear-btn.rounded {
    right: -6px;

    span {
      top: -8px;
      right: -0px;
    }
  }
`;
