import { useState, useEffect, useCallback } from 'react';
import { Form, Select, Row, Col, notification } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';

import { create, update } from 'store/ducks/product';

import { Store } from 'antd/lib/form/interface';
import api from 'services/api';
import Page from 'components/Page';
import FloatLabel from 'components/FloatLabel';
import Dropzone from 'components/Dropzone';

import { secureGoBack } from 'helpers/routeHelper';
import {
  Product,
  ProductBrand,
  ProductCategory,
  ProductState,
} from 'interfaces/product';
import { gutter } from 'constants/styles';
import { EstablishmentState } from 'interfaces/establishment';
import { isPositiveNumber } from 'services/validation';
import { translate } from 'config/i18n';
import { RuleObject } from 'antd/lib/form';
import { Modules } from 'constants/modules';
import { usePermissions } from 'hooks/usePermissions';
import {
  FormContainer,
  Section,
  SectionTitle,
  Input,
  Header,
  Button,
  PricePrefix,
  PriceField,
} from './styles';

interface INavParams {
  productId: string;
}

interface IPriceValues {
  value: string;
}

function ProductForm(): JSX.Element {
  const { productId } = useParams<INavParams>();
  const [form] = Form.useForm();
  const { has } = usePermissions();
  const history = useHistory();
  const lastLocation = useLastLocation();
  const { loading } = useSelector<ReduxStore, ProductState>(
    state => state.product,
  );
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );
  const dispatch = useDispatch();

  const [product, setProduct] = useState({} as Product);
  const [logoPreview, setLogoPreview] = useState<string | null | undefined>('');
  const [imageData, setImageData] = useState<File | null>(null);
  const [categoryList, setCategoryList] = useState<Array<ProductCategory>>([]);
  const [brandList, setBrandList] = useState<Array<ProductBrand>>([]);
  const [preLoading, setPreLoading] = useState(true);

  useEffect(() => {
    async function getDataFromServer() {
      try {
        const promises = [];

        promises.push(
          api.get(
            `trade/establishments/${currentEstablishment?.id}/product-categories`,
          ),
        );
        promises.push(
          api.get(
            `trade/establishments/${currentEstablishment?.id}/product-brands`,
          ),
        );
        if (productId) promises.push(api.get(`trade/products/${productId}`));

        const responses = await Promise.all(promises);

        setCategoryList(responses[0].data?.data);
        setBrandList(responses[1].data?.data);

        if (productId) {
          setLogoPreview(responses[2].data.data.image?.location || '');

          setProduct({
            ...responses[2].data.data,
            price: responses[2].data.data.price / 100,
          });
        }
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setPreLoading(false);
      }
    }

    getDataFromServer();
  }, [productId, currentEstablishment]);

  const handleForm = useCallback(
    (data: Store) => {
      function handleSuccess(backRoute: string) {
        notification.success({
          message: translate('product.was_saved'),
          description: translate('general_messages.was_saved', {
            context: 'product.title',
          }),
        });
        secureGoBack(history, lastLocation, backRoute);
      }

      let price =
        typeof data.price !== 'number'
          ? Number(data.price.replace(/\./g, '').replace(',', '.'))
          : data.price;

      price = +(price * 100).toFixed();

      let unitCost =
        typeof data.unitCost !== 'number' && data.unitCost
          ? Number(data.unitCost.replace(/\./g, '').replace(',', '.'))
          : data.unitCost;

      unitCost = +(unitCost * 100).toFixed();

      const brand = !data.productBrandId
        ? null
        : brandList.find(x => x.id === data.productBrandId)?.name;

      const input: Product = {
        id: productId,
        name: data.name,
        description: data.description,
        price,
        stock: data.stock,
        unitCost,
        establishmentProductCategoryId: data.establishmentProductCategoryId,
      };

      if (productId || !has([Modules.STOCK])) {
        delete input.unitCost;
        delete input.stock;
      }

      if (brand) input.brand = brand;

      if (!productId) {
        dispatch(
          create(currentEstablishment?.id as string, input, imageData, () =>
            handleSuccess('/products'),
          ),
        );
      } else
        dispatch(
          update(
            input,
            imageData,
            () => handleSuccess('/products'),
            !logoPreview,
          ),
        );
    },
    [
      dispatch,
      has,
      productId,
      history,
      lastLocation,
      currentEstablishment,
      imageData,
      brandList,
      logoPreview,
    ],
  );

  const handleImageDrop = useCallback(acceptedFiles => {
    const imgData = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setLogoPreview(reader?.result?.toString());
      setImageData(imgData);
    };
    reader.readAsDataURL(imgData);
  }, []);

  return (
    <Page
      displayDrawer
      title={translate('product.form_title', {
        mode: productId
          ? translate('product.editing')
          : translate('product.register'),
      })}
      showBackButton
      loading={preLoading}
      SubHeader={
        <Header>
          <Button
            onClick={() => {
              secureGoBack(history, lastLocation, '/products');
            }}
            htmlType="button"
            type="default"
          >
            {translate('general.cancel')}
          </Button>

          <Button
            disabled={loading}
            onClick={() => {
              form.submit();
            }}
            htmlType="submit"
            type="primary"
          >
            {loading ? translate('general.saving') : translate('general.save')}
          </Button>
        </Header>
      }
    >
      <FormContainer>
        <Form initialValues={product} form={form} onFinish={handleForm}>
          <Section>
            <SectionTitle>
              Geral
              <div />
            </SectionTitle>
            <Row gutter={gutter}>
              <Col sm={20} md={6}>
                <Dropzone
                  alt={translate('product.product_image')}
                  onDropAccepted={handleImageDrop}
                  onClean={(e: any) => {
                    e.stopPropagation();
                    setLogoPreview('');
                  }}
                  logoPreview={logoPreview}
                />
              </Col>

              <Col sm={20} md={18}>
                <FloatLabel label={`${translate('product.name')}*`}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'validation_messages.required_field',
                          { field: 'product.name' },
                        ),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </FloatLabel>

                <Form.Item
                  name="establishmentProductCategoryId"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'product.category',
                      }),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) => {
                      const rawOptionValue = option?.children
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase();
                      const rawInputValue = input
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase();

                      return rawOptionValue.indexOf(rawInputValue) >= 0;
                    }}
                    placeholder={`${translate('product.category')}*`}
                  >
                    {categoryList.map(category => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="productBrandId">
                  <Select
                    showSearch
                    filterOption={(input, option) => {
                      const rawOptionValue = option?.children
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase();
                      const rawInputValue = input
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase();

                      return rawOptionValue.indexOf(rawInputValue) >= 0;
                    }}
                    placeholder={translate('product.brand')}
                    onSearch={e => {
                      const newBrand = brandList.find(y => y.id === 'new');

                      if (e) {
                        if (newBrand)
                          setBrandList(x =>
                            x.map(y =>
                              y.id === 'new' ? { ...newBrand, name: e } : y,
                            ),
                          );
                        else setBrandList(x => [{ id: 'new', name: e }, ...x]);

                        form.setFieldsValue({
                          productBrandId: 'new',
                        });
                      }
                    }}
                  >
                    {brandList.map(brand => (
                      <Select.Option key={brand.id} value={brand.id}>
                        {brand.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <FloatLabel label={translate('product.price')} padding={50}>
                  <PricePrefix>{translate('general.price_prefix')}</PricePrefix>
                  <Form.Item
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'validation_messages.required_field',
                          { field: 'product.price' },
                        ),
                      },
                      { validator: isPositiveNumber },
                    ]}
                  >
                    <PriceField
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale={false}
                      className="ant-input"
                      onValueChange={({ value }: IPriceValues) => {
                        // setPrice(Number(value));
                        form.setFieldsValue({
                          price: Number(value),
                        });
                      }}
                    />
                  </Form.Item>
                </FloatLabel>

                <FloatLabel label={translate('product.description')}>
                  <Form.Item name="description">
                    <Input />
                  </Form.Item>
                </FloatLabel>
              </Col>
            </Row>
            {!productId && has([Modules.STOCK]) && (
              <>
                <SectionTitle>
                  Estoque
                  <div />
                </SectionTitle>
                <Row gutter={gutter}>
                  <Col className="label-container" sm={20} md={6}>
                    <label>Quantidade de unidades</label>
                  </Col>
                  <Col sm={20} md={6}>
                    <Form.Item
                      name="stock"
                      rules={[
                        {
                          required: true,
                          message: translate(
                            'validation_messages.required_field',
                            {
                              field: 'product.unit_quantity',
                            },
                          ),
                        },
                        { validator: isPositiveNumber },
                      ]}
                    >
                      <Input
                        className="clean-button"
                        placeholder={translate('general.eg', { eg: '100' })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={gutter}>
                  <Col className="label-container" sm={20} md={6}>
                    <label>{translate('product.unit_cost')}</label>
                  </Col>
                  <Col sm={20} md={6}>
                    <PricePrefix className="ml">
                      {translate('general.price_prefix')}
                    </PricePrefix>
                    <Form.Item
                      name="unitCost"
                      rules={[
                        { validator: isPositiveNumber },
                        {
                          validator: (
                            _rule: RuleObject,
                            value: string,
                            callback: (error?: string) => void,
                          ) => {
                            if (form.getFieldValue('stock') && !value) {
                              callback(
                                translate(
                                  'validation_messages.required_field',
                                  {
                                    field: 'product.unit_cost',
                                  },
                                ),
                              );
                            } else {
                              callback();
                            }
                          },
                        },
                      ]}
                    >
                      <PriceField
                        thousandSeparator="."
                        decimalSeparator=","
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={false}
                        className="ant-input clean-button"
                        onValueChange={({ value }: IPriceValues) => {
                          form.setFieldsValue({
                            unitCost: Number(value),
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Section>
        </Form>
      </FormContainer>
    </Page>
  );
}

export default ProductForm;
