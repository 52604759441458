import styled from 'styled-components';
import { Modal as antdModal } from 'antd';

export const Modal = styled(antdModal)`
  div.align-center {
    text-align: center;
  }

  width: 650px !important;
  margin-top: -50px;

  span.ant-modal-close-x {
    color: #fe6a21;
  }

  div.modal-content {
    border-radius: 15px;
    padding: 15px;
    padding-top: 10px;
    font-weight: 500;
    text-align: center;
    font-size: 23px;
    color: black;

    .grid {
      margin: 44px 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 28px;

      .card {
        padding: 16px 23px 16px 23px;
        border-radius: 4px;
        border: 1px solid #ececec;

        span {
          text-align: left;
        }
      }

      span {
        font-size: 16px;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 29px 0;
      flex: 1;

      span:first-of-type {
        font-size: 18px;
        font-weight: bold;
        color: black;
      }

      span:last-of-type {
        font-size: 16px;
        font-weight: bold;
        color: #fe6a21;
      }
    }
  }

  div.modal-header {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    padding-top: 20px;
    color: #5d5e5d;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #ececec;
`;
