import { ReduxAction } from 'interfaces/redux';
import { Campaign, CampaignState } from 'interfaces/campaign';
import { Dispatch } from 'redux';
import { showError, handleAPIError } from 'services/error';
import api from 'services/fanappApi';

export enum CampaignTypes {
  LOADING = 'CAMPAIGN/LOADING',
  STOP_LOADING = 'CAMPAIGN/STOP_LOADING',
}

const initialState: CampaignState = {
  loading: false,
};

export default function campaignActions(
  state: CampaignState = initialState,
  action: ReduxAction<CampaignTypes>,
): CampaignState {
  switch (action.type) {
    case CampaignTypes.LOADING:
      return { ...state, loading: true };
    case CampaignTypes.STOP_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export const actionLoading = (): ReduxAction<CampaignTypes> => ({
  type: CampaignTypes.LOADING,
});

export const actionStopLoading = (): ReduxAction<CampaignTypes> => ({
  type: CampaignTypes.STOP_LOADING,
});

const uploadImage = async (
  imageData: File | null,
  campaignId: string,
  isToRemoveImage?: boolean,
) => {
  if (imageData) {
    const formData = new FormData();

    formData.append('campaign-image', imageData);

    await api.put(`trade/campaigns/${campaignId}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  if (isToRemoveImage === true) {
    await api.delete(`trade/campaigns/${campaignId}/image`);
  }
};

export const create = (
  establishmentId: string,
  input: Campaign,
  imageData: File | null,
  successCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    const { data } = await api.post(
      `trade/establishments/${establishmentId}/campaigns`,
      input,
    );

    try {
      await uploadImage(imageData, data.data.id);
    } catch {
      showError('Ocorreu um erro com a atualização da imagem.');
    }

    if (successCallback) successCallback();
  } catch (error) {
    showError(handleAPIError(error));
  }
  dispatch(actionStopLoading());
};

export const update = (
  input: Campaign,
  imageData: File | null,
  successCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    const { data } = await api.put(`trade/campaigns/${input.id}`, input);

    try {
      await uploadImage(imageData, data.data.id);
      if (successCallback) successCallback();
    } catch {
      showError(
        'As alterações foram salvas mas ocorreu um erro com a atualização da imagem.',
      );
    }
  } catch (error) {
    showError(handleAPIError(error));
  }
  dispatch(actionStopLoading());
};

export const extendPeriod = (
  endAt: string,
  campaignId: string,
  successCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    await api.put(`trade/campaigns/${campaignId}/extend`, { endAt });
    if (successCallback) successCallback();
  } catch (error) {
    showError(handleAPIError(error));
  }
  dispatch(actionStopLoading());
};
