import styled from 'styled-components';
import { Row } from 'antd';
import { Title as DefaultTile } from '../styles';

export const Title = styled(DefaultTile)`
  margin-bottom: 3rem;
`;

export const ButtonContainer = styled(Row)`
  margin-top: 3rem;
`;
