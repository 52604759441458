import styled from 'styled-components';
import { Button as btn, Modal as antdModal, Tabs as DefaultTabs } from 'antd';

export const Modal = styled(antdModal)`
  div.align-center {
    text-align: center;
  }

  span.ant-modal-close-x {
    color: #fe6a21;
  }

  div.modal-content {
    background: #f6f6f6;
    border-radius: 15px;
    padding: 15px;
    padding-top: 10px;
    margin: 15px 0;
  }

  div.modal-header {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 15px;
    color: #5d5e5d;
  }

  .select-roles {
    width: 100%;
  }

  .ant-select-selection-item {
    opacity: 0.4;
  }

  .ant-select-selector {
    background: #f6f6f6;
  }

  .ant-select-arrow {
    color: #fe6a21;
  }
`;

export const TabBarItem = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
`;

export const Tabs = styled(DefaultTabs)`
  display: block;

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 100%;
    color: #c5c5c5;
    display: inline-block;
    text-align: center;
  }

  .ant-tabs-ink-bar-animated.ant-tabs-ink-bar {
    height: 3px;
  }

  .ant-tabs-tab-active > .ant-tabs-tab-btn {
    color: #535353;
  }

  .ant-tabs-nav::before {
    display: none;
  }
`;

export const ListItem = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    border: 1px solid #fe6321;
    background: #ffebe2;
    border-radius: 11px;
    height: 29px;
    padding: 0 8px;
    margin-right: 10px;
    margin-top: 10px;

    button {
      font-weight: 500;
      color: #fe6321;
      padding-right: 0;
    }
  }
`;

export const Button = styled(btn)`
  max-width: 240px;
  margin: 0 auto;
`;
