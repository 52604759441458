import { StorageKeys } from 'constants/storage';
import { Establishment } from 'interfaces/establishment';
import { User } from 'interfaces/user';

export const storeAuthToken = (token: string): void =>
  localStorage.setItem(StorageKeys.AUTH_TOKEN, token);

export const storeKeepUser = (keepUser = false): void => {
  localStorage.setItem(StorageKeys.KEEP_USER, keepUser.toString());
};

export const storeUser = (user: User): void =>
  localStorage.setItem(StorageKeys.USER, JSON.stringify(user));

export const getUser = (): User | null => {
  const user = localStorage.getItem(StorageKeys.USER);
  if (!user) return null;
  return JSON.parse(user);
};

export const storeCurrentEstablishment = (
  establishment: Establishment,
): void => {
  localStorage.setItem(
    StorageKeys.CURRENT_ESTABLISHMENT,
    JSON.stringify(establishment),
  );
};

export const getCurrentEstablishment = (): Establishment | null => {
  const user = localStorage.getItem(StorageKeys.CURRENT_ESTABLISHMENT);
  if (!user) return null;
  return JSON.parse(user);
};

export const getAuthToken = (): string | null =>
  localStorage.getItem(StorageKeys.AUTH_TOKEN);

export const getKeepUser = (): boolean =>
  localStorage.getItem(StorageKeys.KEEP_USER) === 'true';

export const removeAuthToken = (): void =>
  localStorage.removeItem(StorageKeys.AUTH_TOKEN);

export const removeKeepUser = (): void =>
  localStorage.removeItem(StorageKeys.KEEP_USER);

export const removeCurrentEstablishment = (): void =>
  localStorage.removeItem(StorageKeys.CURRENT_ESTABLISHMENT);

export const clearStorage = (): void => localStorage.clear();
