import styled from 'styled-components';

export const Container = styled.div`
  width: 378px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  background: #c5c5c5;
  height: 150px;
  justify-content: center;
  align-items: center;

  > div {
    position: absolute;
    right: 15px;
    top: 15px;
    background: red;
    border-radius: 4px;
    padding: 1px 6px;
    color: white;
    font-size: 12px;
    font-weight: 600;
  }

  > div.ativado {
    background: #68d105;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px 25px 15px;
  border: 2px solid #c5c5c566;
  background: white;

  button {
    text-decoration: underline;
    text-decoration-color: #ff6d08;
    color: #ff6d08;
    height: 40px;
    font-weight: 600;
    font-size: 22px;
    align-self: flex-end;
  }

  span {
    margin-bottom: 10px;
    color: #535353;
  }

  span.title {
    font-size: 22px;
    font-weight: bold;
  }

  span.category {
    font-size: 14px;
    color: #c5c5c5;
  }

  span.condition {
    font-size: 20px;
    color: #68d105;
    font-weight: bold;
  }
`;
