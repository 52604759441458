import { memo, useCallback, useState } from 'react';

import { translate } from 'config/i18n';
import { Store } from 'antd/lib/form/interface';
import { Col, Row, Form, notification } from 'antd';
import api from 'services/api';
import { Modal, Button, Input } from './styles';

interface ModalProps {
  code: string | null;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

function ChangePasswordModal({ code, visible, setVisible }: ModalProps) {
  const [loading, setLoading] = useState(false);

  const handleForm = useCallback(
    async (data: Store) => {
      try {
        setLoading(true);
        if (code)
          await api.post('change-password', {
            password: data.newPassword,
            code,
          });
        else await api.put('me/password', data);
        setVisible(false);
        notification.success({
          message: translate('general.success'),
          description: translate('profile.password_updated_succeed'),
        });
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('profile.password_updated_failed'),
        });
      } finally {
        setLoading(false);
      }
    },
    [setVisible, code],
  );

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">
        {!code
          ? translate('profile.change_password')
          : translate('profile.add_password')}
        <p>{translate('profile.type_new_password')}</p>
      </div>
      <div className="modal-content">
        <Form onFinish={handleForm}>
          {!code && (
            <Row>
              <Col sm={24}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'profile.current_password',
                      }),
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder={translate('profile.current_password')}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={24}>
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: translate('validation_messages.required_field', {
                      field: 'profile.new_password',
                    }),
                    validateTrigger: 'onBlur',
                  },
                  {
                    min: 6,
                    message: translate('validation_messages.min_characters', {
                      field: 'auth.password',
                      minLength: 6,
                    }),
                    validateTrigger: 'onBlur',
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder={translate('profile.new_password')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <Button
                disabled={loading}
                htmlType="submit"
                type="primary"
                size="large"
              >
                {loading
                  ? `${translate('general.loading')}...`
                  : translate('general.confirm')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default memo(ChangePasswordModal);
