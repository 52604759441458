import styled from 'styled-components';
import { Button as Btn } from 'antd';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;

  button + button {
    margin-left: 20px;
  }
`;

export const Button = styled(Btn)`
  text-shadow: none;
  box-shadow: none;
  height: 44px;
  border-radius: 3px;
  font-size: 19px;
  font-weight: 300;
`;

export const Content = styled.div`
  background: white;
  border-radius: 5px;
  padding: 30px;
  max-width: 1100px;
  margin: 46px auto;

  h1.title {
    font-size: 27px;
    font-weight: 700;
    color: #101116;
    margin-bottom: 40px;
    margin-top: -20px;
  }

  .role-name + .role-name::before {
    content: ' | ';
  }

  .establishment-name {
    h1 {
      font-weight: 400;
      color: #101116;
      font-size: 33px;
    }
  }

  .label {
    font-weight: 600;
    color: #101116;
    margin-right: 4px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-top: 29px;
  }

  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h1 {
      font-weight: bold;
    }

    > img {
      max-width: 100%;
      height: 343px;
      border-radius: 5px;
      object-fit: cover;
    }

    div.imagePlaceHolder {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: #ffefe9;
      height: 200px;
      border-radius: 5px;

      img {
        width: 20%;
      }
    }
  }

  .operator-code {
    .text {
      color: #ff6d08;
      margin-right: 30px;
    }

    .edit-link,
    .save-link {
      color: #ff6d08;
      display: none;
      height: 26px;
      margin-top: -1px;
    }

    .save-link {
      display: initial;
    }

    &:hover .edit-link {
      display: initial;
    }
  }

  .mb {
    margin-bottom: 10px;
  }

  .icon {
    margin-right: 10px;
    position: relative;
    top: -2px;

    &.calendar {
      margin-left: -2px;
    }
  }

  .separator {
    border: 0.5px solid #65666b;
    opacity: 0.3;
    margin: 40px 0;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    .footer-label {
      font-weight: 600;
      margin-right: 4px;
    }
  }

  .column {
    span.title {
      color: #535353;
      font-weight: 600;
    }

    span.label {
      font-weight: 600;
      color: #ff6d08;
      margin-right: 5px;
    }

    span.text {
      color: #c3c3c2;
    }

    .email-div {
      width: 100%;
      overflow: hidden;
      position: relative;

      .blur {
        background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
        height: 28px;
        position: absolute;
        width: 50px;
        top: 0;
        right: 0;
      }
    }

    display: flex;
    flex-direction: column;
  }
`;

export const Input = styled.input`
  outline: 0;
  border: 0;
  border-bottom: 1px solid red;
  background: transparent;
  margin-left: 5px;
  height: 26px;
`;
