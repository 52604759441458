import { notification, Switch } from 'antd';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import Page from 'components/Page';
import { translate } from 'config/i18n';
import { Sale, Transaction, TransactionType } from 'interfaces/sale';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from 'services/api';

import { transaction } from 'helpers/mapper';
import { getCurrencyByLocale } from 'helpers/stringHelper';
import { useLastLocation } from 'react-router-last-location';
import { secureGoBack } from 'helpers/routeHelper';
import { Button, Container, Header } from './styles';

export default function Reversal(): JSX.Element {
  const { saleId } = useParams<{ saleId: string }>();
  const [sale, setSale] = useState<Sale>({} as Sale);
  const [transactions, setTransactions] = useState<
    Array<{ [key: string]: string | number }>
  >([]);
  const [salesList, setSalesList] = useState<any[]>([]);

  const [loading, setLoading] = useState(true);
  const [fullReversal, setFullReversal] = useState(false);

  const history = useHistory();
  const lastLocation = useLastLocation();

  useEffect(() => {
    (async () => {
      try {
        setTransactions([]);
        const paymentMethods = await api.get(`trade/payment-methods`);
        const {
          data: { data },
        } = await api.get<{ data: Sale }>(`trade/product-sales/${saleId}`);

        setSale(data);

        let products = [];
        let reversedProducts = [];

        products = [...new Set(data.productSaleItems.map(x => x.productId))];
        reversedProducts = [
          ...new Set(
            data.productSaleItems
              .filter(x => x.reversedAt)
              .map(x => x.productId),
          ),
        ];

        const reversedProductsToAdd = reversedProducts
          .map((x: any) => {
            const productSaleItems = data.productSaleItems.filter(
              (y: any) => y.productId === x,
            );

            return {
              ...productSaleItems[0].product,
              productSaleItemId: productSaleItems[0].id,
              quantity: productSaleItems.filter(y => y.reversedAt).length,
              reversalQuantity: 0,
              reversed: true,
            };
          })
          .filter(x => x.quantity);

        const productsToAdd = products
          .map((x: any) => {
            const productSaleItems = data.productSaleItems.filter(
              (y: any) => y.productId === x,
            );

            return {
              ...productSaleItems[0].product,
              productSaleItemId: productSaleItems[0].id,
              quantity: productSaleItems.filter(y => !y.reversedAt).length,
              reversalQuantity: 0,
              reversedAt: false,
            };
          })
          .filter(x => x.quantity);

        setSalesList([...productsToAdd, ...reversedProductsToAdd]);

        data.transactions.forEach((x: Transaction) => {
          const amount = getCurrencyByLocale(x.amount / 100);
          const paymentMethod = paymentMethods.data.data.find(
            (y: TransactionType) => y.id === x.paymentMethodId,
          );
          const transactionType = transaction[paymentMethod.name];
          setTransactions(prev => [
            ...prev,
            {
              id: x.id,
              amount,
              rawAmount: x.amount,
              transactionType,
            },
          ]);
        });
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [saleId]);

  const getTransactionsTotal = useMemo(
    () =>
      getCurrencyByLocale(
        transactions.reduce((cur, next) => cur + Number(next.rawAmount), 0) /
          100,
      ),
    [transactions],
  );

  function handleAddReversalQuantity(id: string) {
    setSalesList(prev =>
      prev.map(x =>
        x.id !== id
          ? x
          : {
              ...x,
              reversalQuantity:
                x.reversalQuantity < x.quantity
                  ? x.reversalQuantity + 1
                  : x.reversalQuantity,
            },
      ),
    );
  }

  function handleSubReversalQuantity(id: string) {
    setSalesList(prev =>
      prev.map(x =>
        x.id !== id
          ? x
          : {
              ...x,
              reversalQuantity:
                x.reversalQuantity === 0 ? 0 : x.reversalQuantity - 1,
            },
      ),
    );
  }

  function handleSubmit() {
    (async () => {
      try {
        if (fullReversal) {
          await api.post(`/trade/product-sales/${saleId}/reversal`);
        } else {
          const reversalItems: string[] = [];
          salesList
            .filter(x => x.reversalQuantity > 0)
            .forEach(x => {
              reversalItems.push(
                ...sale.productSaleItems
                  .filter(y => y.product.id === x.id)
                  .map(y => y.id)
                  .slice(0, x.reversalQuantity),
              );
            });

          if (!reversalItems.length) {
            notification.error({
              message: translate('general.error'),
              description: 'Selecione algum item para realizar o estorno',
            });
            return;
          }

          await api.post(`/trade/product-sales/${saleId}/partial-reversal`, {
            products: reversalItems,
          });
        }

        notification.success({
          message: translate('product.was_saved'),
          description: translate('general_messages.was_saved', {
            context: 'product.title',
          }),
        });
        secureGoBack(history, lastLocation, '/pdv');
      } catch (err: any) {
        if (err.response.data.error.code === 'HasOperationConstraintError') {
          notification.error({
            message: translate('general.error'),
            description: 'Operação fechada',
          });
        } else {
          notification.error({
            message: translate('general.error'),
            description: translate('general_messages.request_error'),
          });
        }
      }
    })();
  }

  return (
    <Page
      displayDrawer
      title="Estorno da venda"
      showBackButton
      loading={loading}
      SubHeader={
        <Header>
          <Button
            onClick={() => {
              secureGoBack(history, lastLocation, '/pdv');
            }}
            htmlType="button"
            type="default"
          >
            {translate('general.cancel')}
          </Button>

          <Button
            disabled={loading || !salesList.length}
            onClick={() => {
              handleSubmit();
            }}
            htmlType="submit"
            type="primary"
          >
            {loading
              ? translate('general.saving')
              : translate('general.confirm')}
          </Button>
        </Header>
      }
    >
      <Container>
        <div className="header">
          <h1 className="title">{translate('pdv.sale_details')}</h1>
          <h2>COD. {sale.code?.toString().padStart(4, '0')}</h2>
        </div>

        <div className="infos">
          <div className="col">
            <span className="label">Operador</span>
            <span>{sale.employee?.user?.name}</span>
          </div>
          <div>
            <CalendarOutlined />
            <span>12/07/2022</span>
          </div>
          <div>
            <ClockCircleOutlined />
            <span>16h34min</span>
          </div>
        </div>

        <main>
          <aside className="payment-forms">
            <h1>Formas de pagamento</h1>
            {transactions.map(x => (
              <div key={x.id}>
                <span>{x.transactionType}</span>
                <span>{x.amount}</span>
              </div>
            ))}

            <div className="total-row">
              <span>{translate('pdv.payment_total')}</span>
              <span>{getTransactionsTotal}</span>
            </div>
          </aside>
          {!!salesList.length && (
            <aside className="reversal-list">
              <h1>Listagem para estorno</h1>
              <div className="radio-container">
                <Switch
                  checked={fullReversal}
                  size="small"
                  onChange={() => setFullReversal(!fullReversal)}
                />
                <span>Estornar venda completa</span>
              </div>
              {salesList.map(x => (
                <div
                  key={`${x.id}-${x.reversed}`}
                  className={`row ${
                    fullReversal || x.reversed ? 'disabled' : ''
                  }`}
                >
                  <span className="quantity">{x.quantity}x</span>
                  <div className="col">
                    <span className="product-name">{x.name}</span>
                    <span className="unit-price">
                      {getCurrencyByLocale(x.price / 100)} uni
                    </span>
                  </div>
                  <div className="controls">
                    {!x.reversed && (
                      <>
                        <button
                          disabled={fullReversal}
                          onClick={() => handleSubReversalQuantity(x.id)}
                          className="control"
                          type="button"
                        >
                          -
                        </button>
                        <span className="value">
                          {x.reversalQuantity.toString().padStart(2, '0')}
                        </span>
                        <button
                          disabled={fullReversal}
                          onClick={() => handleAddReversalQuantity(x.id)}
                          className="control"
                          type="button"
                        >
                          +
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </aside>
          )}
        </main>
      </Container>
    </Page>
  );
}
