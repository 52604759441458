import styled from 'styled-components';
import colors from 'styles/colors';
import { Button as btn } from 'antd';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;

  > div {
    text-align: center;
  }

  > div:first-of-type {
    text-align: left;
    margin-bottom: 10px;
  }

  > div:last-of-type {
    margin-top: 55px;

    button + button {
      margin-left: 20px;
    }
  }

  div.content {
    border-top: 3px solid ${colors.primary};
    width: 550px;
    margin: 0 auto;

    h1 {
      margin-top: 35px;
      margin-bottom: 25px;
      color: #000;
      font-size: 33px;
      line-height: 36px;
    }

    p {
      font-size: 19px;
      line-height: 24px;
      color: #67687a;
    }

    span.highlited-text {
      color: ${colors.primary};
    }
  }

  div.no-invite {
    border-top: 0 !important;
  }

  img.logo {
    width: 130px;
  }
`;

export const Button = styled(btn)`
  height: 55px;

  font-size: 19px;
  color: #67687a;

  border-color: #67687a;
`;

export const LoadingContainer = styled.div`
  text-align: center;
`;
