import styled from 'styled-components';
import { Button as btn } from 'antd';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const Button = styled(btn)`
  max-width: 240px;
`;

export const Content = styled.div``;

export const NoEstablishment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 0 auto;
  width: 410px;
  height: 300px;

  > p {
    font-size: 32px;
  }
`;

export const EstablishmentList = styled.ul`
  list-style: none;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;
