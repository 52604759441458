import { memo, useState } from 'react';

import CloseOperationImg from 'assets/svg/fechar_operacao.svg';

import { translate } from 'config/i18n';

import api from 'services/api';
import { notification } from 'antd';
import { Modal, Button, OperationImage } from './styles';

interface ModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  callback: () => void;
  operationId: string | undefined;
}

function ManageOperationModal({
  visible,
  setVisible,
  callback,
  operationId,
}: ModalProps) {
  const [loading] = useState(false);

  const handleCloseOperation = async () => {
    try {
      await api.post(`trade/operations/${operationId}/close`);

      notification.success({
        message: translate('general.success'),
        description: translate('operation.was_closed'),
      });
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setVisible(false);
      callback?.();
    }
  };

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">
        <OperationImage src={CloseOperationImg} />
      </div>
      <div className="modal-content">
        Deseja seguir com o <strong>fechamento</strong> da operação?
      </div>
      <div className="align-center">
        <Button
          onClick={handleCloseOperation}
          disabled={loading}
          htmlType="button"
          type="primary"
          size="large"
        >
          {loading
            ? `${translate('general.loading')}...`
            : translate('general.confirm')}
        </Button>
      </div>
    </Modal>
  );
}

export default memo(ManageOperationModal);
