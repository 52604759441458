import { MenuItem } from 'interfaces/menuItem';
import IconDashboard from 'assets/svg/fanapp-icon-dashboard.svg';
import IconEstablishment from 'assets/svg/fanapp-icon-place.svg';
import IconUser from 'assets/svg/fanapp-icon-user.svg';
import IconOperation from 'assets/svg/operacoes.svg';
import IconProduct from 'assets/svg/fanapp-icon-products.svg';
import IconPDV from 'assets/svg/icon-pdv.svg';
import IconCampaign from 'assets/svg/icon-campaign.svg';
import { translate } from 'config/i18n';

const menuData: MenuItem[] = [
  {
    label: translate('dashboard.title'),
    route: '/dashboard',
    icon: <img alt="icon dashboard" src={IconDashboard} />,
    roles: ['admin', 'operationalManager', 'financialManager'],
  },
  {
    label: translate('establishment.title'),
    route: '/establishment',
    icon: <img alt="icon estabelecimento" src={IconEstablishment} />,
    roles: ['admin', 'operationalManager', 'financialManager'],
  },
  {
    label: translate('employee.plural_title'),
    route: '/employees',
    icon: <img alt="icon user" src={IconUser} />,
    roles: ['admin', 'operationalManager'],
  },
  {
    label: translate('operation.plural_title'),
    route: '/operations',
    icon: <img alt="icon user" src={IconOperation} />,
    roles: ['admin', 'operationalManager'],
  },
  {
    label: translate('product.plural_title'),
    route: '/products',
    icon: <img alt="icon user" src={IconProduct} />,
    roles: ['admin', 'operationalManager', 'stockist'],
  },
  {
    label: translate('pdv.title'),
    route: '/pdv',
    icon: <img alt="icon pdv" src={IconPDV} />,
    roles: ['admin', 'operationalManager', 'financialManager'],
  },
  {
    label: translate('campaign.plural_title'),
    route: '/campaigns',
    icon: (
      <img
        alt="icon pdv"
        src={IconCampaign}
        style={{ height: '1.3rem', marginLeft: 2 }}
      />
    ),
    roles: ['admin'],
  },
];

export default menuData;
