import { Establishment } from 'interfaces/establishment';
import Cookies from 'universal-cookie';

export function formatPhone(phone: string | undefined): string | null {
  if (!phone) return null;

  const cleaned = phone.replace('+55', '').replace(/\D/g, '');

  let match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
}

export function getNamePlaceholder(
  establishment: Establishment | null,
): string {
  const name: string | undefined =
    establishment?.tradeName ??
    establishment?.companyName ??
    establishment?.partnerName ??
    undefined;

  if (typeof name === 'undefined') return '';

  const initials = name.match(/\b\w/g) || [];
  const formatted = (
    (initials.shift() || '') + (initials.pop() || '')
  ).toUpperCase();

  return formatted.toString();
}

export function getEstablishmentName(
  establishment: Establishment | undefined | null,
): string {
  const name =
    establishment?.tradeName ??
    establishment?.companyName ??
    establishment?.partnerName ??
    '';

  return name;
}

export function getCurrencyByLocale(number: number): string {
  const cookies = new Cookies();
  const language = cookies.get('language');
  return new Intl.NumberFormat(language || 'pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(number);
}

export const formatCpf = (value: string, masked = false): string => {
  if (!value) return '';
  let result = value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

  if (masked) result = result.replace(/(\d{3})\.\d{3}\.\d{3}/, '$1.***.***');

  return result;
};

export const formatCnpj = (value: string): string => {
  if (!value) return '';
  const result = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

  return result;
};

export function isValidEmail(email: string): boolean {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email);
}

export function isPhoneNumber(phone: string): boolean {
  const regex = /^\+\d\d\d\d\d\d\d\d\d\d\d$/i;

  return regex.test(phone);
}
