import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, TabContainer, TabItem, CounterCard } from './styles';

export interface TopNavigationTab {
  label: string;
  route: string;
  counter?: string | null;
}

export interface TopNavigationProps {
  tabs?: TopNavigationTab[];
}

function TopNavigation({ tabs }: TopNavigationProps) {
  const history = useHistory();
  const { pathname } = history.location;

  return (
    <Container data-testid="top-navigation">
      {tabs &&
        tabs.map(tab => {
          const { route } = tab;
          return (
            <TabContainer
              key={tab.route}
              data-testid={`tab-${tab.route}`}
              className={
                pathname.replace(/[^a-zA-Z0-9]/g, '') ===
                route.replace(/[^a-zA-Z0-9]/g, '')
                  ? 'active'
                  : ''
              }
            >
              <TabItem to={route}>
                {tab.label}
                {tab.counter && (
                  <CounterCard data-testid="counter">{tab.counter}</CounterCard>
                )}
              </TabItem>
            </TabContainer>
          );
        })}
    </Container>
  );
}

export default memo(TopNavigation);
