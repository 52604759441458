import { notification } from 'antd';
import { translate } from 'config/i18n';
import { isPhoneNumber, isValidEmail } from 'helpers/stringHelper';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from 'services/api';

interface Payload {
  phone?: string;
  email?: string;
}

interface IUseForgotPassword {
  loading: boolean;
  input: string;
  errorMessage: string;
  setInput: (input: string) => void;
  handleSubmit: () => void;
}

export const useForgotPassword = (): IUseForgotPassword => {
  const history = useHistory();
  const [loading] = useState(false);
  const [input, setInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  async function handleSubmit() {
    setErrorMessage('');
    const payload: Payload = {
      phone: `+${input}`,
      email: input,
    };
    let errorCounter = 0;

    if (!isPhoneNumber(input)) {
      errorCounter++;
      delete payload.phone;
    } else if (!isValidEmail(input)) {
      errorCounter++;
    }

    if (errorCounter === 2) {
      setErrorMessage('Por favor insira um email ou telefone válido');
    } else {
      try {
        await api.post('reset-password', {
          ...payload,
        });

        notification.success({
          message: 'Sucesso',
          description:
            'Um link para a recuperação de senha foi enviado para o seu email.',
        });

        history.push('/');
      } catch (err: any) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      }
    }
  }

  return {
    loading,
    input,
    setInput,
    errorMessage,
    handleSubmit,
  };
};
