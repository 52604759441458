import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, notification } from 'antd';
import { translate } from 'config/i18n';

import Page from 'components/Page';
import ImagePlaceholderIcon from 'assets/svg/fanapp-icon-image-placeholder.svg';
import PersonIcon from 'assets/svg/person.svg';
import CalendarIcon from 'assets/svg/orange-calendar.svg';

import { EstablishmentLogo } from 'interfaces/establishment';
import { Relationship } from 'interfaces/relationship';

import { gutter } from 'constants/styles';
import api from 'services/api';
import { establishmentRole } from 'helpers/mapper';
import { format, parseISO } from 'date-fns';
import ConfirmModal from 'components/ConfirmModal';
import { formatPhone, getEstablishmentName } from 'helpers/stringHelper';
import {
  Button,
  // Header, Button,
  Content,
  Input,
} from './styles';

interface INavParams {
  establishmentId: string;
}

function EstablishmentRelationship() {
  const { establishmentId } = useParams<INavParams>();
  const [relationship, setRelationship] = useState<Relationship | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [operatorCode, setOperatorCode] = useState('');
  const [unlinkLoading, setUnlinkLoading] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [invitesTotal, setInvitesTotal] = useState(null);
  const history = useHistory();

  useEffect(() => {
    async function getEstablishmentFromServer() {
      try {
        setLoading(true);
        const { data } = await api.get(
          `me/establishments/${establishmentId}/relationship`,
        );

        setRelationship(data.data);
        setOperatorCode(data.data.operatorCode);
      } catch (err: any) {
        if (err.response.status === 404) {
          notification.error({
            message: translate('general.error'),
            description: translate('establishment.establishment_not_found'),
          });
          history.push('/establishments');
        } else {
          notification.error({
            message: translate('general.error'),
            description: translate('general_messages.request_error'),
          });
        }
      } finally {
        setLoading(false);
      }
    }
    getEstablishmentFromServer();
  }, [establishmentId, history]);

  function getLogo(image: EstablishmentLogo | undefined) {
    return image ? (
      <div className="imageContainer">
        <img
          src={relationship?.establishment.logo?.location}
          alt={translate('establisment.logo')}
        />
      </div>
    ) : (
      <div className="imageContainer">
        <div className="imagePlaceHolder">
          <img
            src={ImagePlaceholderIcon}
            alt={translate('establishment.logo_placeholder')}
          />
        </div>
      </div>
    );
  }

  const formattedPhone = useMemo(
    () => formatPhone(relationship?.establishment.phone),
    [relationship],
  );

  const confirmUnlink = useCallback(async () => {
    try {
      setUnlinkLoading(true);
      await api.delete(`me/establishments/${establishmentId}/relationship`);

      notification.success({
        message: translate('general.success'),
        description: translate('establishment.you_leave_establishment'),
      });
      history.push('/establishments');
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setUnlinkLoading(false);
    }
  }, [establishmentId, history]);

  const handleChangeOperatorCode = async () => {
    try {
      if (!operatorCode) {
        notification.error({
          message: translate('general.error'),
          description: translate('validation_messages.required_field', {
            field: 'establishment.operatorCode',
          }),
        });
        return;
      }

      if (operatorCode.trim().length !== 4) {
        notification.error({
          message: translate('general.error'),
          description: translate('validation_messages.exact_characters', {
            field: 'establishment.operatorCode',
            length: 4,
          }),
        });
        return;
      }

      await api.post(
        `/me/establishments/${relationship?.establishmentId}/operator-code`,
        {
          code: operatorCode,
        },
      );

      notification.success({
        message: translate('general.success'),
        description: translate('general_messages.was_saved', {
          context: 'establishment.operatorCode',
        }),
      });
      setEditing(false);
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const invitesResponse = await api.get('me/invites');

        setInvitesTotal(invitesResponse.headers['x-total']);
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Page
      title={translate('establishment.my_relationship')}
      loading={loading}
      displayDrawer={false}
      showBackButton
      tabs={[
        {
          label: translate('establishment.my_establishments'),
          route: '/establishments',
        },
        {
          label: translate('invite.plural_title'),
          route: '/invites',
          counter:
            invitesTotal && invitesTotal !== '0'
              ? translate('invite.counter', { count: invitesTotal || 0 })
              : null,
        },
      ]}
      // SubHeader={(
      // <Header>
      //   <Button onClick={() => { setConfirmModalVisible(true); }}
      // className="btn-danger" htmlType="button" type="primary">
      //     Sair do estabelecimento
      //   </Button>
      // </Header>
      // )}
    >
      <Content>
        <h1 className="title">{translate('establishment.association_data')}</h1>
        <Row gutter={gutter}>
          <Col sm={24} md={8}>
            {getLogo(relationship?.establishment?.logo)}
          </Col>
          <Col sm={24} md={16}>
            <Row gutter={gutter} className="establishment-name">
              <Col sm={24} md={24}>
                <h1>{getEstablishmentName(relationship?.establishment)}</h1>
              </Col>
            </Row>
            <Row gutter={gutter} className="mb">
              <Col sm={24}>
                <img
                  src={CalendarIcon}
                  alt="Calendário"
                  className="icon calendar"
                />
                <span className="label">
                  {translate('establishment.entry_date')}
                </span>
                {relationship
                  ? format(
                      parseISO(relationship.establishment.createdAt),
                      'dd/MM/yyyy',
                    )
                  : ''}
              </Col>
            </Row>
            <Row gutter={gutter} className="mb">
              <Col sm={24}>
                <img src={PersonIcon} alt="Pessoa" className="icon" />
                <span className="label">
                  {translate('establishment.function')}
                </span>
                {relationship?.roles.map(role => (
                  <span key={role.id} className="text role-name">
                    {establishmentRole[role.name]}
                  </span>
                ))}
              </Col>
            </Row>
            <Row gutter={gutter} className="mb">
              <Col sm={24}>
                <img src={PersonIcon} alt="Pessoa" className="icon" />
                <span className="label">
                  {translate('establishment.operatorCode')}
                </span>
                {!editing ? (
                  <>
                    <span className="text"> {operatorCode}</span>
                    <button
                      onClick={() => setEditing(true)}
                      type="button"
                      className="edit-link btn-clean"
                    >
                      editar
                    </button>
                  </>
                ) : (
                  <>
                    <Input
                      maxLength={4}
                      value={operatorCode}
                      onChange={e => setOperatorCode(e.target.value)}
                    />
                    <button
                      onClick={handleChangeOperatorCode}
                      type="button"
                      className="save-link btn-clean"
                    >
                      salvar
                    </button>
                  </>
                )}
              </Col>
            </Row>

            <div className="separator" />

            <div className="footer">
              <div>
                <span className="footer-label">
                  {translate('establishment.document')}:
                </span>
                <span className="text">
                  {relationship?.establishment.document}
                </span>
              </div>
              <div>
                <span className="footer-label">
                  {translate('establishment.email')}:
                </span>
                <span className="text">
                  {relationship?.establishment.email}
                </span>
              </div>
              <div>
                <span className="footer-label">
                  {translate('establishment.abreviated_phone')}:
                </span>
                <span className="text">{formattedPhone}</span>
              </div>
            </div>
            <div className="button-container">
              <Button
                onClick={() => {
                  setConfirmModalVisible(true);
                }}
                className="btn-danger"
                htmlType="button"
                type="primary"
              >
                Desvincular
              </Button>
            </div>
          </Col>
        </Row>

        <ConfirmModal
          loading={unlinkLoading}
          visible={confirmModalVisible}
          setVisible={setConfirmModalVisible}
          confirmText={translate(
            'establishment.remove_relationship_confirmation',
          )}
          confirmCallback={confirmUnlink}
        />
      </Content>
    </Page>
  );
}

export default memo(EstablishmentRelationship);
