export enum Errors {
  request_error_title = 'Erro',
  request_error_description = 'Ocorreu um erro com a requisição. Tente novamente.',
  permission_error_description = 'Você não tem permissão para acessar o recurso solicitado.',
}

export enum ApiErrors {
  HasEstablishmentProductCategoryConstraintError = 'HasEstablishmentProductCategoryConstraintError',
  HasCategoryIconConstraintError = 'HasCategoryIconConstraintError',
  InvalidInput = 'InvalidInput',
  NotFound = 'NotFound',
}
