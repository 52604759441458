/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState, useRef } from 'react';

import { Container } from './styles';

interface ILabel {
  children: any;
  label: string;
  forceUpdate?: any;
  padding?: number;
}

const FloatLabel = ({
  children,
  label,
  forceUpdate,
  padding,
}: ILabel): JSX.Element => {
  const floatLabelRef = useRef<HTMLDivElement>(null);
  const [labelClass, setLabelClass] = useState('label');

  function handlerLabelClass(focused?: boolean | null) {
    const value = floatLabelRef?.current?.getElementsByTagName('input')[0]
      ?.value;
    setLabelClass(focused || value ? 'label label-float' : 'label');
  }

  useEffect(() => {
    handlerLabelClass();
  }, [forceUpdate]);

  useEffect(() => {
    handlerLabelClass();
  }, [children.props]);

  return (
    <Container
      ref={floatLabelRef}
      data-testid="float-label"
      className="float-label"
      $padding={padding}
      onBlur={() => handlerLabelClass(false)}
      onFocus={() => handlerLabelClass(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </Container>
  );
};

export default FloatLabel;
