import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, notification, Select } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';

import { updateTaxes } from 'store/ducks/product';

import { Store } from 'antd/lib/form/interface';
import api from 'services/api';
import Page from 'components/Page';

import { secureGoBack } from 'helpers/routeHelper';
import { ProductState } from 'interfaces/product';
import { gutter } from 'constants/styles';
import { onlyNumbers } from 'services/validation';
import { translate } from 'config/i18n';
import { Tax } from 'interfaces/Tax';
import {
  FormContainer,
  Section,
  SectionTitle,
  Input,
  Header,
  Button,
  Label,
} from './styles';

interface INavParams {
  productId: string;
}

const taxSituationLst = [
  {
    id: '00',
    description: 'Tributada integralmente',
  },
  {
    id: '10',
    description: 'Tributada e com cobrança do ICMS por substituição tributária',
  },
  {
    id: '20',
    description: 'Com redução de base de cálculo',
  },
  {
    id: '30',
    description:
      'Isenta ou não tributada e com cobrança do ICMS por substituição tributária',
  },
  {
    id: '40',
    description: 'Isenta',
  },
  {
    id: '41',
    description: 'Não tributada',
  },
  {
    id: '50',
    description: 'Suspensão',
  },
  {
    id: '51',
    description: 'Diferimento',
  },
  {
    id: '60',
    description: 'ICMS cobrado anteriormente por substituição tributária',
  },
  {
    id: '70',
    description:
      'Com redução de base de cálculo e cobrança do ICMS por substituição tributária',
  },
  {
    id: '90',
    description: 'Outras',
  },
];

const origemLst = [
  {
    id: '0',
    description: 'Nacional',
  },
  {
    id: '1',
    description: 'Estrangeira - Importação direta',
  },
  {
    id: '2',
    description: 'Estrangeira - Adquirida no mercado interno',
  },
];

const modeTaxBaseLst = [
  {
    id: '0',
    description: 'Margem Valor Agregado (%)',
  },
  {
    id: '1',
    description: 'Pauta (Valor)',
  },
  {
    id: '2',
    description: 'Preço Tabelado Máx. (valor)',
  },
  {
    id: '3',
    description: 'Valor da operação',
  },
];

const modeTaxBaseSTLst = [
  {
    id: '0',
    description: 'Preço tabelado ou máximo sugerido',
  },
  {
    id: '1',
    description: 'Preço tabelado ou máximo sugerido',
  },
  {
    id: '2',
    description: 'Lista Positiva (valor)',
  },
  {
    id: '3',
    description: 'Lista Neutra (valor)',
  },
  {
    id: '4',
    description: 'Margem Valor Agregado (%)',
  },
  {
    id: '5',
    description: 'Pauta (valor)',
  },
];

interface UnitMeasureType {
  unit: string;
  description: string;
}

function Taxes(): JSX.Element {
  const { productId } = useParams<INavParams>();
  const [form] = Form.useForm();
  const history = useHistory();
  const lastLocation = useLastLocation();
  const { loading } = useSelector<ReduxStore, ProductState>(
    state => state.product,
  );
  const dispatch = useDispatch();

  const [taxes, setTaxes] = useState({} as Tax);
  const [preLoading, setPreLoading] = useState(true);

  const [taxSituation, setTaxSituation] = useState('');

  const [unitMeasureLst, setUnitMeasureLst] = useState<UnitMeasureType[]>([]);

  const [
    modeTaxBaseLstBySitTaxSituationLst,
    setModeTaxBaseLstBySitTaxSituationLst,
  ] = useState(taxSituation === '10' ? modeTaxBaseSTLst : modeTaxBaseLst);

  useEffect(() => {
    setModeTaxBaseLstBySitTaxSituationLst(
      taxSituation === '10' ? modeTaxBaseSTLst : modeTaxBaseLst,
    );
  }, [taxSituation]);

  useEffect(() => {
    async function getDataFromServer() {
      try {
        const { data } = await api.get(`/trade/products/${productId}/taxes`);
        const { data: unitMeasureResponse } = await api.get(
          `/trade/units-measures`,
        );

        setTaxSituation(data.data?.icms?.taxSituation);

        setTaxes(data.data);
        setUnitMeasureLst(unitMeasureResponse.data);
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setPreLoading(false);
      }
    }

    getDataFromServer();
  }, [productId]);

  const handleForm = useCallback(
    (data: Store) => {
      function handleSuccess(backRoute: string) {
        notification.success({
          message: translate('product.was_saved'),
          description: translate('general_messages.was_saved', {
            context: 'product.title',
          }),
        });
        secureGoBack(history, lastLocation, backRoute);
      }

      dispatch(
        updateTaxes(productId, data as Tax, () => handleSuccess('/products')),
      );
    },
    [dispatch, productId, history, lastLocation],
  );

  return (
    <Page
      displayDrawer
      title={translate('product.tax_management')}
      loading={preLoading}
      SubHeader={
        <Header>
          <Button
            onClick={() => {
              secureGoBack(history, lastLocation, '/products');
            }}
            htmlType="button"
            type="default"
          >
            {translate('general.cancel')}
          </Button>

          <Button
            disabled={loading}
            onClick={() => {
              form.submit();
            }}
            htmlType="submit"
            type="primary"
          >
            {loading ? translate('general.saving') : translate('general.save')}
          </Button>
        </Header>
      }
    >
      <FormContainer>
        <Form initialValues={taxes} form={form} onFinish={handleForm}>
          <Section>
            <SectionTitle>Códigos gerais</SectionTitle>
            <Row gutter={gutter}>
              <Col sm={10}>
                <Label>CFOP</Label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'CFOP',
                      }),
                    },
                  ]}
                  name="cfop"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={10}>
                <Label>NCM</Label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'NCM',
                      }),
                    },
                  ]}
                  name="ncm"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col sm={10}>
                <Label>GTIN/EAN</Label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'GTIN/EAN',
                      }),
                    },
                  ]}
                  name="gtin"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={10}>
                <Label>CEST</Label>
                <Form.Item name="cest">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Section>

          <Section>
            <SectionTitle>Códigos adicionais</SectionTitle>
            <Row gutter={gutter}>
              <Col sm={10}>
                <Label>Unidade de medida</Label>
                <Form.Item name="unitMeasure">
                  <Select
                    showSearch
                    filterOption={(input, option) => {
                      const rawOptionValue = option?.children
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase();
                      const rawInputValue = input
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase();

                      return rawOptionValue.indexOf(rawInputValue) >= 0;
                    }}
                  >
                    {unitMeasureLst.map((item, index) => (
                      <Select.Option key={item.unit + index} value={item.unit}>
                        {item.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={10}>
                <Label>Código do produto/serviço</Label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'Código do produto/serviço',
                      }),
                    },
                  ]}
                  name="code"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Section>

          <Section>
            <SectionTitle>ICMS</SectionTitle>
            <Row gutter={gutter}>
              <Col sm={10}>
                <Label>Situação tributária</Label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'Situação tributária',
                      }),
                    },
                  ]}
                  name={['icms', 'taxSituation']}
                >
                  <Select onChange={(id: string) => setTaxSituation(id)}>
                    {taxSituationLst.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {`${item.id} - ${item.description}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={10}>
                <Label>Origem</Label>
                <Form.Item name={['icms', 'source']}>
                  <Select>
                    {origemLst.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col sm={10}>
                <Label>Percentual de redução da base de cálculo</Label>
                <Form.Item
                  rules={[
                    {
                      validator: (_rule, value, callback) =>
                        onlyNumbers(
                          _rule,
                          value,
                          callback,
                          'Percentual de redução da base de cálculo',
                        ),
                    },
                  ]}
                  name={['icms', 'percentageReductionTaxBase']}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={10}>
                <Label>Modalidade de determinação de Base de Cálculo</Label>
                <Form.Item name={['icms', 'modeTaxBase']}>
                  <Select>
                    {modeTaxBaseLstBySitTaxSituationLst.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col sm={5}>
                <Label>Alíquota</Label>
                <Form.Item
                  rules={[
                    {
                      validator: (_rule, value, callback) =>
                        onlyNumbers(_rule, value, callback, 'Alíquota'),
                    },
                  ]}
                  name={['icms', 'rate']}
                >
                  <Input />
                </Form.Item>
              </Col>
              {taxSituation === '10' && (
                <>
                  <Col sm={5}>
                    <Label>Base de cálculo</Label>
                    <Form.Item
                      rules={[
                        {
                          validator: (_rule, value, callback) =>
                            onlyNumbers(
                              _rule,
                              value,
                              callback,
                              'Base de cálculo',
                            ),
                        },
                      ]}
                      name={['icms', 'taxBaseSR']}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col sm={8}>
                    <Label>Percentual de margem no valor adicionado</Label>
                    <Form.Item
                      rules={[
                        {
                          validator: (_rule, value, callback) =>
                            onlyNumbers(
                              _rule,
                              value,
                              callback,
                              'Percentual de margem no valor adicionado',
                            ),
                        },
                      ]}
                      name={['icms', 'percentageMarginValueAddedSR']}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </Section>

          <Section>
            <SectionTitle>PIS</SectionTitle>
            <Row gutter={gutter}>
              <Col sm={10}>
                <Label>Situação tributária</Label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'Situação tributária',
                      }),
                    },
                  ]}
                  name={['pis', 'taxSituation']}
                >
                  <Select>
                    {taxSituationLst.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {`${item.id} - ${item.description}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={10}>
                <Label>Alíquota</Label>
                <Form.Item
                  rules={[
                    {
                      validator: (_rule, value, callback) =>
                        onlyNumbers(_rule, value, callback, 'Alíquota'),
                    },
                  ]}
                  name={['pis', 'rate']}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Section>

          <Section>
            <SectionTitle>COFINS</SectionTitle>
            <Row gutter={gutter}>
              <Col sm={10}>
                <Label>Situação tributária</Label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'Situação tributária',
                      }),
                    },
                  ]}
                  name={['cofins', 'taxSituation']}
                >
                  <Select>
                    {taxSituationLst.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {`${item.id} - ${item.description}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={10}>
                <Label>Alíquota</Label>
                <Form.Item
                  rules={[
                    {
                      validator: (_rule, value, callback) =>
                        onlyNumbers(_rule, value, callback, 'Alíquota'),
                    },
                  ]}
                  name={['cofins', 'rate']}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Section>

          <Section>
            <SectionTitle>IPI</SectionTitle>
            <Row gutter={gutter}>
              <Col sm={10}>
                <Label>Situação tributária</Label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: translate('validation_messages.required_field', {
                        field: 'Situação tributária',
                      }),
                    },
                  ]}
                  name={['ipi', 'taxSituation']}
                >
                  <Select>
                    {taxSituationLst.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {`${item.id} - ${item.description}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={10}>
                <Label>Alíquota</Label>
                <Form.Item
                  rules={[
                    {
                      validator: (_rule, value, callback) =>
                        onlyNumbers(_rule, value, callback, 'Alíquota'),
                    },
                  ]}
                  name={['ipi', 'rate']}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Section>
        </Form>
      </FormContainer>
    </Page>
  );
}

export default Taxes;
