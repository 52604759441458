import styled from 'styled-components';
import { Avatar as AntdAvatar, Button as Btn } from 'antd';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;

  button + button {
    margin-left: 20px;
  }
`;

export const Button = styled(Btn)`
  text-shadow: none;
  box-shadow: none;
`;

export const Content = styled.div`
  .custom-col {
    /* max-width: 382px; */
  }

  h1 {
    color: #fe6a21;
    font-size: 30px;
    font-weight: 500;
  }

  h3 {
    color: #000;
    font-size: 23px;
    font-weight: 600;
  }

  button.edit-link {
    color: #fe6a21;
  }

  div.main-row {
    margin-bottom: 50px;
  }

  div.main-row > div:nth-of-type(2) {
    height: 620px;
    padding-left: 50px !important;
    overflow: auto;
    display: flex;
    flex-direction: column;
    /* max-width: 450px; */
    border-right: 1.5px solid #ececec;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #67687a;
      border-radius: 3px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #474850;
    }
  }

  div.image-container {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    flex-direction: column;
    margin-bottom: 54px;

    button {
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;

      img {
        width: 20px;
      }
    }

    div.name-container {
      color: #67687a;
      font-size: 19px;
      text-align: center;

      span {
        color: #000;
        font-size: 27px;
      }
      margin-top: 21px;
    }
  }

  div.custom-row:first-of-type {
    justify-content: flex-start;
    margin-bottom: 50px;
  }

  span.title {
    font-size: 23px;
    font-weight: bold;
    color: #000;
  }

  .mt-2 {
    margin-top: 15px;
  }

  .ml-04 {
    margin-left: 4px;
  }

  div.custom-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    div.name-container {
      color: #000;
      font-size: 27px;
    }

    img {
      margin-right: 10px;
    }

    img path {
      fill: red;
    }

    span {
      font-size: 20px;
      font-weight: bold;
    }

    p {
      margin: 0;
      color: #67687a;
    }

    button {
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      margin-left: auto;

      img {
        width: 20px;
      }
    }
  }
`;

export const Avatar = styled(AntdAvatar)`
  width: 101px;
  height: 101px;
  margin-right: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChangePasswordLink = styled(Btn)`
  margin-top: 80px;
  font-weight: bold;
  color: #535353;

  &:hover {
    color: #888;
  }
`;

export const DisconnectLink = styled(Btn)`
  margin-left: auto;
  font-weight: bold;
  color: red;

  &:hover {
    color: #ff4141;
  }
`;

export const Separator = styled.div`
  width: 100%;
  max-width: 320px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 5px 0;
`;

export const EstablishmentList = styled.ul`
  list-style: none;
  padding: 0;
  display: block;
`;
