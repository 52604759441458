// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from 'history';
import { LastLocationType } from 'react-router-last-location';
import i18n from 'i18next';

export function secureGoBack(
  history: History,
  lastRoute: LastLocationType,
  defaultRoute: string,
): void {
  if (lastRoute) {
    history.goBack();
  } else {
    history.push(`${defaultRoute}`);
  }
}

export function languagePush(history: History, url: string): void {
  const defaultLanguage = i18n.language;

  history.push(`/${defaultLanguage}${url}`);
}
