import styled from 'styled-components';
import { Col, Row, Typography, Button } from 'antd';
import background from 'assets/images/background_login-min.png';
import logo from 'assets/images/logo_light.png';

export const Container = styled(Row)`
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
`;

export const HeaderContainer = styled.header`
  margin-bottom: 4rem;
`;

export const BackgroundCol = styled(Col)`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: @primary-color;
    opacity: 0.5;
    z-index: 2;
    display: block;
  }
`;

export const Background = styled.img.attrs({
  src: background,
  alt: 'fan background concert',
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Logo = styled.img.attrs({
  alt: 'logo fan pvp',
  src: logo,
})`
  object-fit: contain;
  width: 50%;
  z-index: 3;
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
  margin: auto;
`;

export const FormCol = styled(Col)`
  padding-top: 2rem;
  padding-left: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: @screen-md) {
    padding-left: 2rem;
  }
`;

export const HighlightText = styled(Typography.Text)`
  color: @primary-color;
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 0;
  font-weight: 300;
  line-height: 2.4rem;
  white-space: pre-line;
`;

export const Subtitle = styled(Typography.Text)`
  font-size: 1.25rem;
`;

export const FormTitle = styled.h2`
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

export const FormContainer = styled.div`
  max-width: 450px;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 2rem;
`;

export const SubmitButton = styled(Button)`
  width: 200px;
  height: 60px;
`;
