import { memo, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import Page from 'components/Page';
import { gutter } from 'constants/styles';
import { translate } from 'config/i18n';

import { ReduxStore } from 'interfaces/reduxStore';
import { EstablishmentState } from 'interfaces/establishment';
import { useSelector } from 'react-redux';
import { Roles } from 'constants/roles';
import EstablishmentGeneralPreferences from './General';

import { Content } from './styles';

function EstablishmentPreferences() {
  const [readonly, setReadonly] = useState(true);
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );

  useEffect(() => {
    const roles = currentEstablishment?.employees
      ? currentEstablishment?.employees[0]?.roles.map(x => x.name)
      : [];

    if (roles) {
      setReadonly(
        !(
          roles.includes(Roles.ADMIN) ||
          roles.includes(Roles.OPERATIONAL_MANAGER)
        ),
      );
    }
  }, [currentEstablishment]);

  return (
    <Page
      title={translate('establishment.title')}
      tabs={[
        {
          label: translate('establishment.general'),
          route: '/establishment',
        },
        {
          label: translate('establishment.preferences'),
          route: '/establishment/preferences',
        },
      ]}
    >
      <Content>
        <Row gutter={gutter} className="face-sheet">
          <Col sm={24}>
            <EstablishmentGeneralPreferences
              establishmentId={currentEstablishment?.id}
              readonly={readonly}
            />
          </Col>
        </Row>
      </Content>
    </Page>
  );
}

export default memo(EstablishmentPreferences);
