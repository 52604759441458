import { memo, useState } from 'react';
import { Form, Input } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useDispatch, useSelector } from 'react-redux';
import { completeSignup, CompleteSignupInput } from 'store/ducks/user';
import { ReduxStore } from 'interfaces/reduxStore';
import { isCPF } from 'services/validation';
import { useQuery } from 'hooks/useQuery';
import { translate } from 'config/i18n';
import { RuleObject } from 'antd/lib/form';
import { ButtonContainer } from './styles';
import { SubmitButton, FormTitle } from '../styles';

export interface CompleteSignUpFormProps {
  code: string;
}
function CompleteSignUpForm({ code }: CompleteSignUpFormProps) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const query = useQuery();
  const [redirectUrl] = useState<string | null>(query.get('redirect'));
  const [document, setDocument] = useState('');

  const handleForm = (values: Store) => {
    dispatch(
      completeSignup({ ...values, code } as CompleteSignupInput, () => {
        if (redirectUrl) window.location.href = redirectUrl;
      }),
    );
  };

  const loading = useSelector<ReduxStore, boolean>(
    state => state.user.loading || state.auth.loading,
  );

  const setOnlyNumber = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const re = /^[0-9\b]+$/;

    if (value === '' || re.test(value)) {
      form.setFieldsValue({ document: value });
      setDocument(value);
    } else {
      form.setFieldsValue({ document });
    }
  };

  const onlyNumbers = (
    _rule: RuleObject,
    value: string,
    callback: (error?: string) => void,
  ): void => {
    try {
      if (!value) callback();
      const cpf =
        typeof value === 'number' ? value : Number(value.replace(',', '.'));

      if (!/^\d+$/.test(cpf.toString())) {
        throw new Error(
          translate('validation_messages.must_be_a_number', {
            field: 'auth.document',
          }),
        );
      }

      callback();
    } catch (error: any) {
      callback(error);
    }
  };

  return (
    <Form
      initialValues={{ name: '', document: '', password: '' }}
      onFinish={handleForm}
      form={form}
    >
      <FormTitle>{translate('auth.insert_yout_details')}</FormTitle>
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: translate('validation_messages.required_field', {
              field: 'auth.name',
            }),
            validateTrigger: 'onBlur',
          },
          {
            pattern: new RegExp(/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF '.-]+$/),
            message: translate('validation_messages.must_have_only_letters', {
              field: 'auth.name',
            }),
          },
        ]}
      >
        <Input placeholder={translate('auth.name')} />
      </Form.Item>
      <Form.Item
        name="document"
        rules={[
          {
            required: true,
            message: translate('validation_messages.required_field', {
              field: 'auth.document',
            }),
            validateTrigger: 'onBlur',
          },
          { validator: isCPF, validateTrigger: 'onBlur' },
          { validator: onlyNumbers, validateTrigger: 'onBlur' },
        ]}
      >
        <Input
          onChange={e => {
            setOnlyNumber(e);
          }}
          placeholder={translate('auth.document')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: translate('validation_messages.required_field', {
              field: 'auth.password',
            }),
            validateTrigger: 'onBlur',
          },
          {
            min: 6,
            message: translate('validation_messages.min_characters', {
              field: 'auth.password',
              minLength: 6,
            }),
          },
          {
            pattern: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/),
            message: translate('validation_messages.password_pattern'),
          },
        ]}
      >
        <Input.Password
          type="password"
          placeholder={translate('auth.password')}
        />
      </Form.Item>
      <ButtonContainer justify="center">
        <SubmitButton
          disabled={loading}
          size="large"
          type="primary"
          htmlType="submit"
        >
          {loading
            ? translate('general.sending')
            : translate('general.confirm')}
        </SubmitButton>
      </ButtonContainer>
    </Form>
  );
}

export default memo(CompleteSignUpForm);
