import { memo, useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Page from 'components/Page';
import api from 'services/api';
import { translate } from 'config/i18n';

import { Establishment } from 'interfaces/establishment';
import { notification } from 'antd';
import { clearCurrentEstablishment } from 'store/ducks/establishment';
import EstablishmentCard from '../Card';
import {
  Header,
  Button,
  Content,
  NoEstablishment,
  EstablishmentList,
} from './styles';

function Establishments() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [establishments, setEstablishments] = useState<Array<Establishment>>(
    [],
  );
  const [loading, setLoading] = useState(true);
  const [invitesTotal, setInvitesTotal] = useState(null);

  useEffect(() => {
    async function getEstablishmentsFromServer() {
      try {
        const { data } = await api.get('me/establishments');

        const invitesResponse = await api.get('me/invites');

        setInvitesTotal(invitesResponse.headers['x-total']);

        setEstablishments(data.data);
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    }
    dispatch(clearCurrentEstablishment());
    getEstablishmentsFromServer();
  }, [dispatch]);

  function navigateToNewEstablishment() {
    history.push('/establishments/create');
  }

  return (
    <Page
      displayDrawer={false}
      title={translate('establishment.plural_title')}
      tabs={[
        {
          label: translate('establishment.my_establishments'),
          route: '/establishments',
        },
        {
          label: translate('invite.plural_title'),
          route: '/invites',
          counter:
            invitesTotal && invitesTotal !== '0'
              ? translate('invite.counter', { count: invitesTotal || 0 })
              : null,
        },
      ]}
      loading={loading}
      SubHeader={
        <Header>
          {establishments.length > 0 && !loading && (
            <Button
              onClick={navigateToNewEstablishment}
              htmlType="button"
              type="primary"
              size="middle"
            >
              <PlusOutlined /> {translate('establishment.create')}
            </Button>
          )}
        </Header>
      }
    >
      <Content>
        {establishments.length === 0 ? (
          <NoEstablishment>
            <p>{translate('establishment.no_relationship')}</p>
            <Button
              onClick={navigateToNewEstablishment}
              htmlType="button"
              type="primary"
              size="middle"
            >
              <PlusOutlined /> {translate('establishment.create')}
            </Button>
          </NoEstablishment>
        ) : (
          <EstablishmentList>
            {establishments.map(establishment => (
              <EstablishmentCard
                key={establishment.id}
                establishment={establishment}
              />
            ))}
          </EstablishmentList>
        )}
      </Content>
    </Page>
  );
}

export default memo(Establishments);
