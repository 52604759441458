import styled from 'styled-components';
import { Button as btn } from 'antd';

export const Container = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;

  h1 {
    margin-top: 20px;
  }
`;

export const Button = styled(btn)`
  width: 130px;
  height: 47px;
  margin: 10px auto;
  margin-bottom: 30px;
`;
