import { memo, useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImagePlaceholderIcon from 'assets/svg/fanapp-icon-image-placeholder.svg';

import Page from 'components/Page';

import api from 'services/api';

import { EstablishmentState, Role } from 'interfaces/establishment';
import { notification } from 'antd';

import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { Invite } from 'interfaces/invite';
import { translate } from 'config/i18n';
import { User } from 'interfaces/user';
import { establishmentRole, inviteStatus } from 'helpers/mapper';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import LoadingImg from 'assets/images/loading.gif';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Header, Button, Content, ImagePlaceholder } from '../List/styles';
import { Card, Title } from './styles';
import InviteModal from '../InviteModal';

function EstablishmentInvites() {
  const [loading, setLoading] = useState(true);
  const [loadingOtherInvites, setLoadingOtherInvites] = useState(true);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [showAllInvites, setShowAllInvites] = useState(false);
  const [invites, setInvites] = useState<Array<Invite>>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [otherInvites, setOtherInvites] = useState<Array<Invite>>([]);

  const [loadingButtons, setLoadingButtons] = useState<Array<string>>([]);

  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );

  function getEmployeeAvatar(user: User | undefined) {
    return (
      <ImagePlaceholder avatar={user?.avatar?.location || ''}>
        <img src={ImagePlaceholderIcon} alt={translate('general.Avatar')} />
      </ImagePlaceholder>
    );
  }

  useEffect(() => {
    async function getDataFromServer() {
      try {
        setLoading(true);
        const response = await api.get(
          `trade/establishments/${currentEstablishment?.id}/invites?status[]=created&expired=false`,
        );
        setTotal(response.headers['x-total'] || 0);
        setInvites(response.data.data);
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    }

    getDataFromServer();
  }, [currentEstablishment]);

  useEffect(() => {
    if (showAllInvites && page === 1) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 0);
    }
  }, [showAllInvites, page]);

  const handleScrolling = useCallback(async () => {
    try {
      if (page === 1) setLoadingOtherInvites(true);

      const response = await api.get(
        `trade/establishments/${
          currentEstablishment?.id
        }/invites?status[]=accepted&status[]=rejected&status[]=expired&status[]=canceled&page=${
          page || 1
        }&pageSize=20`,
      );

      setTotal(response.headers['x-total'] || 0);
      setPage(page + 1);

      setOtherInvites(x => [...x, ...response.data.data]);
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoadingOtherInvites(false);
    }
  }, [currentEstablishment, page]);

  const handleCancelInvite = useCallback(async (inviteId, code) => {
    try {
      setLoadingButtons(x => [...x, inviteId]);
      const response = await api.post(`trade/invites/${inviteId}/cancel`, {
        code,
      });

      setInvites(x => x.filter(y => y.id !== inviteId));
      setOtherInvites(x => [response.data.data, ...x]);

      notification.success({
        message: translate('general.success'),
        description: translate('invite.invite_canceled'),
      });
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoadingButtons(x => x.filter(y => y !== inviteId));
    }
  }, []);

  const handleResendInvite = useCallback(
    async invite => {
      try {
        setLoadingButtons(x => [...x, invite.id]);

        const postDate: {
          roles: Array<string>;
          phone?: Array<string>;
          email?: Array<string>;
        } = {
          roles: invite.roles?.map((x: Role) => x.id),
        };

        try {
          parsePhoneNumber(invite.identifier);
          postDate.phone = [invite.identifier];
        } catch {
          postDate.email = [invite.identifier];
        }

        const response = await api.post(
          `trade/establishments/${currentEstablishment?.id}/invites`,
          postDate,
        );

        setInvites(x => [
          response.data.data[0],
          ...x.filter(y => y.identifier !== response.data.data[0].identifier),
        ]);
        setOtherInvites(x => x.filter(y => y.id !== invite.id));

        notification.success({
          message: translate('general.success'),
          description: translate('invite.invite_resent'),
        });
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoadingButtons(x => x.filter(y => y !== invite.id));
      }
    },
    [currentEstablishment],
  );

  function handleInviteModal() {
    setInviteModalVisible(true);
  }

  function calcDays(expirationDate: string, reverse = false) {
    const daysRemaining = reverse
      ? differenceInCalendarDays(new Date(), parseISO(expirationDate))
      : differenceInCalendarDays(parseISO(expirationDate), new Date());

    if (daysRemaining === 0)
      return translate('employee.updated_at_reduce', { day: 'general.today' });

    if (daysRemaining === 1) {
      if (reverse)
        return translate('employee.updated_at_reduce', {
          day: 'general.yesterday',
        });
      return translate('employee.expires_in_reduce', {
        day: 'general.tomorrow',
      });
    }

    if (reverse)
      return translate('employee.updated_at', { count: daysRemaining });

    return translate('employee.expires_in', { count: daysRemaining });
  }

  return (
    <Page
      displayDrawer
      title={translate('employee.plural_title')}
      tabs={[
        {
          label: translate('employee.plural_title'),
          route: '/employees',
        },
        {
          label: translate('invite.plural_title'),
          route: '/employees/invites',
        },
      ]}
      loading={loading}
      SubHeader={
        <Header>
          <Button
            onClick={handleInviteModal}
            htmlType="button"
            type="primary"
            size="middle"
          >
            {translate('employee.invite')}
          </Button>
        </Header>
      }
    >
      <Content>
        <Title>{translate('employee.standby')}</Title>
        {invites.map((invite, index) => (
          <Card key={['invite', index].join('_')}>
            <div>{getEmployeeAvatar(invite.user)}</div>
            <div>
              <span className="label">
                {invite.user?.name || invite.identifier}
              </span>
              {invite.user && <span>{invite.user.email}</span>}
            </div>
            <div>
              <span className="label">Permissões</span>
              {invite.roles?.map(role => (
                <span key={role.id}>{establishmentRole[role.name]}</span>
              ))}
            </div>
            <div>
              <span className="label">{calcDays(invite.expirationDate)}</span>
            </div>
            <div>
              <Button
                onClick={() => handleCancelInvite(invite.id, invite.code)}
                htmlType="button"
                className="btn-danger"
                size="middle"
                disabled={!!loadingButtons.find(x => x === invite.id)}
              >
                {loadingButtons.find(x => x === invite.id)
                  ? `${translate('general.loading')}...`
                  : translate('invite.cancel_invite')}
              </Button>
            </div>
          </Card>
        ))}

        <div className="hide-link">
          <Button
            htmlType="button"
            type="link"
            onClick={() => {
              setShowAllInvites(!showAllInvites);
              if (page === 1) handleScrolling();
            }}
          >
            {translate('employee.show_hide_all', {
              action: showAllInvites
                ? translate('general.hide')
                : translate('general.show'),
            })}
          </Button>
        </div>

        {showAllInvites && (
          <>
            <Title>{translate('employee.others')}</Title>
            {loadingOtherInvites ? (
              <div className="align-center">
                <img src={LoadingImg} alt={translate('general.loading')} />
              </div>
            ) : (
              <InfiniteScroll
                dataLength={otherInvites.length}
                next={handleScrolling}
                hasMore={total > otherInvites.length}
                loader={
                  <div className="loading-div">
                    {translate('general.loading')}...
                  </div>
                }
              >
                {otherInvites.map((invite, index) => (
                  <Card key={['otherInvite', index].join('_')}>
                    <div>{getEmployeeAvatar(invite.user)}</div>
                    <div>
                      <span className="label">
                        {invite.user?.name || invite.identifier}
                      </span>
                      {invite.user && <span>{invite.user.email}</span>}
                    </div>
                    <div>
                      <span className="label">
                        {translate('employee.roles')}
                      </span>
                      {invite.roles?.map(role => (
                        <span key={role.id}>
                          {establishmentRole[role.name]}
                        </span>
                      ))}
                    </div>
                    <div>
                      <span className={`label ${invite.status}`}>
                        {inviteStatus[invite.status]}
                      </span>
                      <span className="label">
                        {calcDays(invite.statusChangedAt, true)}
                      </span>
                    </div>
                    <div>
                      {(invite.status === 'expired' ||
                        invite.status === 'canceled') && (
                        <Button
                          onClick={() => handleResendInvite(invite)}
                          htmlType="button"
                          className="btn-success"
                          size="middle"
                          disabled={!!loadingButtons.find(x => x === invite.id)}
                        >
                          {loadingButtons.find(x => x === invite.id)
                            ? `${translate('general.loading')}...`
                            : translate('general.resend')}
                        </Button>
                      )}
                    </div>
                  </Card>
                ))}
              </InfiniteScroll>
            )}
          </>
        )}
      </Content>

      <InviteModal
        visible={inviteModalVisible}
        setVisible={setInviteModalVisible}
        callback={newInvite => {
          setInvites(x => [newInvite, ...x]);
        }}
      />
    </Page>
  );
}

export default memo(EstablishmentInvites);
