import { memo, useState, useEffect } from 'react';
import SearchImage from 'assets/svg/search.svg';
import { Icon } from 'interfaces/product';
import { Modal, SearchContainer } from './styles';

interface ModalProps {
  iconList: Icon[];
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setIconId: (id: string) => void;
}

function AddIconModal({
  iconList,
  setIconId,
  visible,
  setVisible,
}: ModalProps) {
  const [search, setSearch] = useState('');
  const [data, setData] = useState(iconList);

  useEffect(() => {
    if (search) {
      setData(iconList.filter(x => x.name.includes(search)));
    } else {
      setData(iconList);
    }
  }, [search, iconList]);

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <SearchContainer>
        <img src={SearchImage} alt="Search" />
        <input
          type="text"
          placeholder="Pesquisar..."
          onChange={({ target }) => setSearch(target.value)}
        />
      </SearchContainer>

      <div className="icon-container">
        {data.map(x => (
          <button
            className="btn-clean"
            type="button"
            onClick={() => {
              setIconId(x.id);
              setVisible(false);
            }}
          >
            <img src={x.icon.location} alt={x.name} />
          </button>
        ))}
      </div>
    </Modal>
  );
}

export default memo(AddIconModal);
