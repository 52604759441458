import { memo, useState, useEffect, useCallback } from 'react';

import Page from 'components/Page';
import api from 'services/api';
import { translate } from 'config/i18n';

import { notification } from 'antd';
import { Invite } from 'interfaces/invite';
import {
  formatPhone,
  getEstablishmentName,
  getNamePlaceholder,
} from 'helpers/stringHelper';
import {
  // Header,
  Button,
  Content,
  NoInvite,
  InviteList,
  Placeholder,
} from './styles';

function Invites() {
  const [invites, setInvites] = useState<Invite[]>([]);
  const [loading, setLoading] = useState(true);
  const [invitesTotal, setInvitesTotal] = useState<number | null>(null);

  useEffect(() => {
    async function getInvitesFromServer() {
      try {
        const response = await api.get('me/invites');
        setInvitesTotal(response.headers['x-total']);
        setInvites(response.data.data);
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    }

    getInvitesFromServer();
  }, []);

  const handleAcceptInvite = useCallback(async code => {
    try {
      await api.post('me/invites/accept', { code });
      setInvites(prevInvites => prevInvites.filter(x => x.code !== code));

      setInvitesTotal(x => (x && x > 0 ? x - 1 : null));

      notification.success({
        message: translate('general.success'),
        description: translate('invite.invite_accepted'),
      });
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleRejectInvite = useCallback(async code => {
    try {
      await api.post('me/invites/reject', { code });
      setInvites(prevInvites => prevInvites.filter(x => x.code !== code));

      setInvitesTotal(x => (x && x > 0 ? x - 1 : null));

      notification.success({
        message: translate('general.success'),
        description: translate('invite.invite_rejected'),
      });
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Page
      displayDrawer={false}
      title={translate('invite.plural_title')}
      loading={loading}
      tabs={[
        {
          label: translate('establishment.my_establishments'),
          route: '/establishments',
        },
        {
          label: translate('invite.plural_title'),
          route: '/invites',
          counter: invitesTotal
            ? translate('invite.counter', { count: invitesTotal || 0 })
            : null,
        },
      ]}
      // SubHeader={(
      //   <Header>
      //     <Button onClick={() => {}} htmlType="button" type="primary" size="middle">
      //       Código de convite
      //     </Button>
      //   </Header>
      // )}
    >
      <Content>
        {invites.length === 0 ? (
          <NoInvite>
            <p>{translate('invite.no_invites')}</p>
          </NoInvite>
        ) : (
          <InviteList>
            {invites.map(invite => (
              <li key={invite.id}>
                <div>
                  <Placeholder>
                    {invite.establishment.logo ? (
                      <div
                        style={{
                          backgroundImage: `url(${invite.establishment.logo.location})`,
                        }}
                      />
                    ) : (
                      <span>{getNamePlaceholder(invite.establishment)}</span>
                    )}
                  </Placeholder>
                  <span>{getEstablishmentName(invite.establishment)}</span>
                </div>

                <table>
                  <tbody>
                    <tr>
                      <td>{translate('establishment.document')}</td>
                      <td>{invite.establishment.document}</td>
                    </tr>
                    <tr>
                      <td>{translate('establishment.email')}</td>
                      <td>{invite.establishment.email}</td>
                    </tr>
                    <tr>
                      <td>{translate('establishment.phone')}</td>
                      <td>{formatPhone(invite.establishment.phone)}</td>
                    </tr>
                  </tbody>
                </table>

                <div className="card-buttons">
                  <Button
                    onClick={() => handleRejectInvite(invite.code)}
                    htmlType="button"
                    className="btn-danger"
                    size="middle"
                  >
                    {translate('general.reject')}
                  </Button>

                  <Button
                    onClick={() => handleAcceptInvite(invite.code)}
                    htmlType="button"
                    className="btn-blue"
                    size="middle"
                  >
                    {translate('general.accept')}
                  </Button>
                </div>
              </li>
            ))}
          </InviteList>
        )}
      </Content>
    </Page>
  );
}

export default memo(Invites);
