import { memo, useState, useCallback, useEffect } from 'react';

import Cookies from 'universal-cookie';
import InputMask from 'react-input-mask';
import {
  parse,
  format,
  subHours,
  addHours,
  startOfMinute,
  startOfHour,
  parseISO,
  isBefore,
} from 'date-fns';

import Page from 'components/Page';
import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { Establishment } from 'interfaces/establishment';
import { translate } from 'config/i18n';
import api from 'services/api';
import { Col, notification, Row, Form } from 'antd';
import LoadingImg from 'assets/images/loading.gif';
import { gutter } from 'constants/styles';
import { Store } from 'antd/lib/form/interface';
import { LoadingContainer, IFrame, Input, Button, Select } from './styles';

const cookies = new Cookies();

const granularities = [
  { value: '1 HOUR', text: '1 hora' },
  { value: '1 DAY', text: '1 dia' },
  { value: '1 WEEK', text: '1 semana' },
  { value: '1 MONTH', text: '1 mês' },
];

function Sales() {
  const [form] = Form.useForm();
  const [metabaseUrl, setMetabaseUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const establishment = useSelector<ReduxStore, Establishment | null>(
    state => state.establishment.currentEstablishment,
  );

  const getChartsFromServer = useCallback(
    async (initialDate: string, finalDate: string, granularity: string) => {
      try {
        setLoading(true);
        const response = await api.get(
          `trade/establishments/${establishment?.id}/dashboards/detailed-financial-panel?initialDate=${initialDate}&finalDate=${finalDate}&granularity=${granularity}`,
        );
        setMetabaseUrl(response.data.data.href);
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
        setLoading(false);
      }
    },
    [establishment],
  );

  const exportReports = useCallback(
    async (initialDate: string, finalDate: string) => {
      try {
        const { data } = await api.get(
          `/trade/establishments/${establishment?.id}/reports/download?initialDate=${initialDate}&finalDate=${finalDate}`,
        );

        const filePath = data.data.location;
        const a = document.createElement('a');
        a.href = filePath;
        const lastIndex = filePath.lastIndexOf('/') + 1;
        a.download = filePath.substr(lastIndex);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      }
    },
    [establishment],
  );

  useEffect(() => {
    if (!cookies.get('dashboard_initial')) {
      const currentDate = startOfMinute(startOfHour(new Date()));

      getChartsFromServer(
        subHours(currentDate, 12).toISOString(),
        addHours(currentDate, 12).toISOString(),
        '1 HOUR',
      );
    } else {
      form.setFieldsValue({
        initialDate: format(
          parseISO(cookies.get('dashboard_initial')),
          'dd/MM/yyyy HH:mm',
        ),
        finalDate: format(
          parseISO(cookies.get('dashboard_final')),
          'dd/MM/yyyy HH:mm',
        ),
        granularity: cookies.get('dashboard_granularity'),
      });

      getChartsFromServer(
        cookies.get('dashboard_initial'),
        cookies.get('dashboard_final'),
        cookies.get('dashboard_granularity'),
      );
    }
  }, [getChartsFromServer, form]);

  const handleOnloadIFrame = useCallback(() => {
    setLoading(false);
  }, []);

  const validate = (data: Store) => {
    let initialDate = '';
    let finalDate = '';
    let hasError = false;

    try {
      initialDate = parse(
        data.initialDate,
        'dd/MM/yyyy HH:mm',
        new Date(),
      ).toISOString();
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('dashboard.invalid_initial_date'),
      });
      hasError = true;
    }

    try {
      finalDate = parse(
        data.finalDate,
        'dd/MM/yyyy HH:mm',
        new Date(),
      ).toISOString();
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('dashboard.invalid_final_date'),
      });
      hasError = true;
    }

    if (
      isBefore(
        parse(data.finalDate, 'dd/MM/yyyy HH:mm', new Date()),
        parse(data.initialDate, 'dd/MM/yyyy HH:mm', new Date()),
      )
    ) {
      notification.error({
        message: translate('general.error'),
        description: translate(
          'validation_messages.date_must_be_grater_than_field',
          {
            grater: 'dashboard.final_date',
            smaller: 'dashboard.initial_date',
          },
        ),
      });
      hasError = true;
    }

    return { initialDate, finalDate, hasError };
  };

  const handleForm = useCallback(
    (data: Store) => {
      const { initialDate, finalDate, hasError } = validate(data);

      if (hasError) return;

      cookies.set('dashboard_initial', initialDate, {
        sameSite: 'strict',
      });

      cookies.set('dashboard_final', finalDate, {
        sameSite: 'strict',
      });

      cookies.set('dashboard_granularity', data.granularity, {
        sameSite: 'strict',
      });

      getChartsFromServer(initialDate, finalDate, data.granularity);
    },
    [getChartsFromServer],
  );

  const handleExport = useCallback(() => {
    const data = form.getFieldsValue(['initialDate', 'finalDate']);

    const { initialDate, finalDate, hasError } = validate(data);

    if (hasError) return;

    exportReports(initialDate, finalDate);
  }, [exportReports, form]);

  return (
    <Page
      title="Dashboard"
      tabs={[
        {
          label: translate('dashboard.sales'),
          route: '/dashboard',
        },
        {
          label: translate('dashboard.employees'),
          route: '/dashboard/employees',
        },
      ]}
      SubHeader={
        <Form
          form={form}
          onFinish={handleForm}
          initialValues={{
            initialDate: format(
              subHours(startOfMinute(startOfHour(new Date())), 12),
              'dd/MM/yyyy HH:mm',
            ),
            finalDate: format(
              addHours(startOfMinute(startOfHour(new Date())), 12),
              'dd/MM/yyyy HH:mm',
            ),
            granularity: '1 MONTH',
          }}
        >
          <Row gutter={gutter}>
            <Col sm={24} md={6}>
              <Form.Item
                name="initialDate"
                rules={[
                  {
                    required: true,
                    message: translate('validation_messages.required_field', {
                      field: 'dashboard.initial_date',
                    }),
                  },
                  {
                    pattern: new RegExp(/^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/),
                    message: translate(
                      'validation_messages.must_be_in_format',
                      {
                        field: 'dashboard.initial_date',
                        format: 'dd/mm/aaaa hh:mm',
                      },
                    ),
                  },
                ]}
              >
                <InputMask mask={translate('masks.date_time')}>
                  <Input placeholder={translate('dashboard.initial_date')} />
                </InputMask>
              </Form.Item>
            </Col>
            <Col sm={24} md={6}>
              <Form.Item
                name="finalDate"
                rules={[
                  {
                    required: true,
                    message: translate('validation_messages.required_field', {
                      field: 'dashboard.final_date',
                    }),
                  },
                  {
                    pattern: new RegExp(/^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/),
                    message: translate(
                      'validation_messages.must_be_in_format',
                      {
                        field: 'dashboard.final_date',
                        format: 'dd/mm/aaaa hh:mm',
                      },
                    ),
                  },
                ]}
              >
                <InputMask mask={translate('masks.date_time')}>
                  <Input placeholder={translate('dashboard.final_date')} />
                </InputMask>
              </Form.Item>
            </Col>
            <Col sm={24} md={3}>
              <Form.Item
                name="granularity"
                rules={[
                  {
                    required: true,
                    message: translate('validation_messages.required_field', {
                      field: 'dashboard.granularity',
                    }),
                  },
                ]}
              >
                <Select
                  placeholder={translate('dashboard.granularity')}
                  size="small"
                >
                  {granularities.map(granularity => (
                    <Select.Option
                      key={granularity.value}
                      value={granularity.value}
                    >
                      {granularity.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={4}>
              <Button disabled={loading} htmlType="submit" type="primary">
                {loading
                  ? translate('general.loading')
                  : translate('general.send')}
              </Button>
            </Col>
            <Col sm={24} md={5}>
              <Button
                disabled={loading}
                onClick={() => {
                  handleExport();
                }}
                htmlType="button"
                type="primary"
              >
                {loading
                  ? translate('general.loading')
                  : translate('product.export')}
              </Button>
            </Col>
          </Row>
        </Form>
      }
    >
      {loading && (
        <LoadingContainer>
          <img src={LoadingImg} alt={translate('general.loading')} />
        </LoadingContainer>
      )}
      {metabaseUrl && (
        <IFrame
          onLoad={handleOnloadIFrame}
          style={{
            display: loading ? 'none' : 'block',
          }}
          src={metabaseUrl}
          title={translate('dashboard.financial_panel')}
          className="sales"
        />
      )}
    </Page>
  );
}

export default memo(Sales);
