import { memo, useState, useCallback } from 'react';
import { Input, notification } from 'antd';

import api from 'services/api';
import { Employee } from 'interfaces/employee';
import { translate } from 'config/i18n';
import { Modal, Button } from './styles';

interface ModalProps {
  employee: Employee;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

function EditCodeModal({ employee, visible, setVisible }: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(employee.operatorCode);

  const handleSubmit = useCallback(async () => {
    if (!code) {
      notification.error({
        message: translate('general.error'),
        description: translate('validation_messages.required_field', {
          field: 'establishment.operatorCode',
        }),
      });
      return;
    }

    if (code.trim().length !== 4) {
      notification.error({
        message: translate('general.error'),
        description: translate('validation_messages.exact_characters', {
          field: 'establishment.operatorCode',
          length: 4,
        }),
      });
      return;
    }

    try {
      setLoading(true);

      await api.post(`trade/employees/${employee.id}/operator-code`, {
        code,
      });

      notification.success({
        message: translate('general.success'),
        description: translate('invite.permissions_have_been_updated'),
      });
      setVisible(false);
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
    }
  }, [code, employee.id, setVisible]);

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
        setCode(employee.operatorCode);
      }}
    >
      <div className="modal-header">{translate('employee.operation_code')}</div>
      <div className="modal-content">
        <Input
          maxLength={4}
          value={code}
          onChange={e => {
            setCode(e.target.value);
          }}
        />
      </div>
      <div className="align-center">
        <Button
          onClick={handleSubmit}
          disabled={loading}
          htmlType="button"
          type="primary"
          size="large"
        >
          {loading
            ? `${translate('general.loading')}...`
            : translate('general.update')}
        </Button>
      </div>
    </Modal>
  );
}

export default memo(EditCodeModal);
