import styled from 'styled-components';
import { Button as btn, Modal as antdModal } from 'antd';
import NumberFormat from 'react-number-format';

export const Modal = styled(antdModal)`
  div.align-center {
    text-align: center;
  }

  width: 600px !important;

  span.ant-modal-close-x {
    color: #fe6a21;
  }

  div.modal-content {
    border-radius: 15px;
    padding: 15px;
    padding-top: 10px;
    margin: 15px 0;

    .image-container {
      background: #f6f6f6;
      border-radius: 15px;
      padding: 10px;
      height: 120px;
      display: flex;
      align-items: center;

      > div {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      span {
        font-size: 18px;
      }

      span:first-of-type {
        font-weight: bold;
      }

      img {
        height: 100%;
      }
    }
  }

  div.modal-header {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    padding-bottom: 15px;
    color: #fe6a21;
  }

  .select-roles {
    width: 100%;
  }

  .ant-select-selection-item {
    opacity: 0.4;
  }

  .ant-select-selector {
    background: #f6f6f6;
  }

  .ant-select-arrow {
    color: #fe6a21;
  }

  .unit-cost-container {
    position: relative;
  }
`;

export const ListItem = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    border: 1px solid #fe6321;
    background: #ffebe2;
    border-radius: 11px;
    height: 29px;
    padding: 0 8px;
    margin-right: 10px;
    margin-top: 10px;

    button {
      font-weight: 500;
      color: #fe6321;
      padding-right: 0;
    }
  }
`;

export const Button = styled(btn)`
  max-width: 240px;
  margin: 0 auto;
`;

export const Label = styled.label`
  font-size: 18px;
  margin-bottom: 5px;
  margin-left: 5px;
  display: block;

  input + & {
    margin-top: 20px;
  }
`;

export const PricePrefix = styled.span`
  position: absolute;
  z-index: 1;
  top: 15px;
  font-size: 20px;
  color: #fe6a21;
  left: 15px;
`;

export const PriceField = styled(NumberFormat)`
  text-indent: 25px;
`;
