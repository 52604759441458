import { ReduxAction } from 'interfaces/redux';
import { Dispatch } from 'redux';
import Cookies from 'universal-cookie';
import api from 'services/api';
import {
  getAuthToken,
  storeAuthToken,
  storeUser,
  getUser,
  clearStorage,
  storeKeepUser,
} from 'services/storage';
import { AuthState } from 'interfaces/auth';
import { showError } from 'services/error';
import { User } from 'interfaces/user';
import { translate } from 'config/i18n';
import { clearUser, setUser } from 'services/sentry';
import { isDevelopment } from 'services/environment';
import fanappApi from 'services/fanappApi';
import { actionClearCurrentEstablishment } from './establishment';

export enum AuthTypes {
  LOADING = 'AUTH/LOADING',
  STOP_LOADING = 'AUTH/STOP_LOADING',
  SET_TOKEN = 'AUTH/SET_TOKEN',
  SET_USER = 'AUTH/SET_USER',
  LOGOUT = 'AUTH/LOGOUT',
}

const initialState: AuthState = {
  token: getAuthToken(),
  user: getUser(),
  loading: false,
};

const cookies = new Cookies();

export default function authActions(
  state: AuthState = initialState,
  action: ReduxAction<AuthTypes>,
): AuthState {
  switch (action.type) {
    case AuthTypes.LOADING:
      return { ...state, loading: true };
    case AuthTypes.STOP_LOADING:
      return { ...state, loading: false };
    case AuthTypes.SET_TOKEN:
      return { ...state, token: action.payload };
    case AuthTypes.SET_USER:
      return { ...state, user: action.payload };
    case AuthTypes.LOGOUT:
      return { ...state, user: null, token: null };
    default:
      return state;
  }
}

export const actionLoading = (): ReduxAction<AuthTypes> => ({
  type: AuthTypes.LOADING,
});

export const actionStopLoading = (): ReduxAction<AuthTypes> => ({
  type: AuthTypes.STOP_LOADING,
});

export const actionSetToken = (token: string): ReduxAction<AuthTypes> => ({
  type: AuthTypes.SET_TOKEN,
  payload: token,
});

export const actionSetUser = (user: User): ReduxAction<AuthTypes> => ({
  type: AuthTypes.SET_USER,
  payload: user,
});

export const actionLogout = (): ReduxAction<AuthTypes> => ({
  type: AuthTypes.LOGOUT,
});

export interface LoginInput {
  phone?: string;
  email?: string;
  password: string;
}

export const logout = () => async (dispatch: Dispatch): Promise<void> => {
  clearStorage();

  if (!isDevelopment()) clearUser();
  dispatch(actionLogout());
  dispatch(actionClearCurrentEstablishment());
};

export const login = (
  input: LoginInput,
  persistToken: boolean,
  successCallback?: (user: User) => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());

  try {
    const {
      data: {
        data: { token, user },
      },
    } = await api.post('login', input);

    cookies.set('token', token, {
      sameSite: 'strict',
    });
    storeKeepUser(persistToken);
    storeAuthToken(token);
    storeUser(user);
    if (!isDevelopment()) setUser(user.id);

    api.defaults.headers.Authorization = `Bearer ${token}`;
    fanappApi.defaults.headers.Authorization = `Bearer ${token}`;

    dispatch(actionSetToken(token));
    dispatch(actionSetUser(user));
    dispatch(actionClearCurrentEstablishment());

    if (successCallback) successCallback(user);
  } catch (error: any) {
    if (error.response?.status === 400 || error.response?.status === 401) {
      showError(translate('auth.email_or_password_is_incorrect'));
    } else {
      showError(translate('general_messages.request_error'));
    }
  }
  dispatch(actionStopLoading());
};

export const loginWithSocial = (
  params: string,
  successCallback?: (user: User) => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    const {
      data: {
        data: { token, user },
      },
    } = await api.get(`/connect/google/callback${params}`);

    cookies.set('token', token, {
      sameSite: 'strict',
    });
    storeKeepUser(true);
    storeAuthToken(token);
    storeUser(user);
    if (!isDevelopment()) setUser(user.id);

    api.defaults.headers.Authorization = `Bearer ${token}`;
    fanappApi.defaults.headers.Authorization = `Bearer ${token}`;

    dispatch(actionSetToken(token));
    dispatch(actionSetUser(user));
    if (successCallback) successCallback(user);
  } catch (error: any) {
    showError(translate('general_messages.request_error'));
  }
  dispatch(actionStopLoading());
};
