import styled from 'styled-components';
import { Button as btn } from 'antd';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const Button = styled(btn)`
  max-width: 240px;
`;

export const Content = styled.div``;

export const NoInvite = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 0 auto;
  width: 410px;
  height: 300px;

  > p {
    font-size: 32px;
  }
`;

export const InviteList = styled.ul`
  list-style: none;
  margin-top: 20px;

  li {
    display: inline-block;
    border-radius: 10px;
    padding: 15px;
    width: 330px;
    box-shadow: 0 0 20px 6px rgba(74, 74, 74, 0.1);
    margin-top: 21px;
    margin-right: 20px;

    .card-buttons button:last-of-type {
      margin-left: 15px;
    }

    div + div {
      margin-top: 15px;
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    > div:first-of-type {
      margin-bottom: 20px;

      span {
        font-size: 20px;
        font-weight: 600;
      }
    }

    > div:last-of-type {
      justify-content: flex-end;
      margin-top: 25px;
    }

    > table {
      tr td:first-of-type {
        color: @primary-color;
        font-weight: 700;
        padding-right: 10px;
      }

      td {
        color: @input-placeholder-color;
      }
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
`;

export const Placeholder = styled.div`
  background: #ccc;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  span {
    color: #666;
    font-size: 26px;
    font-weight: bold;
  }

  > div {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
  }
`;
