import { memo, useState, useEffect, useMemo } from 'react';
import { notification } from 'antd';

import api from 'services/api';
import { translate } from 'config/i18n';
import { Sale, Transaction, TransactionType } from 'interfaces/sale';
import { getCurrencyByLocale } from 'helpers/stringHelper';
import { transaction } from 'helpers/mapper';
import { Modal } from './styles';

interface ModalProps {
  sale: Sale;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

function SaleDetailModal({ sale, visible, setVisible }: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<
    Array<{ [key: string]: string | number }>
  >([]);

  useEffect(() => {
    (async () => {
      try {
        setDetails([]);
        setLoading(true);
        const paymentMethods = await api.get(`trade/payment-methods`);
        const { data } = await api.get(`trade/product-sales/${sale.id}`);

        data.data.transactions.forEach((x: Transaction) => {
          const amount = getCurrencyByLocale(x.amount / 100);
          const paymentMethod = paymentMethods.data.data.find(
            (y: TransactionType) => y.id === x.paymentMethodId,
          );
          const transactionType = transaction[paymentMethod.name];
          setDetails(prev => [
            ...prev,
            {
              id: x.id,
              amount,
              rawAmount: x.amount,
              transactionType,
            },
          ]);
        });
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [sale.id]);

  const getTotal = useMemo(
    () =>
      getCurrencyByLocale(
        details.reduce((cur, next) => cur + Number(next.rawAmount), 0) / 100,
      ),
    [details],
  );

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
        setDetails([]);
      }}
    >
      <div>
        <div className="modal-header">{translate('pdv.payment_details')}</div>
        <div className="modal-content">
          {loading ? (
            <span>Carregando</span>
          ) : (
            <>
              {details.map(x => (
                <div key={x.id}>
                  <span>{x.transactionType}</span>
                  <span>{x.amount}</span>
                </div>
              ))}

              <div className="total-row">
                <span>{translate('pdv.payment_total')}</span>
                <span>{getTotal}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default memo(SaleDetailModal);
