import { memo, useState, useEffect, useCallback } from 'react';

import Page from 'components/Page';
import DataTable from 'components/DataTable';

import api from 'services/api';

import { EstablishmentState } from 'interfaces/establishment';
import { Menu, notification } from 'antd';

import { defaultPageSize } from 'constants/styles';
import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { translate } from 'config/i18n';

import { Sale } from 'interfaces/sale';
import { parseISO } from 'date-fns/esm';
import { format } from 'date-fns';
import { getCurrencyByLocale } from 'helpers/stringHelper';
import { EllipsisOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Content, Dropdown, MenuItem } from './styles';
import SaleDetailModal from './SaleDetailModal';

function PDV() {
  const [sales, setSales] = useState<Array<any>>([]);
  const [rawSales, setRawSales] = useState<Array<Sale>>([]);
  const [loading, setLoading] = useState(true);
  const [selectedPdv, setSelectedPdv] = useState<Sale>();
  const [salesModalVisible, setSalesModalVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );
  const history = useHistory();

  const columns: any = [
    {
      title: translate('pdv.date_hour'),
      dataIndex: 'date',
    },
    {
      title: translate('pdv.order_code'),
      dataIndex: 'code',
    },
    {
      title: translate('pdv.product'),
      dataIndex: 'identifier',
    },
    {
      title: translate('pdv.value'),
      dataIndex: 'amount',
    },
    {
      title: translate('pdv.operator'),
      dataIndex: 'employeeName',
    },
    {
      title: translate('pdv.reversed'),
      dataIndex: 'reversed',
    },
  ];

  const handleChangeTable = useCallback(
    async (page?: number | undefined, pageSize?: number | undefined) => {
      try {
        setLoading(true);
        const response = await api.get(
          `trade/establishments/${
            currentEstablishment?.id
          }/product-sales?page=${page || 1}&pageSize=${
            pageSize || defaultPageSize
          }`,
        );

        setTotal(response.headers['x-total'] || 0);

        setRawSales(
          response.data.data.map((x: Sale) => ({
            ...x,
            amount: getCurrencyByLocale(x.amount / 100),
            reversed: x.reversedAt ? 'Sim' : 'Não',
          })),
        );
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    },
    [currentEstablishment],
  );

  useEffect(() => {
    async function getPSalesFromServer() {
      handleChangeTable();
    }

    getPSalesFromServer();
  }, [currentEstablishment, handleChangeTable]);

  const DropdownMenu = useCallback(
    sale => (
      <Menu>
        <MenuItem
          onClick={() => {
            setSalesModalVisible(true);
            setSelectedPdv(sale);
          }}
        >
          {translate('pdv.payment_details')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(`pdv/reversal/${sale.id}`);
          }}
        >
          {translate('pdv.sale_reversal')}
        </MenuItem>
      </Menu>
    ),
    [history],
  );

  useEffect(() => {
    setSales(
      rawSales.map((x: Sale) => ({
        ...x,
        identifier: x.identifier.replace(/,/g, '\n'),
        date: format(parseISO(x.createdAt), "dd/MM/yyyy HH'h'mm"),
        actions: (
          <>
            <Dropdown
              trigger={['click']}
              overlay={() => DropdownMenu(x)}
              placement="bottomLeft"
            >
              <EllipsisOutlined className="actions-icon" />
            </Dropdown>
          </>
        ),
      })),
    );
  }, [DropdownMenu, rawSales]);

  return (
    <Page
      displayDrawer
      title={translate('pdv.title')}
      tabs={[
        {
          label: translate('pdv.sales_list'),
          route: '/pdv',
        },
      ]}
    >
      <Content>
        <DataTable
          total={total}
          loading={loading}
          onChange={handleChangeTable}
          columns={columns}
          dataSource={sales}
          rowKey="id"
        />
      </Content>

      {selectedPdv && (
        <SaleDetailModal
          sale={selectedPdv as Sale}
          visible={salesModalVisible}
          setVisible={setSalesModalVisible}
        />
      )}
    </Page>
  );
}

export default memo(PDV);
