import styled from 'styled-components';
import {
  Row as RowAntd,
  Input as AntdInput,
  Button as ShadowedButton,
} from 'antd';
import NumberFormat from 'react-number-format';

export const FormContainer = styled.div``;

export const Section = styled.div`
  padding: 30px 0 20px 0;

  textarea {
    resize: none;
  }

  .dropzone-column {
    padding-top: 10px;
  }

  label {
    font-size: 18px;
    color: #000;
    margin-left: 5px;
    margin-bottom: 5px;
    display: block;
    font-weight: 500;
  }

  label.optional::after {
    content: '(opcional)';
    font-style: italic;
    color: #b5b5b5;
    font-size: 14px;
    margin-left: 8px;
  }

  .label-container {
    align-items: center;
    display: flex;

    label {
      font-size: 18px;
    }
  }

  .clean-button {
    padding-top: initial !important;
    padding-bottom: initial !important;
    height: 60px !important;
  }

  .mb-section {
    margin-bottom: 100px;
  }

  .mb-2 {
    margin-bottom: 20px;
  }

  .single-line {
    display: flex;

    > div:first-of-type {
      margin-right: 20px;
    }

    > div {
      flex: 1;
    }
  }

  .ant-picker {
    width: 100%;
  }
`;

export const SectionTitle = styled.h1`
  font-family: 'Titillium Web', sans-serif;
  color: #000;
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 27.6px;
  margin-bottom: 30px;

  span {
    color: #67687a;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    top: 2px;
    position: relative;
    margin-left: 10px;
  }
`;

export const Input = styled(AntdInput)`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 18px;
  height: 60px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;

  button + button {
    margin-left: 20px;
  }
`;

export const Button = styled(ShadowedButton)`
  text-shadow: none;
  box-shadow: none;
  height: 40px;
  font-size: 16px;
`;

export const PricePrefix = styled.span`
  position: absolute;
  z-index: 1;
  top: 15px;
  font-size: 20px;
  color: #fe6a21;
  left: 15px;

  &.ml {
    left: 30px;
  }
`;

export const PriceField = styled(NumberFormat)``;

interface RowProps {
  visible?: boolean;
}

export const Row = styled(RowAntd)<RowProps>`
  display: ${({ visible }) =>
    visible || visible === undefined ? 'flex' : 'none'};

  div.invisible {
    display: none;
  }
`;

export const PaymentCard = styled.div`
  cursor: pointer;
  padding: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px;
  background: white;
  border: 1px solid #fe6321;

  input {
    margin-top: auto;
  }

  > div {
    margin-left: 15px;
  }

  span {
    color: black;
    font-size: 19px;
  }

  p {
    margin-bottom: 30px;
  }

  .ant-checkbox-inner {
    border-radius: 50%;
  }

  &.unselected {
    color: #c4c4c4 !important;
    border: 0px;
    box-shadow: 0px 5px 20px -5px rgba(133, 133, 133, 0.15);

    span {
      color: #c4c4c4;
    }
  }
`;

export const SwitchContainer = styled.div`
  span {
    margin-left: 10px;
    color: black;
    position: relative;
    top: 3px;
    font-weight: 400;
    font-size: 17px;
  }
`;
