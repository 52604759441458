import { memo, useEffect, useState, useCallback } from 'react';
import { notification, Select, Input } from 'antd';
import { useSelector } from 'react-redux';
import parsePhoneNumber from 'libphonenumber-js';

import { ReduxStore } from 'interfaces/reduxStore';
import PhoneInput from 'react-phone-input-2';
import { EstablishmentState } from 'interfaces/establishment';
import { Role } from 'interfaces/role';
import api from 'services/api';
import { validateEmail } from 'services/validation';
import { establishmentRole } from 'helpers/mapper';
import { Invite } from 'interfaces/invite';
import { translate } from 'config/i18n';
import { Modal, Tabs, TabBarItem, ListItem, Button } from './styles';

const { TabPane } = Tabs;

interface ModalProps {
  visible: boolean;
  callback?: (newInvite: Invite) => void;
  setVisible: (visible: boolean) => void;
}

function InviteModal({ visible, setVisible, callback }: ModalProps) {
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );

  const [emailInput, setEmailInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');

  const [roles, setRoles] = useState<Array<Role>>([]);
  const [preLoading, setPreLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [selectedRoles, setSelectedRoles] = useState<Array<Role>>([]);
  const [selectedEmails, setSelectedEmails] = useState<Array<string>>([]);
  const [selectedPhones, setSelectedPhones] = useState<Array<string>>([]);

  useEffect(() => {
    async function getRolesFromServer() {
      try {
        const { data } = await api.get(
          `/trade/establishments/${currentEstablishment?.id}/roles`,
        );
        setRoles(
          data.data.map((x: { id: string; name: string }) => ({
            ...x,
            name: establishmentRole[x.name],
          })),
        );
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setPreLoading(false);
      }
    }

    if (visible) getRolesFromServer();
  }, [currentEstablishment, visible]);

  useEffect(() => {
    const selectTitle = document.getElementsByClassName(
      'ant-select-selection-item',
    )[0] as HTMLSpanElement;

    if (selectTitle) selectTitle.innerText = 'Permissão';
  }, [roles]);

  const handleSelectRole = useCallback(
    (roleId: string) => {
      const selectedRole = roles.find(role => role.id === roleId);

      if (selectedRole) {
        setSelectedRoles([...selectedRoles, selectedRole]);
        setRoles(roles.filter(role => role.id !== roleId));
      }
    },
    [roles, selectedRoles],
  );

  const handleRemoveRole = useCallback(
    (roleId: string) => {
      const selectedRole = selectedRoles.find(role => role.id === roleId);

      if (selectedRole) {
        setRoles([...roles, selectedRole].sort((a, b) => (a < b ? -1 : 1)));
        setSelectedRoles(selectedRoles.filter(role => role.id !== roleId));
      }
    },
    [selectedRoles, roles],
  );

  const handleRemoveEmail = useCallback(
    (input: string) => {
      setSelectedEmails(selectedEmails.filter(email => email !== input));
    },
    [selectedEmails],
  );

  const handleRemovePhone = useCallback(
    (input: string) => {
      setSelectedPhones(selectedPhones.filter(phone => phone !== input));
    },
    [selectedPhones],
  );

  const handleSelectUser = useCallback(
    (input: string) => {
      if (!input) return;

      if (phoneInput) {
        if (input.length === 2) return;
        const phoneNumber = parsePhoneNumber(`+${input}`);
        if (!phoneNumber?.isValid()) {
          notification.error({
            message: translate('general.error'),
            description: translate('invite.invalid_phone'),
          });
          return;
        }
      } else if (!validateEmail(input)) {
        notification.error({
          message: translate('general.error'),
          description: translate('invite.invalid_email'),
        });
        return;
      }

      const selectedUser =
        selectedEmails.find(email => email === input) ||
        selectedPhones.find(phone => phone === `+${input}`);

      if (!selectedUser) {
        if (phoneInput) setSelectedPhones([...selectedPhones, `+${input}`]);
        else setSelectedEmails([...selectedEmails, input]);
        setEmailInput('');
        const flagDiv = document.getElementsByClassName(
          'selected-flag',
        )[0] as HTMLDivElement;
        if (flagDiv) setPhoneInput(flagDiv.title.split('+')[1]);
      } else {
        notification.error({
          message: translate('general.error'),
          description: translate('invite.data_already_inserted'),
        });
      }
    },
    [phoneInput, selectedEmails, selectedPhones],
  );

  const handleKeyDown = useCallback(
    (e: any, input: string) => {
      if (e.key === 'Enter') {
        handleSelectUser(input);
      }
    },
    [handleSelectUser],
  );

  const resetFields = useCallback(() => {
    const flagDiv = document.getElementsByClassName(
      'selected-flag',
    )[0] as HTMLDivElement;
    if (flagDiv) setPhoneInput(flagDiv.title.split('+')[1]);
    setEmailInput('');
    setPreLoading(true);
    setSelectedRoles([]);
    setSelectedEmails([]);
    setSelectedPhones([]);
  }, []);

  const handleSubmit = useCallback(async () => {
    let error = false;
    if (selectedPhones.length === 0 && selectedEmails.length === 0) {
      error = true;
      notification.error({
        message: translate('general.error'),
        description: translate('invite.select_at_least_one_email_phone'),
      });
    }

    if (selectedRoles.length === 0) {
      error = true;
      notification.error({
        message: translate('general.error'),
        description: translate('invite.select_at_least_one_role'),
      });
    }

    if (error) return;

    try {
      setLoading(true);

      const data: { roles: string[]; email?: string[]; phone?: string[] } = {
        roles: selectedRoles.map(x => x.id),
        email: selectedEmails,
        phone: selectedPhones.map(x =>
          x.replace(/ /g, '').replace('(', '').replace(')', ''),
        ),
      };

      if (data.email?.length === 0) delete data.email;
      if (data.phone?.length === 0) delete data.phone;

      const response = await api.post(
        `trade/establishments/${currentEstablishment?.id}/invites`,
        data,
      );

      if (callback) callback(response.data.data[0]);

      notification.success({
        message: translate('general.success'),
        description: translate('invite.invites_sent'),
      });
      setVisible(false);
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
    }
  }, [
    selectedPhones,
    selectedEmails,
    currentEstablishment,
    selectedRoles,
    setVisible,
    callback,
  ]);

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      afterClose={resetFields}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">
        {translate('employee.invite_employees')}
      </div>
      <div className="modal-content">
        <Tabs defaultActiveKey="1" tabBarGutter={0}>
          <TabPane
            key="1"
            tab={<TabBarItem>{translate('employee.email')}</TabBarItem>}
          >
            <Input
              onKeyDown={e => handleKeyDown(e, emailInput)}
              onBlur={() => handleSelectUser(emailInput)}
              placeholder={translate('employee.email')}
              value={emailInput}
              onChange={e => {
                setEmailInput(e.target.value);
                setPhoneInput('');
              }}
            />
          </TabPane>
          <TabPane
            key="2"
            tab={<TabBarItem>{translate('employee.phone')}</TabBarItem>}
          >
            <PhoneInput
              onKeyDown={e => handleKeyDown(e, phoneInput)}
              onBlur={() => handleSelectUser(phoneInput)}
              country="br"
              inputClass="ant-input"
              value={phoneInput}
              onChange={val => {
                setPhoneInput(val);
                setEmailInput('');
              }}
            />
          </TabPane>
        </Tabs>
        <div>
          <ListItem>
            {selectedEmails.map((email, index) => (
              <li key={['email_', index].join('_')}>
                {email}
                <button
                  className="btn-clean"
                  type="button"
                  onClick={() => handleRemoveEmail(email)}
                >
                  x
                </button>
              </li>
            ))}
            {selectedPhones.map((phone, index) => (
              <li key={['phone_', index].join('_')}>
                {phone}
                <button
                  className="btn-clean"
                  type="button"
                  onClick={() => handleRemovePhone(phone)}
                >
                  x
                </button>
              </li>
            ))}
          </ListItem>
        </div>
        <Select
          className="select-roles"
          placeholder={
            preLoading
              ? `${translate('general.loading')}...`
              : translate('employee.role')
          }
          onChange={handleSelectRole}
          disabled={preLoading}
        >
          {roles.map(role => (
            <Select.Option key={role.id} value={role.id}>
              {role.name}
            </Select.Option>
          ))}
        </Select>
        <div>
          <ListItem>
            {selectedRoles.map(role => (
              <li key={role.id}>
                {role.name}
                <button
                  className="btn-clean"
                  type="button"
                  onClick={() => handleRemoveRole(role.id)}
                >
                  x
                </button>
              </li>
            ))}
          </ListItem>
        </div>
      </div>
      <div className="align-center">
        <Button
          onClick={handleSubmit}
          disabled={loading}
          htmlType="button"
          type="primary"
          size="large"
        >
          {loading
            ? `${translate('general.loading')}...`
            : translate('employee.send_invites')}
        </Button>
      </div>
    </Modal>
  );
}

InviteModal.defaultProps = {
  callback: undefined,
};

export default memo(InviteModal);
