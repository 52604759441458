import { useEffect, useState } from 'react';
import { Row } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { translate } from 'config/i18n';
import GoogleIcon from 'assets/svg/google.svg';
import { useDispatch } from 'react-redux';
import { loginWithSocial } from 'store/ducks/auth';
import { useQuery } from 'hooks/useQuery';
import { API_ENDPOINT } from 'constants/environment';
import { usePermissions } from 'hooks/usePermissions';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';

import {
  // FormTitle,
  FormContainer,
  HighlightText,
} from '../styles';
import {
  NoAccountText,
  Tabs,
  TabBarItem,
  SocialLoginContainer,
} from './styles';

import Header from './Header';
import { LoginLayout } from '../Layout';

const { TabPane } = Tabs;

function LoginForm(): JSX.Element {
  const dispatch = useDispatch();
  const { clearCurrentEstablishment, addLoggedUser } = usePermissions();
  const query = useQuery();
  const location = useLocation();
  const [redirectUrl] = useState<string | null>(query.get('redirect'));

  useEffect(() => {
    const params = location.search;

    clearCurrentEstablishment();
    if (params && params.includes('code=') && params.includes('scope=')) {
      dispatch(
        loginWithSocial(params, user => {
          if (redirectUrl) window.location.href = redirectUrl;
          addLoggedUser(user);
        }),
      );
    }
  }, [
    dispatch,
    location.search,
    redirectUrl,
    clearCurrentEstablishment,
    addLoggedUser,
  ]);

  return (
    <LoginLayout>
      <Header />
      <FormContainer>
        {/* <FormTitle>Faça o login.</FormTitle> */}
        <Tabs defaultActiveKey="1" tabBarGutter={0}>
          <TabPane
            key="1"
            tab={<TabBarItem>{translate('auth.email')}</TabBarItem>}
          >
            <EmailForm />
          </TabPane>
          <TabPane
            key="2"
            tab={<TabBarItem>{translate('auth.phone')}</TabBarItem>}
          >
            <PhoneForm />
          </TabPane>
        </Tabs>
        <SocialLoginContainer>
          <p>Ou cadastre-se usando uma dessas redes sociais</p>
          <Row justify="center">
            <a href={`${API_ENDPOINT}/connect/google`}>
              <img src={GoogleIcon} alt="Google" />
            </a>
          </Row>
        </SocialLoginContainer>
        <Row justify="center">
          <NoAccountText>
            {translate('auth.dont_have_account')}{' '}
            <Link to={`/signup${window.location.search}`}>
              <HighlightText strong>
                {translate('auth.register_clicking_here')}
              </HighlightText>
            </Link>
          </NoAccountText>
        </Row>
      </FormContainer>
    </LoginLayout>
  );
}

export default LoginForm;
