/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState } from 'react';
import DropzoneComponent, { DropzoneOptions, FileError } from 'react-dropzone';
import { CloudUploadOutlined } from '@ant-design/icons';
import IndicatorIcon from 'assets/svg/search.svg';

import { notification } from 'antd';
import { translate } from 'config/i18n';
import { ImageUpload, Indicator } from './styles';

interface IDropzoneProps extends DropzoneOptions {
  logoPreview: string | null | undefined;
  alt: string | undefined;
  maxSize?: number;
  accept?: string | string[];
  width?: number;
  rounded?: boolean;
  loading?: boolean;
  showIndicator?: boolean;
  onClean: (e: any) => void;
}

export default function Dropzone({
  onDropAccepted,
  onClean,
  logoPreview = '',
  maxSize,
  accept,
  width,
  rounded,
  loading,
  showIndicator,
  alt,
}: IDropzoneProps): JSX.Element {
  const [visibleRemove, setVisibleRemove] = useState(!rounded);

  const onDropRejected = useCallback(
    rejectedFiles => {
      const imgData = rejectedFiles[0];

      imgData.errors.forEach((error: FileError) => {
        if (error.code === 'file-too-large') {
          const maxFileSize = maxSize || 1048576;
          notification.error({
            message: translate('general.error'),
            description: translate('validation_messages.file_max_size', {
              maxFileSize: maxFileSize / 1048576,
            }),
          });
        }
      });
    },
    [maxSize],
  );

  return (
    <DropzoneComponent
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      accept={accept}
      maxSize={maxSize}
      multiple={false}
      data-testid="dropzone"
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <ImageUpload
          $width={width}
          rounded={rounded}
          $loading={loading}
          hasPreview={!!logoPreview}
          {...getRootProps()}
          hasLogoPreview={!!logoPreview}
          isDragActive={isDragActive}
          onMouseOver={() => {
            if (rounded) setVisibleRemove(true);
          }}
          onMouseOut={() => {
            if (rounded) setVisibleRemove(false);
          }}
        >
          <input {...getInputProps()} />

          {logoPreview ? (
            <>
              <button
                type="button"
                className={`image-clear-btn ${rounded ? 'rounded' : ''}`}
                style={{ display: visibleRemove ? 'block' : 'none' }}
                onClick={onClean}
                data-testid="dropzone-clear"
              >
                <span>x</span>
              </button>
              <img src={logoPreview} alt={alt} />
            </>
          ) : (
            <>
              <CloudUploadOutlined />
              {isDragActive ? (
                <p>{translate('general.drop_image_here')}</p>
              ) : (
                <p>{translate('general.drag_and_drop_file_or_click')}</p>
              )}
            </>
          )}
          {showIndicator && (
            <Indicator data-testid="dropzone-indicator">
              <img
                src={IndicatorIcon}
                alt={translate('profile.upload_indicator')}
              />
            </Indicator>
          )}
        </ImageUpload>
      )}
    </DropzoneComponent>
  );
}

Dropzone.defaultProps = {
  maxSize: 1048576,
  width: undefined,
  rounded: false,
  loading: false,
  showIndicator: false,
  accept: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
  ],
};
