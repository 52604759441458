import { Modules } from 'constants/modules';
import { Roles } from 'constants/roles';
import { StorageKeys } from 'constants/storage';
import { establishmentRole, rolesHierarchy } from 'helpers/mapper';
import { Establishment, Role } from 'interfaces/establishment';
import { User } from 'interfaces/user';
import { createContext, useCallback, useContext, useState } from 'react';
import api from 'services/api';
import { storeCurrentEstablishment, storeUser } from 'services/storage';

interface PermissionsContextData {
  addCurrentEstablishment: (establishment: Establishment) => void;
  addLoggedUser: (user: User) => void;
  clearCurrentEstablishment: () => void;
  refreshCurrentEstablishment: (establishmentId: string) => void;
  clearLoggedUser: () => void;
  has: (
    modules: Array<Modules>,
    roles?: Array<Roles>,
    establishment?: Establishment | null,
  ) => boolean;
  getPermission: () => string[];
  loggedUser: User;
  currentEstablishment: Establishment;
}

interface IPermissionsProviderProps {
  children: JSX.Element | JSX.Element[];
}

const PermissionsContext = createContext({} as PermissionsContextData);

const PermissionsProvider = ({
  children,
}: IPermissionsProviderProps): JSX.Element => {
  const [loggedUser, setLoggedUser] = useState(() => {
    const serializedUser = localStorage.getItem(StorageKeys.USER);
    if (!serializedUser) return null;
    return JSON.parse(serializedUser);
  });

  const [
    currentEstablishment,
    setCurrentEstablishment,
  ] = useState<Establishment>(() => {
    const serializedEstablishment = localStorage.getItem(
      StorageKeys.CURRENT_ESTABLISHMENT,
    );
    if (!serializedEstablishment) return null;
    return JSON.parse(serializedEstablishment);
  });

  const addCurrentEstablishment = useCallback(establishment => {
    setCurrentEstablishment(establishment);
    storeCurrentEstablishment(establishment);
  }, []);

  const addLoggedUser = useCallback(user => {
    setLoggedUser(user);
    storeUser(user);
  }, []);

  const clearCurrentEstablishment = useCallback(() => {
    setCurrentEstablishment({} as Establishment);
  }, []);

  const clearLoggedUser = useCallback(() => {
    setLoggedUser({} as User);
  }, []);

  const refreshCurrentEstablishment = useCallback(
    async establishmentId => {
      try {
        const {
          data: { data },
        } = await api.get(
          `trade/establishments/${establishmentId}?integrations=true&roles=true`,
        );

        addCurrentEstablishment(data);
      } catch {
        //
      }
    },
    [addCurrentEstablishment],
  );

  const getPermission = useCallback(() => {
    if (
      !currentEstablishment ||
      (currentEstablishment && Object.keys(currentEstablishment).length === 0)
    ) {
      return [];
    }
    const roles = rolesHierarchy
      .filter(x =>
        currentEstablishment?.employees[0]?.roles
          .map(y => y.name)
          .includes(x.role),
      )
      .sort((a, b) => (a.weight < b.weight ? -1 : 1));

    if (!roles.length) return [];

    const maxWeight = roles[0]?.weight;

    return roles
      .filter(x => x.weight === maxWeight)
      .map(x => establishmentRole[x.role]);
  }, [currentEstablishment]);

  const has = useCallback(
    (modules = [], roles = [], establishment = null) => {
      const userRoles = establishment?.employees
        ? establishment?.employees[0]?.roles?.map((x: Role) =>
            x.name.toLowerCase(),
          )
        : currentEstablishment?.employees
        ? currentEstablishment?.employees[0]?.roles.map(x =>
            x.name.toLowerCase(),
          )
        : [];

      let allow =
        !roles || !userRoles
          ? true
          : roles.some((x: string) => userRoles.indexOf(x.toLowerCase()) > -1);

      function checkStockModule() {
        const stockIntegration = currentEstablishment?.integrations?.stock;
        return stockIntegration && !stockIntegration.disabledAt;
      }

      function checkFiscalModule() {
        const stockIntegration = currentEstablishment?.integrations?.enotas;
        return stockIntegration && !stockIntegration.disabledAt;
      }

      modules.forEach((x: string) => {
        switch (x) {
          case Modules.STOCK:
            allow = checkStockModule();
            break;
          case Modules.FISCAL:
            allow = checkFiscalModule();
            break;
          default:
            break;
        }
      });

      return allow;
    },
    [currentEstablishment],
  );

  return (
    <PermissionsContext.Provider
      value={{
        addCurrentEstablishment,
        addLoggedUser,
        refreshCurrentEstablishment,
        clearCurrentEstablishment,
        clearLoggedUser,
        has,
        getPermission,
        loggedUser,
        currentEstablishment,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

function usePermissions(): PermissionsContextData {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }

  return context;
}

export { PermissionsProvider, usePermissions };
