import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  .card {
    position: relative;
    border-radius: 5px;
    background: red;
    height: 207px;
    cursor: pointer;
    background-size: cover;
    background-position: center;

    button {
      padding: 15px 24px;
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 100%;

      span {
        color: white;
        font-weight: 600;
        font-size: 23px;
        line-height: 28px;
        z-index: 9;
      }

      &:hover::before {
        content: '';
        position: absolute;
        border-radius: 5px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) -4.35%,
          rgba(205, 61, 0, 0.804812) 71.28%,
          #ff4c00 100%
        );
      }
    }
  }
`;
