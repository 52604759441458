import { memo, useState, useCallback, useEffect } from 'react';
import { Col, Input, notification, Row, Select } from 'antd';

import api from 'services/api';
import { Icon, ProductCategory, Workflow } from 'interfaces/product';
import { translate } from 'config/i18n';
import { gutter } from 'constants/styles';
import { Modal, Button, Label, AddIconButton, PlaceholderIcon } from './styles';
import AddIconModal from './AddIconModal';

interface ModalProps {
  establishmentId: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  callBack: () => void;
  productCategory?: ProductCategory;
}

interface FormDataProps {
  name: string;
  productCategoryId: string;
  categoryIconId: string;
  deliveryWorkflowId?: string;
}

function FormModal({
  establishmentId,
  visible,
  setVisible,
  callBack,
  productCategory,
}: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [addIconModalVisible, setAddIconModalVisible] = useState(false);
  const [name, setName] = useState(productCategory?.name || '');
  const [productCategoryBaseList, setProductCategoryBaseList] = useState<
    ProductCategory[]
  >([]);
  const [workflowList, setWorkflowList] = useState<Workflow[]>([]);
  const [iconList, setIconList] = useState<Icon[]>([]);
  const [productCategoryBaseId, setProductCategoryBaseId] = useState(
    productCategory?.productCategoryId || '',
  );
  const [workflowId, setWorkflowId] = useState(
    productCategory?.deliveryWorkflowId || '',
  );

  const [iconId, setIconId] = useState(productCategory?.categoryIconId || '');

  useEffect(() => {
    async function getDataFromServer() {
      try {
        if (productCategory) {
          setName(productCategory.name);
          setProductCategoryBaseId(productCategory.productCategoryId);
          setWorkflowId(productCategory.deliveryWorkflowId);
          setIconId(productCategory.categoryIconId);
        }

        const promises = [];

        promises.push(api.get('trade/product-categories'));
        promises.push(api.get('trade/delivery-workflows'));
        promises.push(api.get('trade/category-icons'));
        const responses = await Promise.all(promises);

        const translated = responses[0].data?.data
          .map((x: any) => ({
            ...x,
            name: translate(
              `category_base.${x.name.toLowerCase().replace(' ', '_')}`,
            ),
          }))
          .sort((a: any, b: any) => Intl.Collator().compare(a.name, b.name));

        setProductCategoryBaseList(translated);
        setWorkflowList(responses[1].data?.data);
        setIconList(
          responses[2].data?.data.map((x: any) => ({
            ...x,
            name: translate(
              `icons.${x.name.replace('-', '_').replace('.svg', '')}`,
            ),
          })),
        );
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      }
    }

    getDataFromServer();
  }, [productCategory]);

  const resetFields = useCallback(() => {
    setName('');
    setIconId('');
    setWorkflowId('');
    setProductCategoryBaseId('');
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!name) {
      notification.error({
        message: translate('general.error'),
        description: translate('validation_messages.required_field', {
          field: 'product.name',
        }),
      });
      return;
    }

    if (!iconId) {
      notification.error({
        message: translate('general.error'),
        description: translate('validation_messages.required_field', {
          field: 'product.icon',
        }),
      });
      return;
    }

    if (!productCategoryBaseId) {
      notification.error({
        message: translate('general.error'),
        description: translate('validation_messages.required_field', {
          field: 'product.base_category',
        }),
      });
      return;
    }

    try {
      setLoading(true);

      const formData = {
        name,
        productCategoryId: productCategoryBaseId,
        categoryIconId: iconId,
        deliveryWorkflowId: workflowId,
      } as FormDataProps;

      if (!workflowId) delete formData.deliveryWorkflowId;

      if (productCategory)
        await api.put(
          `trade/establishment-product-categories/${productCategory.id}`,
          formData,
        );
      else
        await api.post(
          `trade/establishments/${establishmentId}/product-categories`,
          formData,
        );

      callBack?.();
      notification.success({
        message: translate('general.success'),
        description: translate('product.product_category_success_message'),
      });
      setVisible(false);
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
    }
  }, [
    setVisible,
    name,
    establishmentId,
    productCategoryBaseId,
    productCategory,
    callBack,
    workflowId,
    iconId,
  ]);

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      afterClose={resetFields}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">
        {translate('product.category_registration')}
      </div>
      <div className="modal-content">
        <Row gutter={gutter}>
          <Col sm={24}>
            {iconId && iconList.length ? (
              <PlaceholderIcon onClick={() => setAddIconModalVisible(true)}>
                <img
                  src={iconList.find(x => x.id === iconId)?.icon.location}
                  alt={
                    iconList.find(x => x.id === iconId)?.icon.name || 'default'
                  }
                />
              </PlaceholderIcon>
            ) : (
              <AddIconButton
                type="button"
                className="btn-clean"
                onClick={() => setAddIconModalVisible(true)}
              >
                <span>+</span> Adicionar ícone
              </AddIconButton>
            )}
          </Col>
        </Row>
        <Row gutter={gutter}>
          <Col sm={24}>
            <Label>{translate('product.name')}</Label>
            <Input
              value={name}
              onChange={e => {
                setName(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row gutter={gutter} style={{ marginTop: 10 }}>
          <Col sm={24}>
            <Label>{translate('product.base_category')}</Label>
            <Select
              style={{ width: '100%' }}
              showSearch
              defaultValue={productCategory?.productCategoryId}
              onChange={(id: string) => setProductCategoryBaseId(id)}
              filterOption={(input, option) => {
                const rawOptionValue = option?.children
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase();
                const rawInputValue = input
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase();

                return rawOptionValue.indexOf(rawInputValue) >= 0;
              }}
              placeholder={`${translate('product.category')}*`}
            >
              {productCategoryBaseList.map(categoryBase => (
                <Select.Option key={categoryBase.id} value={categoryBase.id}>
                  {categoryBase.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={gutter} style={{ marginTop: 10 }}>
          <Col sm={24}>
            <Label>{translate('product.delivery_workflow')}</Label>
            <Select
              style={{ width: '100%' }}
              showSearch
              defaultValue={productCategory?.deliveryWorkflowId}
              onChange={(id: string) => setWorkflowId(id)}
              filterOption={(input, option) => {
                const rawOptionValue = option?.children
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase();
                const rawInputValue = input
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase();

                return rawOptionValue.indexOf(rawInputValue) >= 0;
              }}
              placeholder={`${translate('product.delivery_workflow')}*`}
            >
              {workflowList.map(workflow => (
                <Select.Option key={workflow.id} value={workflow.id}>
                  {workflow.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>
      <div className="align-center">
        <Button
          onClick={handleSubmit}
          disabled={loading}
          htmlType="button"
          type="primary"
          size="large"
        >
          {loading
            ? `${translate('general.loading')}...`
            : translate('general.conclude')}
        </Button>
      </div>

      <AddIconModal
        visible={addIconModalVisible}
        setVisible={setAddIconModalVisible}
        iconList={iconList}
        setIconId={setIconId}
      />
    </Modal>
  );
}

export default memo(FormModal);
