import styled, { css } from 'styled-components';

interface ImageUploadProps {
  isDragActive: boolean;
  hasLogoPreview: boolean;
  $width: number | undefined;
  rounded: boolean | undefined;
  $loading: boolean | undefined;
  hasPreview: boolean | undefined;
}

export const Indicator = styled.div`
  background: #ff6d08;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  transform: translateX(40%);
  transform: translateY(40%);

  img {
    width: 70% !important;
    height: 70% !important;
    margin: 0 !important;
  }
`;

export const ImageUpload = styled.div<ImageUploadProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 250px;
  height: 250px;
  padding: 30px;
  background: #fff;
  position: relative;
  outline: 0;
  border-radius: 5px;
  flex-direction: column;

  &:hover {
    box-shadow: 0 5px 10px 0 #ff6d0844;

    .icon-container {
      background: #ff6d08;

      .anticon.anticon-cloud-upload {
        color: #ffffff !important;
      }
    }

    .remove-layer {
      display: flex;
    }
  }

  span {
    color: #67687a;
    font-size: 16px;
  }

  .anticon.anticon-cloud-upload {
    font-size: 40px;
    color: #ff6d08;
    left: -2px;
    top: -2px;
    position: relative;
  }

  button {
    background: #ff6d08;
    margin-left: auto;
    color: #fff;
    border-radius: 10px;
    height: 40px;
    padding: 0 15px;
  }

  border: 3px solid #ffefe9;

  ${props =>
    props.hasLogoPreview &&
    css`
      padding: 0;
      background: transparent;
    `}

  ${props =>
    props.$width &&
    css`
      width: ${props.$width}px;
    `}
  ${props =>
    props.$width &&
    css`
      height: ${props.$width}px;
    `}

  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${props =>
      props.rounded
        ? css`
            border-radius: 50%;
          `
        : css`
            border-radius: 5px;
          `}

    ${props =>
      props.$loading
        ? css`
            opacity: 0.6;
          `
        : css`
            opacity: 1;
          `}
  }
`;

export const IconContainer = styled.div`
  background: #ffefe9;
  padding: 15px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-bottom: 15px;
`;

export const PreviewContainer = styled.div`
  border: 1px solid #ececec;
  border-radius: 5px;
  width: 140px;
  height: 140px;
  position: relative;
  padding: 5px;
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  overflow-wrap: break-word;
  margin-top: 10px;
  display: block;
  color: #000;

  span {
    display: block;
    font-size: 12px;
    color: #67687a;
  }
`;

export const RemoveLayer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background: #ff000099;
  border-radius: 5px;
`;

export const RemoveIconContainer = styled.div`
  background: #fff !important;
  padding: 15px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-bottom: 15px;
  position: relative;
  top: 5px;

  .anticon.anticon-delete {
    color: red;
    font-size: 28px;
    top: 3px;
    position: relative;
  }
`;
