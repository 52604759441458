import { ReduxAction } from 'interfaces/redux';
import { Product, ProductState } from 'interfaces/product';
import { Dispatch } from 'redux';
import { showError } from 'services/error';
import api from 'services/api';
import { Tax } from 'interfaces/Tax';
import { translate } from 'config/i18n';

export enum ProductTypes {
  LOADING = 'PRODUCT/LOADING',
  STOP_LOADING = 'PRODUCT/STOP_LOADING',
}

const initialState: ProductState = {
  loading: false,
};

export default function productActions(
  state: ProductState = initialState,
  action: ReduxAction<ProductTypes>,
): ProductState {
  switch (action.type) {
    case ProductTypes.LOADING:
      return { ...state, loading: true };
    case ProductTypes.STOP_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export const actionLoading = (): ReduxAction<ProductTypes> => ({
  type: ProductTypes.LOADING,
});

export const actionStopLoading = (): ReduxAction<ProductTypes> => ({
  type: ProductTypes.STOP_LOADING,
});

const uploadImage = async (
  imageData: File | null,
  productId: string,
  isToRemoveImage?: boolean,
) => {
  if (imageData) {
    const formData = new FormData();

    formData.append('productImage', imageData);

    await api.put(`trade/products/${productId}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  if (isToRemoveImage === true) {
    await api.delete(`trade/products/${productId}/image`);
  }
};

export const create = (
  establishmentId: string,
  input: Product,
  imageData: File | null,
  successCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    const { data } = await api.post(
      `trade/establishments/${establishmentId}/products`,
      input,
    );

    try {
      await uploadImage(imageData, data.data.id);
    } catch {
      showError('Ocorreu um erro com a atualização da imagem.');
    }

    if (successCallback) successCallback();
  } catch (error) {
    showError(translate('general_messages.request_error'));
  }
  dispatch(actionStopLoading());
};

export const update = (
  input: Product,
  imageData: File | null,
  successCallback?: () => void,
  isToRemoveImage?: boolean,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    const { data } = await api.put(`trade/products/${input.id}`, input);

    try {
      await uploadImage(imageData, data.data.id, isToRemoveImage);
      if (successCallback) successCallback();
    } catch {
      showError(
        'As alterações foram salvas mas ocorreu um erro com a atualização da imagem.',
      );
    }
  } catch (error) {
    showError(translate('general_messages.request_error'));
  }
  dispatch(actionStopLoading());
};

export const updateTaxes = (
  productId: string,
  input: Tax,
  successCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    await api.put(`trade/products/${productId}/taxes`, input);

    successCallback?.();
  } catch (error) {
    showError(translate('general_messages.request_error'));
  }
  dispatch(actionStopLoading());
};
