import { memo, useState, useCallback } from 'react';
import { Checkbox, notification } from 'antd';

import api from 'services/api';
import { translate } from 'config/i18n';
import data from '../data.json';
import { Modal, Button } from './styles';
import { CategoryBaseType } from '..';

interface ModalProps {
  establishmentId: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  callBack?: () => void;
  categoryBase: CategoryBaseType;
}

const translated = {
  bar: 'Bar',
  restaurant: 'Restaurante',
  club: 'Parque aquático',
  nightClub: 'Casa noturna',
  party: 'Festival',
};

function AddCategoriesModal({
  establishmentId,
  visible,
  setVisible,
  callBack,
  categoryBase,
}: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(() =>
    data[categoryBase!].map(x => ({ ...x, selected: true })),
  );

  const handleSubmit = useCallback(async () => {
    if (!categories.filter(x => x.selected).length) {
      notification.error({
        message: translate('general.error'),
        description: 'Selecione ao menos uma categoria',
      });
      return;
    }

    try {
      setLoading(true);

      await api.post(
        `trade/establishments/${establishmentId}/product-categories-sets`,
        {
          categories: categories
            .filter(x => x.selected)
            .map(x => ({
              name: x.name,
              productCategoryId: x.productCategoryId,
            })),
        },
      );

      callBack?.();
      notification.success({
        message: translate('general.success'),
        description: translate('product.product_category_success_message'),
      });
      setVisible(false);
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
    }
  }, [categories, establishmentId, callBack, setVisible]);

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      afterClose={() => {
        setCategories(
          data[categoryBase!].map(x => ({ ...x, selected: false })),
        );
      }}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">
        Modelo de estabelecimento:{' '}
        <span className="hightlight">{translated[categoryBase!]}</span>
        <p>
          Para facilitar selecionamos as categorias mais comuns desta modalidade
          para você cadastrar seus produtos.
        </p>
        <p>Caso não queira algum ítem na sua listagem basta desmarcar.</p>
      </div>
      <div className="modal-content">
        {categories.map(x => (
          <div key={x.name} className="category">
            <Checkbox
              checked={x.selected}
              onChange={() => {
                setCategories(prev =>
                  prev.map(y =>
                    y.productCategoryId === x.productCategoryId
                      ? { ...y, selected: !y.selected }
                      : y,
                  ),
                );
              }}
            />
            {x.name}
          </div>
        ))}
      </div>
      <div className="align-center">
        <Button
          onClick={handleSubmit}
          disabled={loading}
          htmlType="button"
          type="primary"
          size="large"
        >
          {loading
            ? `${translate('general.loading')}...`
            : translate('general.conclude')}
        </Button>
      </div>
    </Modal>
  );
}

export default memo(AddCategoriesModal);
