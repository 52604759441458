import { combineReducers } from 'redux';
import auth from './auth';
import user from './user';
import establishment from './establishment';
import product from './product';
import campaign from './campaign';

const reducers = combineReducers({
  auth,
  user,
  establishment,
  product,
  campaign,
});

export default reducers;
