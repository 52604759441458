import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SENTRY_DSN } from 'constants/environment';
import { isDevelopment } from 'services/environment';
import App from './App';

if (!isDevelopment()) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

// TODO: verificar o motivo de estar dando erro quando em StrictMode (Módulos de InputMask e ANTD)
ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root'),
);
