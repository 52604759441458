import { memo } from 'react';

import { Operation } from 'interfaces/operation';
import { translate } from 'config/i18n';

import { getCurrencyByLocale } from 'helpers/stringHelper';
import { Modal, Separator } from './styles';

interface IOperation {
  id: string;
  name: string;
}

interface ModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  operation: Operation;
  operationModesList: Array<IOperation>;
  operationCategoriesList: Array<IOperation>;
}

function DetailsModal({
  visible,
  setVisible,
  operation,
  operationModesList,
  operationCategoriesList,
}: ModalProps) {
  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">
        {translate('operation.establishment_options')}
      </div>
      <div className="modal-content">
        <Separator />
        <div className="grid">
          <div className="card">
            <span>{translate('establishment.min_value_in_card_payment')}</span>
            <span>
              {getCurrencyByLocale(operation.minValueInCardPayment / 100)}
            </span>
          </div>
          <div className="card">
            <span>{translate('establishment.audience_capacity')}</span>
            <span>
              {translate('establishment.audience_capacity_count', {
                count: operation.audienceCapacity,
              })}
            </span>
          </div>
          <div className="card">
            <span>{translate('establishment.age_group')}</span>
            <span>{`${operation.ageGroup} ano${
              operation.ageGroup > 1 ? 's' : ''
            }`}</span>
          </div>
        </div>
        <Separator />
        <div className="row">
          <div className="card">
            <span>{translate('establishment.operation_category')}</span>
            <span>
              {
                operationCategoriesList.find(
                  x => x.id === operation.operationCategoryId,
                )?.name
              }
            </span>
          </div>
          <div className="card">
            <span>{translate('establishment.operation_mode')}</span>
            <span>
              {
                operationModesList.find(x => x.id === operation.operationModeId)
                  ?.name
              }
            </span>
          </div>
        </div>
        <Separator />
        <div className="card">
          <span>{translate('establishment.show_brands_in_payment')}</span>
          <span>{operation.showBrandsInPayment ? 'Ativo' : 'Inativo'}</span>
        </div>
      </div>
    </Modal>
  );
}

export default memo(DetailsModal);
