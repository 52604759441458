import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  flex: 1;
  padding-bottom: 133px;

  .subHeader {
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 30px;
  }
`;

export const Content = styled.section`
  height: 100%;
  flex: 1;
  padding: 0 2rem;
  max-width: 1500px;
  margin: 0 auto;
  padding-top: 1.5rem;
`;

export const InnerContent = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 1.5rem;
`;

export const TopFixed = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
  border-bottom: 2px solid #ececec;
  z-index: 2;
  padding-left: 30px;

  .headerContainer {
    max-width: 1500px;
    margin: 0 auto;
  }
`;

export const LoadingContainer = styled.div`
  text-align: center;
`;

export const Footer = styled.footer`
  border-top: 1px solid #ececec;
  padding: 26px 68px;
  z-index: 4;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
`;
