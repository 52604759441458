import { ReduxAction } from 'interfaces/redux';
import { Establishment, EstablishmentState } from 'interfaces/establishment';
import { Dispatch } from 'redux';
import { showError } from 'services/error';
import api from 'services/api';
import {
  getCurrentEstablishment,
  removeCurrentEstablishment,
  storeCurrentEstablishment,
} from 'services/storage';
import { setTag } from 'services/sentry';
import { isDevelopment } from 'services/environment';
import { translate } from 'config/i18n';

export enum EstablishmentTypes {
  LOADING = 'ESTABLISHMENT/LOADING',
  STOP_LOADING = 'ESTABLISHMENT/STOP_LOADING',
  SET_CURRENT_ESTABLISHMENT = 'ESTABLISHMENT/SET_CURRENT_ESTABLISHMENT',
  CLEAR_CURRENT_ESTABLISHMENT = 'ESTABLISHMENT/CLEAR_CURRENT_ESTABLISHMENT',
}

const initialState: EstablishmentState = {
  loading: false,
  currentEstablishment: getCurrentEstablishment(),
};

export default function establishmentActions(
  state: EstablishmentState = initialState,
  action: ReduxAction<EstablishmentTypes>,
): EstablishmentState {
  switch (action.type) {
    case EstablishmentTypes.LOADING:
      return { ...state, loading: true };
    case EstablishmentTypes.STOP_LOADING:
      return { ...state, loading: false };
    case EstablishmentTypes.SET_CURRENT_ESTABLISHMENT:
      return { ...state, currentEstablishment: action.payload };
    case EstablishmentTypes.CLEAR_CURRENT_ESTABLISHMENT:
      return { ...state, currentEstablishment: null };
    default:
      return state;
  }
}

export const actionLoading = (): ReduxAction<EstablishmentTypes> => ({
  type: EstablishmentTypes.LOADING,
});

export const actionStopLoading = (): ReduxAction<EstablishmentTypes> => ({
  type: EstablishmentTypes.STOP_LOADING,
});

export const actionSetCurrentEstablishment = (
  establishment: Establishment,
): ReduxAction<EstablishmentTypes> => ({
  type: EstablishmentTypes.SET_CURRENT_ESTABLISHMENT,
  payload: establishment,
});

export const actionClearCurrentEstablishment = (): ReduxAction<EstablishmentTypes> => ({
  type: EstablishmentTypes.CLEAR_CURRENT_ESTABLISHMENT,
});

export interface EstablishmentInput {
  id?: string;
  companyName?: string;
  brandColor?: string;
  description?: string;
  website?: string;
  email: string;
  phone: string;
  document?: string;
  tradeName?: string;
  stateRegistration?: string;
  townRegistration?: string;
  postalCode: string;
  line1: string;
  line2: string;
  line3?: string;
  neighborhood: string;
  city: string;
  state: string;
  partnerName: string;
  partnerDocument: string;
  partnerBirthdate: string;
  couvert: number;
  transactionLimit: number;
}

export const setCurrentEstablishment = (
  currentEstablishment: Establishment,
  successCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  storeCurrentEstablishment(currentEstablishment);
  dispatch(actionSetCurrentEstablishment(currentEstablishment));

  if (!isDevelopment())
    setTag({ tagName: 'establishmentId', value: currentEstablishment.id });
  if (successCallback) successCallback();
};

const uploadImage = async (
  imageData: File | null,
  establishmentId: string,
  isToRemoveImage?: boolean,
) => {
  if (imageData) {
    const formData = new FormData();

    formData.append('logo', imageData);

    const { data } = await api.put(
      `trade/establishments/${establishmentId}/logo`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data.data;
  }

  if (isToRemoveImage === true) {
    await api.delete(`trade/establishments/${establishmentId}/logo`);
  }

  return null;
};

export const clearCurrentEstablishment = () => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(actionClearCurrentEstablishment());
  removeCurrentEstablishment();
  if (!isDevelopment())
    setTag({ tagName: 'establishmentId', value: undefined });
};

export const create = (
  input: EstablishmentInput,
  imageData: File | null,
  successCallback?: () => void,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    const { data } = await api.post('trade/establishments', input);

    try {
      await uploadImage(imageData, data.data.id);
    } catch {
      showError('Ocorreu um erro com a atualização da imagem.');
    }

    if (successCallback) successCallback();
  } catch (error: any) {
    showError(translate('general_messages.request_error'));
  }
  dispatch(actionStopLoading());
};

export const update = (
  input: EstablishmentInput,
  imageData: File | null,
  successCallback?: () => void,
  isToRemoveImage?: boolean,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(actionLoading());
  try {
    const { data } = await api.put(`trade/establishments/${input.id}`, input);

    try {
      const logo = await uploadImage(imageData, data.data.id, isToRemoveImage);

      data.data.logo = logo;

      const currentEstablishment = getCurrentEstablishment();

      const updatedEstablishment = currentEstablishment
        ? {
            employees: currentEstablishment.employees,
            ...data.data,
          }
        : null;

      storeCurrentEstablishment(updatedEstablishment);
      dispatch(actionSetCurrentEstablishment(updatedEstablishment));

      if (successCallback) successCallback();
    } catch {
      showError(
        'As alterações foram salvas mas ocorreu um erro com a atualização da imagem.',
      );
    }
  } catch (error: any) {
    showError(translate('general_messages.request_error'));
  }
  dispatch(actionStopLoading());
};
