import { memo } from 'react';

import ErrorImage from 'assets/svg/error.svg';

import { translate } from 'config/i18n';
import { Modal, Button } from './styles';

interface ModalProps {
  confirmText: string;
  visible: boolean;
  loading: boolean;
  setVisible: (visible: boolean) => void;
  confirmCallback: () => void;
}

function ConfirmModal({
  confirmText,
  visible,
  loading,
  setVisible,
  confirmCallback,
}: ModalProps) {
  return (
    <Modal
      title=""
      data-testid="confirmation-modal"
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div data-testid="confirmation-message" className="modal-content">
        <img src={ErrorImage} alt={translate('general.alert_image')} />
        {confirmText}
      </div>
      <div className="align-center">
        <Button
          onClick={() => {
            setVisible(false);
          }}
          data-testid="back-button"
          htmlType="button"
          type="ghost"
          size="large"
        >
          {translate('general.back')}
        </Button>
        <Button
          onClick={confirmCallback}
          data-testid="submit-button"
          disabled={loading}
          htmlType="button"
          type="primary"
          size="large"
        >
          {loading
            ? `${translate('general.loading')}...`
            : translate('general.confirm')}
        </Button>
      </div>
    </Modal>
  );
}

export default memo(ConfirmModal);
