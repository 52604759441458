import { notification } from 'antd';
import { translate } from 'config/i18n';
import { ApiErrors } from 'constants/messages';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from 'services/api';

interface INavParams {
  code: string;
}

interface IUseNewPassword {
  loading: boolean;
  errorMessage: string;
  password: string;
  confirmPassword: string;
  setPassword: (password: string) => void;
  setConfirmPassword: (password: string) => void;
  handleSubmit: () => void;
}

export const useNewPassword = (): IUseNewPassword => {
  const { code } = useParams<INavParams>();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  async function handleSubmit() {
    setErrorMessage('');
    setLoading(true);
    if (!password) {
      setErrorMessage('Informe a nova senha');
    } else if (password !== confirmPassword) {
      setErrorMessage('As senhas não conferem');
    } else if (!code) {
      notification.error({
        message: 'Erro',
        description: 'O código informado não é válido',
      });
    } else {
      try {
        await api.post('change-password', {
          password,
          confirm: confirmPassword,
          code: code?.toUpperCase(),
        });

        notification.success({
          message: 'Sucesso',
          description: 'Sua senha foi alterada.',
        });

        history.push('/');
      } catch (err: any) {
        if (err.response?.data?.error?.code === ApiErrors.InvalidInput) {
          setErrorMessage(
            'A senha deve conter letras minúsculas, maiúsculas e números.',
          );
        } else if (err.response?.data?.error?.code === ApiErrors.NotFound) {
          notification.error({
            message: 'Erro',
            description: 'O código informado é inválido ou está expirado.',
          });
        } else {
          notification.error({
            message: translate('general.error'),
            description: translate('general_messages.request_error'),
          });
        }
      } finally {
        setLoading(false);
      }
    }
  }

  return {
    loading,
    errorMessage,
    password,
    confirmPassword,
    setPassword,
    setConfirmPassword,
    handleSubmit,
  };
};
