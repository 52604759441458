import styled from 'styled-components';
import { Input as AntdInput, Button as btn, Modal as antdModal } from 'antd';

export const Modal = styled(antdModal)`
  .ant-input {
    border: 1px solid #67687a;
    border-right-width: 1px !important;
    box-shadow: none !important;
    color: #67687a;
    font-size: 14px;

    &::placeholder {
      color: #67687a;
    }
  }

  div.align-center {
    text-align: center;
  }

  span.ant-modal-close-x {
    color: #fe6a21;
  }

  div.modal-content {
    padding: 45px 45px 0 45px;
    padding-top: 10px;
    margin: 15px 0;
  }

  div.modal-header {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding: 15px 0 0;
    color: #fe6a21;

    p {
      font-weight: initial;
      color: #535353;
      font-size: 16px;
    }
  }
`;

export const Button = styled(btn)`
  width: 100%;
  margin: 0 auto;
  height: 48px;
`;

export const Input = styled(AntdInput)`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 18px;
  height: 55px;
`;

export const LinkButton = styled(btn)`
  margin-top: 25px;
  color: #535353;
  align-self: center;
`;
