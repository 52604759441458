import { EstablishmentState } from 'interfaces/establishment';
import { ReduxStore } from 'interfaces/reduxStore';
import { useLastLocation } from 'react-router-last-location';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { translate } from 'config/i18n';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { notification } from 'antd';
import Cookies from 'universal-cookie';

interface IAuthRoute extends RouteProps {
  component: any;
  path: string;
  requiredRoles?: Array<string>;
}

const cookies = new Cookies();

function AuthRoute({
  component: Component,
  path,
  requiredRoles = [],
  ...rest
}: IAuthRoute): JSX.Element {
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );
  const lastLocation = useLastLocation();
  const [userHasRequiredRole, setUserHasRequiredRole] = useState(true);

  useEffect(() => {
    const userRoles = currentEstablishment?.employees
      ? currentEstablishment?.employees[0]?.roles.map(x => x.name.toLowerCase())
      : [];

    setUserHasRequiredRole(
      !userRoles || requiredRoles.length === 0
        ? true
        : requiredRoles.some(x => userRoles.indexOf(x.toLowerCase()) > -1),
    );
  }, [
    currentEstablishment,
    setUserHasRequiredRole,
    lastLocation,
    requiredRoles,
  ]);

  useEffect(() => {
    if (!path.startsWith('/dashboard')) {
      cookies.remove('dashboard_initial');
      cookies.remove('dashboard_final');
      cookies.remove('dashboard_granularity');
    }
  }, [path]);

  const renderRoute = useCallback(
    props => {
      let redirectPath = '/establishments';
      if (!userHasRequiredRole) {
        if (
          lastLocation &&
          lastLocation.pathname.toLowerCase() !== '/' &&
          lastLocation.pathname.toLowerCase() !== '/dashboard'
        ) {
          redirectPath = lastLocation.pathname;
        }

        notification.error({
          message: translate('general.error'),
          description: translate('auth.you_have_no_permission'),
        });
      }

      if (!currentEstablishment && path.startsWith('')) {
        redirectPath = `/establishments?redirect=${path}`;
      }

      return userHasRequiredRole ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: redirectPath,
            state: {
              requestedPath: path,
            },
          }}
        />
      );
    },
    [lastLocation, path, userHasRequiredRole, currentEstablishment, Component],
  );

  return <Route {...rest} path={path} render={renderRoute} />;
}

export default AuthRoute;

AuthRoute.defaultProps = {
  requiredRoles: [],
};
