import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  width: 100%;
  background: #f6f6f6;
  border-radius: 5px;
  padding: 20px;

  & + & {
    margin-top: 15px;
  }

  div:nth-of-type(1) {
    flex: 0.5;
  }

  div {
    > div {
      width: 63px;
      min-height: 63px;
      margin-left: 0;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .label {
      font-weight: bold;
    }

    .label.expired {
      color: #ffb100;
    }

    .label.accepted {
      color: #229dff;
    }

    .label.canceled {
      color: #cacaca;
    }

    .label.rejected {
      color: #ff0000;
    }
  }
`;

export const Title = styled.div`
  font-size: 24px;
  color: #ff6d08;
  padding-bottom: 10px;
  margin-bottom: 25px;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
`;
