import { memo, useState, useEffect, useCallback, useMemo } from 'react';
import { AuthState } from 'interfaces/auth';
import { ReduxStore } from 'interfaces/reduxStore';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import WelcomeImage from 'assets/svg/funapp-imagem-bemvindo.svg';
import NoInviteImage from 'assets/svg/no-invite.svg';
import LogoImage from 'assets/images/logo_dark.png';
import LoadingImg from 'assets/images/loading.gif';
import { translate } from 'config/i18n';

import { Invite } from 'interfaces/invite';
import { setCurrentEstablishment } from 'store/ducks/establishment';
import api from 'services/api';
import { notification } from 'antd';
import { usePermissions } from 'hooks/usePermissions';
import { establishmentRole } from 'helpers/mapper';
import { getEstablishmentName } from 'helpers/stringHelper';
import { Container, Button, LoadingContainer } from './styles';

interface INavParams {
  code: string;
}

function AcceptInvite() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addCurrentEstablishment } = usePermissions();
  const { user } = useSelector<ReduxStore, AuthState>(state => state.auth);
  const { code } = useParams<INavParams>();
  const [invite, setInvite] = useState<Invite | null>(null);
  const [loading, setLoading] = useState(true);
  const [errorCode, setErrorCode] = useState(null);
  const [allowAccess, setAllowAccess] = useState(false);

  const redirect = useCallback(() => {
    if (!user) history.push('/');
    else history.push('/establishments');
  }, [history, user]);

  function handleAccessEstablishment() {
    if (invite) {
      addCurrentEstablishment(invite.establishment);
      dispatch(
        setCurrentEstablishment(invite.establishment, () =>
          history.push('/dashboard'),
        ),
      );
    }
  }

  useEffect(() => {
    async function acceptInvite() {
      try {
        setLoading(true);
        const { data } = await api.post('me/invites/accept', { code });
        setInvite(data.data);

        const hasRole =
          data.data.roles?.includes('admin') ||
          data.data.roles?.includes('financialManager') ||
          data.data.roles?.includes('operationalManager');
        setAllowAccess(hasRole || false);
      } catch (err: any) {
        setErrorCode(err.response?.data?.error?.status);
        setInvite(null);
      } finally {
        setLoading(false);
      }
    }

    if (!user) history.push('/');
    else if (code) acceptInvite();
    else {
      notification.error({
        message: translate('general.error'),
        description: translate('invite.no_code_provided'),
      });
      redirect();
    }
  }, [history, user, code, redirect]);

  const roleNames = useMemo(() => {
    return invite?.roles.map(x => x.name) || [];
  }, [invite]);

  return (
    <Container>
      <div>
        <img
          className="logo"
          src={LogoImage}
          alt={translate('general.fanpdv_logo')}
        />
      </div>
      <div>
        {invite ? (
          <img
            className="welcome"
            src={WelcomeImage}
            alt={translate('general.welcome')}
          />
        ) : (
          <img
            className="welcome"
            src={NoInviteImage}
            alt={translate('general.welcome')}
          />
        )}
      </div>
      {!!loading && (
        <LoadingContainer>
          <img src={LoadingImg} alt={translate('general.loading')} />
        </LoadingContainer>
      )}

      {!loading && invite ? (
        <>
          <div className="content">
            <h1>{translate('invite.accepted_invite')}</h1>
            <p>
              {translate('invite.welcome_to_establishment')}{' '}
              <span className="highlited-text">
                {getEstablishmentName(invite?.establishment)}
              </span>
              . {translate('invite.your_user_has_permissions')}
              {roleNames.map((x, index) => (
                <>
                  <span key={x} className="highlited-text">
                    {establishmentRole[x]}
                  </span>
                  {index === roleNames.length - 1
                    ? '.'
                    : index === roleNames.length - 2
                    ? ' e '
                    : ', '}
                </>
              ))}
            </p>
          </div>
          <div>
            {allowAccess && (
              <Button
                onClick={handleAccessEstablishment}
                htmlType="button"
                type="primary"
                size="middle"
              >
                {translate('general.access')}
              </Button>
            )}

            <Button
              onClick={() => history.push('/establishments')}
              htmlType="button"
              type="default"
              size="middle"
            >
              {translate('establishment.my_establishments')}
            </Button>
          </div>
        </>
      ) : (
        <>
          {errorCode === 404 && (
            <>
              <div className="content no-invite">
                <h1>{translate('invite.invalid_code')}</h1>
                <p>{translate('invite.no_avaible_code')}</p>
              </div>
              <div>
                <Button
                  onClick={() => history.push('/establishments')}
                  htmlType="button"
                  type="default"
                >
                  {translate('invite.log_in_with_another_account')}
                </Button>

                <Button
                  onClick={() => history.push('/establishments')}
                  htmlType="button"
                  type="default"
                >
                  {translate('establishment.my_establishments')}
                </Button>
              </div>
            </>
          )}
          {!errorCode && !loading && (
            <>
              <div className="content">
                <h1>{translate('general.ops')}</h1>
                <p>{translate('invite.unexpected_error')}</p>
              </div>
              <div>
                <Button
                  onClick={() => history.push('/establishments')}
                  htmlType="button"
                  type="primary"
                  size="middle"
                >
                  {translate('establishment.my_establishments')}
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default memo(AcceptInvite);
