import { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { Establishment } from 'interfaces/establishment';
import Page from 'components/Page';
import { Row, Col, notification } from 'antd';
import EmailIcon from 'assets/svg/mail.svg';
import PhoneIcon from 'assets/svg/tel.svg';
import PasswordIcon from 'assets/svg/password.svg';
import LogoutIcon from 'assets/svg/logout.svg';

import ConfirmModal from 'components/ConfirmModal';
import { storeUser } from 'services/storage';
import { gutter } from 'constants/styles';
import { User } from 'interfaces/user';
import api from 'services/api';
import { formatPhone, formatCpf } from 'helpers/stringHelper';
import { logout, actionSetUser } from 'store/ducks/auth';
import Dropzone from 'components/Dropzone';
import { removeAvatar, uploadAvatar } from 'store/ducks/user';
import { translate } from 'config/i18n';
import { AuthState } from 'interfaces/auth';
import { parseJwt } from 'helpers/jwt';
import getToken from 'services/token';
import { usePermissions } from 'hooks/usePermissions';
import EditDataModal from './EditDataModal';
import ChangePasswordModal from './ChangePasswordModal';
import ChangePasswordCodeModal from './ChangePasswordCodeModal';
import ChangeEmailModal from './ChangeEmailModal';
import ChangePhoneModal from './ChangePhoneModal';
import ConfirmCodeModal from './ConfirmCodeModal';
import EstablishmentCard from '../Establishment/Card';
import {
  Header,
  Button,
  Content,
  ChangePasswordLink,
  DisconnectLink,
  Separator,
  EstablishmentList,
} from './styles';

function Profile() {
  const user = useSelector<ReduxStore, User | null>(state => state.auth.user);
  const [establishments, setEstablishments] = useState<Array<Establishment>>(
    [],
  );
  const [logoPreview, setLogoPreview] = useState<string | null | undefined>(
    user?.avatar?.location || '',
  );
  const [loading, setLoading] = useState(false);
  const [editDataModalVisible, setEditDataModalVisible] = useState(false);
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(
    false,
  );
  const [
    changePasswordCodeModalVisible,
    setChangePasswordCodeModalVisible,
  ] = useState(false);
  const [changeEmailModalVisible, setChangeEmailModalVisible] = useState(false);
  const [changePhoneModalVisible, setChangePhoneModalVisible] = useState(false);
  const [confirmCodeModalVisible, setConfirmCodeModalVisible] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [changeEmailValue, setChangeEmailValue] = useState<string | null>(null);
  const [changePhoneValue, setChangePhoneValue] = useState<string | null>(null);
  const [changePasswordCodeValue, setChangePasswordCodeValue] = useState<
    string | null
  >(null);
  const dispatch = useDispatch();
  const { addLoggedUser } = usePermissions();
  const { clearCurrentEstablishment } = usePermissions();
  const history = useHistory();

  const { token: stateToken } = useSelector<ReduxStore, AuthState>(
    state => state.auth,
  );
  const token = getToken(stateToken);

  const tokenPayload = parseJwt(token);

  useEffect(() => {
    async function getEstablishmentsFromServer() {
      try {
        const { data } = await api.get('me/establishments');
        setEstablishments(data.data);
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    }

    getEstablishmentsFromServer();
  }, []);

  const formattedPhone = useMemo(() => formatPhone(user?.phone), [user]);

  const handleLogout = useCallback(() => {
    clearCurrentEstablishment();
    dispatch(logout());
    history.push('/');
  }, [history, dispatch, clearCurrentEstablishment]);

  const confirmRemoveAccount = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete('me');
      setConfirmModalVisible(false);

      notification.success({
        message: translate('general.success'),
        description: translate('profile.your_account_was_removed'),
      });

      handleLogout();
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    }
  }, [handleLogout]);

  const confirmRemoveAvatar = useCallback(async () => {
    setAvatarLoading(true);
    dispatch(
      removeAvatar(responseUser => {
        setLogoPreview('');
        notification.success({
          message: translate('general.success'),
          description: translate('general_messages.was_removed', {
            context: 'avatar',
            gender: 'O',
          }),
        });
        setAvatarLoading(false);
        addLoggedUser(responseUser);
      }),
    );
  }, [dispatch, addLoggedUser]);

  const handleImageDrop = useCallback(
    acceptedFiles => {
      setAvatarLoading(true);
      const imgData = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        setLogoPreview(reader?.result?.toString());
        dispatch(
          uploadAvatar(imgData, responseUser => {
            notification.success({
              message: translate('general.success'),
              description: translate('general_messages.was_updated', {
                context: 'avatar',
              }),
            });
            setAvatarLoading(false);
            addLoggedUser(responseUser);
          }),
        );
      };
      reader.readAsDataURL(imgData);
    },
    [dispatch, addLoggedUser],
  );

  return (
    <Page
      displayDrawer={false}
      title="Perfil"
      loading={loading}
      showBackButton
      SubHeader={
        <Header>
          <Button
            onClick={() => setConfirmModalVisible(true)}
            className="btn-danger"
            htmlType="button"
            type="default"
          >
            Apagar conta
          </Button>
        </Header>
      }
      tabs={[
        {
          label: translate('profile.title'),
          route: '/profile',
        },
        // {
        //   label: 'Dispositivos',
        //   route: '/devices',
        // },
        // {
        //   label: 'Segurança',
        //   route: '/security',
        // },
      ]}
    >
      <Content>
        <Row gutter={gutter} className="main-row">
          <Col xs={24} sm={24} md={8} className="custom-col">
            <div className="image-container mb-5">
              <Dropzone
                width={141}
                rounded
                alt={translate('general.avatar')}
                loading={avatarLoading}
                onDropAccepted={handleImageDrop}
                showIndicator={!!logoPreview}
                onClean={(e: any) => {
                  e.stopPropagation();
                  confirmRemoveAvatar();
                }}
                logoPreview={logoPreview}
              />
              <div className="name-container">
                <span>{user?.name}</span>
                {user?.document && <p>{formatCpf(user?.document, true)}</p>}
              </div>
              <button
                onClick={() => setEditDataModalVisible(true)}
                type="button"
                className="edit-link"
              >
                editar
              </button>
            </div>

            <span className="title">Dados cadastrais</span>

            {user?.email && (
              <div className="custom-row mt-2">
                <img src={EmailIcon} alt={translate('profile.email_icon')} />
                <p>{user?.email}</p>
                <button
                  onClick={() => setChangeEmailModalVisible(true)}
                  type="button"
                  className="edit-link"
                >
                  editar
                </button>
              </div>
            )}
            {user?.phone && (
              <div className="custom-row mt-2">
                <img src={PhoneIcon} alt={translate('profile.phone_icon')} />
                <p>{formattedPhone}</p>
                <button
                  onClick={() => setChangePhoneModalVisible(true)}
                  type="button"
                  className="edit-link"
                >
                  editar
                </button>
              </div>
            )}
            {tokenPayload?.hasPassword === true ? (
              <div className="custom-row mt-2">
                <img src={PasswordIcon} alt={translate('profile.phone_icon')} />
                <p>***********</p>
                <button
                  onClick={() => setChangePasswordModalVisible(true)}
                  type="button"
                  className="edit-link"
                >
                  editar
                </button>
              </div>
            ) : (
              <ChangePasswordLink
                onClick={() => {
                  setChangePasswordCodeModalVisible(true);
                }}
                type="link"
              >
                Adicionar Senha
              </ChangePasswordLink>
            )}
            <Separator />
          </Col>
          <Col sm={24} md={11}>
            <h3>{translate('profile.linked_establishments')}</h3>
            <EstablishmentList>
              {establishments.length ? (
                establishments.map(establishment => (
                  <EstablishmentCard
                    key={establishment.id}
                    establishment={establishment}
                  />
                ))
              ) : (
                <p>{translate('profile.no_relationship')}</p>
              )}
            </EstablishmentList>
          </Col>
          <Col sm={24} md={5}>
            <DisconnectLink type="link" onClick={handleLogout}>
              <img src={LogoutIcon} alt={translate('general.disconnect')} />
              {translate('general.disconnect')}
            </DisconnectLink>
          </Col>
        </Row>
      </Content>

      <EditDataModal
        user={user}
        visible={editDataModalVisible}
        setVisible={setEditDataModalVisible}
      />

      <ChangePasswordModal
        code={changePasswordCodeValue}
        visible={changePasswordModalVisible}
        setVisible={setChangePasswordModalVisible}
      />

      <ChangePasswordCodeModal
        email={user?.email}
        callback={code => {
          setChangePasswordCodeValue(code);
          setChangePasswordModalVisible(true);
        }}
        visible={changePasswordCodeModalVisible}
        setVisible={setChangePasswordCodeModalVisible}
      />

      <ChangeEmailModal
        callback={(email, phone) => {
          setChangeEmailValue(email);
          setChangePhoneValue(phone);
          setConfirmCodeModalVisible(true);
          dispatch(actionSetUser({ ...(user as User), email: email! }));
          storeUser({ ...(user as User), email: email! });
        }}
        visible={changeEmailModalVisible}
        setVisible={setChangeEmailModalVisible}
      />

      <ChangePhoneModal
        callback={(email, phone) => {
          setChangeEmailValue(email);
          setChangePhoneValue(phone);
          setConfirmCodeModalVisible(true);
          dispatch(actionSetUser({ ...(user as User), phone: phone! }));
          storeUser({ ...(user as User), phone: phone! });
        }}
        visible={changePhoneModalVisible}
        setVisible={setChangePhoneModalVisible}
      />

      <ConfirmCodeModal
        email={changeEmailValue}
        phone={changePhoneValue}
        visible={confirmCodeModalVisible}
        setVisible={setConfirmCodeModalVisible}
      />

      <ConfirmModal
        loading={loading}
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        confirmText={translate('profile.remove_account_confirmation')}
        confirmCallback={confirmRemoveAccount}
      />
    </Page>
  );
}

export default memo(Profile);
