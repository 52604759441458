import styled from 'styled-components';
import { Button as AntdButton, Input as AntdInput } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export const Button = styled(AntdButton)`
  text-shadow: none;
  box-shadow: none;
  height: 40px;
  width: 100%;
`;

export const Content = styled.div``;

export const Header = styled.div`
  .ant-select {
    width: 100%;
  }
`;

export const SearchIcon = styled(SearchOutlined)`
  color: #ff6d08;
  font-size: 20px;
  margin-right: 10px;
`;

export const Input = styled(AntdInput)`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 18px;
  height: 60px;
`;

export const StatusFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2px;
  flex-wrap: wrap;

  > button + button {
    margin-left: 15px;
  }

  button.status-filter-card,
  button.status-filter-card:active {
    padding: 3px 10px;
    border-radius: 7px;
    text-align: center;
    width: fit-content;
    cursor: pointer;
    flex: 1;
    background: #e2e2e2;
    border: 2px solid #e2e2e2;

    span {
      color: #858585;
    }

    > div {
      position: relative;
      padding-left: 15px;

      > div {
        position: absolute;
        width: 8px;
        height: 8px;
        background: green;
        border-radius: 4px;
        margin-right: 8px;
        left: 0;
        top: 9px;
      }
    }
  }

  button.status-filter-card.selected {
    background: white;
    border: 2px solid #ff6d08;
  }

  button.status-filter-card:first-of-type > div > div {
    background: #ff6d08;
  }
  button.status-filter-card:last-of-type > div > div {
    background: red;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: rgba(0, 0, 0, 0.1);
  margin: 20px 0;
`;

export const FilterListContainer = styled.div`
  display: flex;
  flex-direction: row;

  > button {
    text-decoration: underline;
    text-decoration-color: #ff6d08;
    color: #ff6d08;
    height: 40px;
    font-weight: 600;
    font-size: 18px;
    margin-left: auto;
  }

  span.title {
    position: relative;
    top: 4px;
    margin-right: 15px;
  }

  span {
    color: #666;
  }

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  div.filter-list-card {
    margin-right: 15px;
    margin-bottom: 10px;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    border-radius: 8px;

    background: #229dff44;

    span {
      font-weight: 600;
    }

    span:first-of-type {
      font-weight: normal;
      margin-right: 5px;
    }

    button {
      color: #229dff;
      font-size: 19px;
      margin-left: 3px;
      top: -7px;
      position: relative;
      margin-bottom: -10px;
      padding-right: 0;
      font-weight: 600;
    }
  }

  div.filter-list-card.search {
    background: #ffefe9;

    button {
      color: #ff6d08;
    }
  }

  div.filter-list-card.category {
    background: #ffb10044;

    button {
      color: #ffb100;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  /* justify-content: space-between; */
  gap: 40px;
`;
