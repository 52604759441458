import styled from 'styled-components';
import { Button as btn, Dropdown as DefaultDropdown } from 'antd';

export const Button = styled(btn)`
  max-width: 240px;
`;

export const Card = styled.li`
  display: flex;
  padding: 20px;
  width: 390px;
  margin-top: 21px;
  margin-right: 15px;
  background: white;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 9px 11px -63px rgba(151, 151, 151, 0.1);
  border-radius: 5px;
  justify-content: center;

  .title {
    font-size: 23px;
    color: #000;
    font-weight: 600;
  }

  .email-div {
    width: 100%;
    max-width: 10px;
    overflow: hidden;
    position: relative;

    .blur {
      background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
      height: 28px;
      position: absolute;
      width: 50px;
      top: 0;
      right: 0;
    }
  }

  > div:first-of-type {
    span {
      font-size: 20px;
      font-weight: 600;
    }

    margin-right: 20px;
  }

  > div:last-of-type {
  }

  table {
    color: #67687a;
    font-size: 12px;
    margin: 10px 0 20px 0;

    tr td:first-of-type {
      font-weight: 700;
      padding-right: 10px;
      text-transform: uppercase;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    button.my-relationship {
      font-size: 14px;
      height: 30px;
      padding: 0;
      color: #67687a;
    }

    button:not(.my-relationship) {
      font-size: 14px;
      background: #229dff;
      height: 30px;
      border: 0;
    }
  }
`;

export const Dropdown = styled(DefaultDropdown)`
  cursor: pointer;

  margin-left: auto;

  svg {
    align-self: flex-start;
    font-size: 30px;
  }
`;

export const Placeholder = styled.div`
  background: #ccc;
  width: 159px;
  height: 159px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #666;
    font-size: 26px;
    font-weight: bold;
  }

  > div {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
  }
`;
