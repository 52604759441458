import { BackButton } from 'components/MainHeader/styles';
import BackButtonImage from 'assets/svg/icon-back.svg';
import { useHistory } from 'react-router-dom';
import { secureGoBack } from 'helpers/routeHelper';
import { useLastLocation } from 'react-router-last-location';
import { HeaderContainer, Logo } from './styles';

function Header(): JSX.Element {
  const history = useHistory();
  const lastLocation = useLastLocation();

  return (
    <HeaderContainer>
      <BackButton
        data-testid="back-button"
        onClick={() => secureGoBack(history, lastLocation, '/')}
      >
        <img src={BackButtonImage} alt="Voltar" />
      </BackButton>
      <Logo />
    </HeaderContainer>
  );
}

export { Header };
