import 'react-phone-input-2/lib/plain.css';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import './App.less';
import { Provider } from 'react-redux';
import { AppProvider } from 'hooks';
import GlobalStyle from './styles/global';
import Routes from './routes';
import store from './store';
import './config/i18n';

const cookies = new Cookies();

function App(): JSX.Element {
  const { i18n } = useTranslation();

  if (!cookies.get('language')) {
    const defaultLanguage = i18n.language;
    cookies.set('language', defaultLanguage, {
      sameSite: 'strict',
    });
  } else {
    i18n.changeLanguage(cookies.get('language'));
  }

  return (
    <>
      <AppProvider>
        <Provider store={store}>
          <Routes />
        </Provider>
      </AppProvider>
      <GlobalStyle />
    </>
  );
}

export default App;
