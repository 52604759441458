import { useState, useCallback } from 'react';
import { Form, Row, Col, notification } from 'antd';

import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';

import api from 'services/api';
import Page from 'components/Page';
import FloatLabel from 'components/FloatLabel';

import { gutter } from 'constants/styles';
import { EstablishmentState } from 'interfaces/establishment';
import { translate } from 'config/i18n';
import { useHistory, useLocation } from 'react-router-dom';
import { usePermissions } from 'hooks/usePermissions';
import ConfirmModal from 'components/ConfirmModal';
import { FormContainer, Section, Input, Header, Button } from './styles';

interface IZoopConfig {
  sellerId: string;
  disabledAt: string;
}

interface INavState {
  config: IZoopConfig;
  integrationName: string;
}

export default function ZoopConfiguration(): JSX.Element {
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );
  const {
    state: { config, integrationName },
  } = useLocation<INavState>();

  const [form] = Form.useForm();
  const history = useHistory();
  const { refreshCurrentEstablishment } = usePermissions();

  const [loading, setLoading] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const handleForm = useCallback(
    async data => {
      try {
        if (data.sellerId.length !== 32) {
          notification.error({
            message: translate('general.error'),
            description: translate('validation_messages.exact_characters', {
              field: 'establishment.seller_id',
              length: '32',
            }),
          });
          return;
        }

        setLoading(true);

        await api.put(
          `trade/establishments/${currentEstablishment?.id}/integrations/zoop`,
          { sellerId: data.sellerId },
        );

        refreshCurrentEstablishment(currentEstablishment?.id as string);

        notification.success({
          message: translate('general.success'),
          description: translate('establishment.integration_configured'),
        });
        history.push('/integrations');
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    },
    [currentEstablishment, history, refreshCurrentEstablishment],
  );

  const disable = useCallback(async () => {
    try {
      setLoading(true);

      await api.post(
        `trade/establishments/${currentEstablishment?.id}/integrations/zoop/deactivate`,
      );

      refreshCurrentEstablishment(currentEstablishment?.id as string);

      notification.success({
        message: translate('general.success'),
        description: translate('integration.disabled'),
      });
      history.push('/integrations');
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
      setConfirmModalVisible(false);
    }
  }, [currentEstablishment, history, refreshCurrentEstablishment]);

  return (
    <Page
      displayDrawer
      title={`${translate('general.configuration')}: ${integrationName}`}
      SubHeader={
        <Header>
          {!!config && !config.disabledAt && (
            <Button
              disabled={loading}
              onClick={() => setConfirmModalVisible(true)}
              htmlType="button"
              className="disable-button"
              type="default"
            >
              {translate('integration.disable')}
            </Button>
          )}
          <Button
            onClick={() => {
              history.push('/integrations');
            }}
            htmlType="button"
            type="default"
          >
            {translate('general.cancel')}
          </Button>

          <Button
            disabled={loading}
            onClick={() => {
              form.submit();
            }}
            htmlType="submit"
            type="primary"
          >
            {loading ? translate('general.saving') : translate('general.save')}
          </Button>
        </Header>
      }
    >
      <FormContainer>
        <Form initialValues={config} form={form} onFinish={handleForm}>
          <Section>
            <Row gutter={gutter}>
              <Col sm={20}>
                <FloatLabel label={`${translate('integration.seller_id')}*`}>
                  <Form.Item
                    name="sellerId"
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'validation_messages.required_field',
                          { field: 'integration.seller_id' },
                        ),
                      },
                    ]}
                  >
                    <Input maxLength={32} />
                  </Form.Item>
                </FloatLabel>
              </Col>
            </Row>
          </Section>
        </Form>
      </FormContainer>

      <ConfirmModal
        loading={loading}
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        confirmText={translate('integration.disable_confirmation', {
          integrationName: 'Zoop',
        })}
        confirmCallback={disable}
      />
    </Page>
  );
}
