import styled from 'styled-components';

interface IFloatLabelContainerProps {
  $padding?: number;
}

export const Container = styled.div`
  position: relative;

  .label {
    font-size: 18px;
    position: absolute;
    pointer-events: none;
    left: ${({ $padding }: IFloatLabelContainerProps) =>
      $padding ? `${$padding}px` : '23px'};
    top: 18px;
    transition: 0.2s ease all;
    color: #cfcfcf;
  }

  .label-float {
    top: 10px;
    font-size: 12px;
    color: #a0a0a0;
  }
`;
