import styled from 'styled-components';
import { Button as btn, Modal as antdModal } from 'antd';

export const Modal = styled(antdModal)`
  div.align-center {
    text-align: center;
  }

  width: 600px !important;

  > div {
    padding: 15px;
  }

  span.ant-modal-close-x {
    color: #101116;
    width: 28px;
    height: 28px;
    background: #f1f1f3;
    line-height: 28px;
    border-radius: 50%;
    margin: 30px 30px 0 0;
  }

  div.modal-content {
    /* padding: 15px; */
    padding-top: 10px;
    margin: 15px 0;
    height: 230px;
    overflow: auto;

    .category {
      font-size: 19.2px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 15px;

      label {
        margin-right: 10px;
        top: -2px;
        position: relative;
      }
    }
  }

  div.modal-header {
    margin-top: 40px;
    font-size: 23px;
    font-weight: 600;
    color: #101116;

    .hightlight {
      color: #fe6321;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: #65666b;
      line-height: 20px;

      &:first-of-type {
        margin-top: 10px;
      }
    }
  }

  .select-roles {
    width: 100%;
  }

  .ant-select-selection-item {
    opacity: 0.4;
  }

  .ant-select-selector {
    background: #f6f6f6;
  }

  .ant-select-arrow {
    color: black;
  }

  .unit-cost-container {
    position: relative;
  }
`;

export const Button = styled(btn)`
  width: 100%;
  height: 55px;
  font-size: 19px;
  margin-top: 8px;
`;
