import i18n, { TOptions, StringMap } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Importa as traduções da pasta locales (nome e local da pasta é opcional)
import translations from './locales';

// Configuração i18n
const i18nConfig = {
  resources: translations, // resources são as traduções
  fallbackLng: 'pt-BR', // fallbackLng é o idioma padrão, pra caso ocorra qualquer tipo de erro
  defaultNS: 'translations', // defaultNS é o namespace padrão, o nome do objeto de tradução
};

i18n
  .use(LanguageDetector) // Usa o detector de idioma do seu browser
  .use(initReactI18next) // Usa o pacote do i18n específico para React
  .init(i18nConfig); // Usa as configurações definidas

function translate(
  key: string | string[],
  options?: TOptions<StringMap> | undefined,
): string {
  let customKey = key;

  if (i18n.exists(`${customKey}_plural`) && !options?.count) {
    customKey = `${customKey}_fallback`;
  } else if (options?.count === 0) {
    customKey = `${key}_none`;
  }

  if (
    typeof customKey === 'string' &&
    customKey.includes('.error.') &&
    !i18n.exists(customKey)
  ) {
    customKey = customKey.replace(/(?:\.error\.).*/, 'default');
  }

  return i18n.t(i18n.exists(customKey) ? customKey : key, options);
}

export default i18n;

export { translate };
