import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import NotFound from './NotFound';
import LoginForm from './LoginForm';
import SignUp from './SignUp';
import ConfirmSignUp from './ConfirmSignUp';
import CompleteSignUp from './CompleteSignUp';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { NewPassword } from './ForgotPassword/NewPassword';

function Login(): JSX.Element {
  const location = useLocation();

  const redirectUrl = location.pathname.startsWith('/invites')
    ? `?redirect=${window.location.href}`
    : '';

  if (redirectUrl) {
    return <Redirect to={`${redirectUrl}`} />;
  }

  return (
    <Switch>
      <Route path="/signup/confirm">
        <ConfirmSignUp />
      </Route>
      <Route path="/signup/complete">
        <CompleteSignUp />
      </Route>
      <Route path="/signup">
        <SignUp />
      </Route>

      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/recovery/:code/">
        <NewPassword />
      </Route>

      {/* <Route path="/recovery/confirm">
            <ConfirmRecoverPassword />
          </Route>
          <Route path="/recovery/complete">
            <CompleteRecoverPassword />
          </Route>
          <Route path="/recovery">
            <RecoverPassword />
          </Route> */}
      <Route exact path="/">
        <LoginForm />
      </Route>

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default Login;
