import { useMemo } from 'react';
import { useQuery } from 'hooks/useQuery';
import { translate } from 'config/i18n';
import { FormContainer, HighlightText } from '../styles';
import { Title } from './styles';
import CompleteSignUpForm from './CompleteSignupForm';
import NoCodeInfo from './NoCodeInfo';
import { LoginLayout } from '../Layout';

function CompleteSignUp(): JSX.Element {
  const query = useQuery();

  const code = useMemo(() => query.get('code') || '', [query]);

  return (
    <LoginLayout>
      <FormContainer>
        <Title>
          <HighlightText strong>
            {translate('auth.complete_registration')}
          </HighlightText>
          {'\n'}
          {translate('auth.and_start_to_operate_system')}
        </Title>
        {code ? <CompleteSignUpForm code={code} /> : <NoCodeInfo />}
      </FormContainer>
    </LoginLayout>
  );
}

export default CompleteSignUp;
