import { memo, useState, useEffect, useCallback } from 'react';
import {
  PlusOutlined,
  EllipsisOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import Page from 'components/Page';
import DataTable from 'components/DataTable';

import api from 'services/fanappApi';

import { EstablishmentState } from 'interfaces/establishment';
import { notification, Menu } from 'antd';

import { defaultPageSize } from 'constants/styles';
import { Campaign, CampaignType } from 'interfaces/campaign';
import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { translate } from 'config/i18n';
import CalendarIcon from 'assets/svg/calendar.svg';

import { format, parseISO } from 'date-fns';

import {
  Header,
  Button,
  Content,
  Dropdown,
  DropdownStatus,
  MenuItem,
  DateColumn,
} from './styles';
import ExtendPeriodModal from './ExtendPeriodModal';

function Campaigns() {
  const history = useHistory();
  const [campaigns, setCampaigns] = useState<Array<any>>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
  const [extendPeriodModalVisible, setExtendPeriodModalVisible] = useState(
    false,
  );
  const [rawCampaigns, setRawCampaigns] = useState<Array<Campaign>>([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );
  const [categoryTypeList, setCategoryTypeList] = useState<Array<CampaignType>>(
    [],
  );

  const columns: any = [
    {
      title: translate('campaign.title_field'),
      dataIndex: 'title',
    },
    {
      title: translate('campaign.start_end'),
      dataIndex: 'startEnd',
    },
    {
      title: translate('campaign.situation'),
      dataIndex: 'status',
      sorter: false,
    },
  ];

  const handleChangeTable = useCallback(
    async (page?: number | undefined, pageSize?: number | undefined) => {
      try {
        setLoading(true);
        const response = await api.get(
          `trade/establishments/${currentEstablishment?.id}/campaigns?page=${
            page || 1
          }&pageSize=${pageSize || defaultPageSize}`,
        );

        api.get('customer/campaign-types').then(({ data }) => {
          setCategoryTypeList(data.data);
        });

        setTotal(response.headers['x-total'] || 0);

        setRawCampaigns(response.data.data);
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    },
    [currentEstablishment],
  );

  const DropdownMenu = useCallback(
    campaign => (
      <Menu>
        <MenuItem
          onClick={() => {
            history.push(`/campaigns/update/${campaign.id}`);
          }}
        >
          {translate('campaign.edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(`/campaigns/create/${campaign.id}`, {
              duplicate: true,
            });
          }}
        >
          {translate('campaign.duplicate')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSelectedCampaign(campaign);
            setExtendPeriodModalVisible(true);
          }}
        >
          {translate('campaign.extend')}
        </MenuItem>
      </Menu>
    ),
    [history],
  );

  function getStatus(campaign: Campaign) {
    if (!campaign.disabledAt && !campaign.publishedAt) return 'limited';
    if (!campaign.disabledAt && campaign.publishedAt) return 'published';
    return 'disabled';
  }

  const changeStatus = useCallback(
    async (campaign, status) => {
      try {
        setLoading(true);

        await api.post(`trade/campaigns/${campaign.id}/change-status`, {
          status,
        });
        handleChangeTable();
        notification.success({
          message: translate('general.success'),
          description: translate('campaign.was_saved'),
        });
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    },
    [handleChangeTable],
  );

  const DropdownMenuStatus = useCallback(
    campaign => (
      <Menu>
        <MenuItem onClick={() => changeStatus(campaign, 'published')}>
          {translate('campaign.published')}
        </MenuItem>
        <MenuItem onClick={() => changeStatus(campaign, 'limited')}>
          {translate('campaign.limited')}
        </MenuItem>
        <MenuItem onClick={() => changeStatus(campaign, 'disabled')}>
          {translate('campaign.disabled')}
        </MenuItem>
      </Menu>
    ),
    [changeStatus],
  );

  const updateDate = (date: string) => {
    setRawCampaigns(
      rawCampaigns.map(x =>
        x.id === selectedCampaign?.id ? { ...x, endAt: date } : x,
      ),
    );
    setSelectedCampaign(undefined);
  };

  useEffect(() => {
    async function getCampaignsFromServer() {
      handleChangeTable();
    }

    getCampaignsFromServer();
  }, [currentEstablishment, handleChangeTable]);

  useEffect(() => {
    setCampaigns(
      rawCampaigns.map((x: Campaign) => ({
        ...x,
        startEnd: (
          <>
            <DateColumn>
              <div>
                <div>
                  <span>Início</span>
                  <img src={CalendarIcon} alt="Calendar" />
                  {x.startAt
                    ? format(parseISO(x.startAt!), 'dd/MM/yyyy')
                    : 'n/a'}
                </div>
                <div className="last">
                  <span>Fim</span>
                  <img src={CalendarIcon} alt="Calendar" />
                  {x.endAt ? format(parseISO(x.endAt!), 'dd/MM/yyyy') : 'n/a'}
                </div>
              </div>
            </DateColumn>
          </>
        ),
        status: (
          <>
            <DropdownStatus
              trigger={['click']}
              overlay={() => DropdownMenuStatus(x)}
              placement="bottomLeft"
              status={getStatus(x)}
            >
              <div>
                {translate(`campaign.${getStatus(x)}`)}
                <DownOutlined className="ml-1" />
              </div>
            </DropdownStatus>
          </>
        ),
        actions: (
          <>
            <Dropdown
              trigger={['click']}
              overlay={() => DropdownMenu(x)}
              placement="bottomLeft"
            >
              <EllipsisOutlined className="actions-icon" />
            </Dropdown>
          </>
        ),
      })),
    );
  }, [rawCampaigns, DropdownMenu, DropdownMenuStatus, categoryTypeList]);

  function navigateToNewCampaign() {
    history.push('/campaigns/create');
  }

  return (
    <Page
      displayDrawer
      title={translate('campaign.plural_title')}
      tabs={[
        {
          label: translate('campaign.plural_title'),
          route: '/campaigns',
        },
      ]}
      SubHeader={
        <Header>
          <Button
            onClick={navigateToNewCampaign}
            htmlType="button"
            type="primary"
            size="middle"
          >
            <PlusOutlined /> {translate('campaign.create')}
          </Button>
        </Header>
      }
    >
      <Content>
        <DataTable
          total={total}
          loading={loading}
          onChange={handleChangeTable}
          columns={columns}
          dataSource={campaigns}
          rowKey="id"
        />
      </Content>

      <ExtendPeriodModal
        campaign={selectedCampaign as Campaign}
        visible={extendPeriodModalVisible}
        setVisible={setExtendPeriodModalVisible}
        callback={updateDate}
      />
    </Page>
  );
}

export default memo(Campaigns);
