import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { AuthState } from 'interfaces/auth';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import Login from 'pages/Login';
import Authenticated from 'pages/Authenticated';
import getToken from 'services/token';

function Routes(): JSX.Element {
  const { token: stateToken } = useSelector<ReduxStore, AuthState>(
    state => state.auth,
  );

  const token = getToken(stateToken);

  return (
    <Router>
      <LastLocationProvider>
        {token ? <Authenticated /> : <Login />}
      </LastLocationProvider>
    </Router>
  );
}

export default Routes;
