import { memo, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { User } from 'interfaces/user';
import { Menu, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { logout } from 'store/ducks/auth';
import { actionClearCurrentEstablishment } from 'store/ducks/establishment';
import { translate } from 'config/i18n';
import { Establishment } from 'interfaces/establishment';
import IntegrationImage from 'assets/svg/icon-integrations.svg';
import IntegrationImageActive from 'assets/svg/icon-integrations-active.svg';
import BackButtonImage from 'assets/svg/icon-back.svg';
import { secureGoBack } from 'helpers/routeHelper';
import { useLastLocation } from 'react-router-last-location';
import { usePermissions } from 'hooks/usePermissions';
import { Roles } from 'constants/roles';
import {
  BackButton,
  IconsContainer,
  Title,
  Container,
  InfoContainer,
  UsernameText,
  Dropdown,
  SignoutButton,
  MenuItem,
} from './styles';

export interface MainHeaderProps {
  title: string;
  showBackButton?: boolean;
}

const getShortName = (name: string) => {
  const nameArray = name.split(' ');
  if (nameArray.length === 1) return nameArray[0];
  return `${nameArray[0]} ${nameArray[nameArray.length - 1]}`;
};

function MainHeader({ title, showBackButton = false }: MainHeaderProps) {
  const establishment = useSelector<ReduxStore, Establishment | null>(
    state => state.establishment.currentEstablishment,
  );
  const history = useHistory();
  const lastLocation = useLastLocation();
  const user = useSelector<ReduxStore, User | null>(state => state.auth.user);
  const dispatch = useDispatch();
  const { clearCurrentEstablishment, has } = usePermissions();

  const { pathname } = history.location;
  const activePath = `/${pathname.split('/')[1]?.toLowerCase()}`;

  const { getPermission } = usePermissions();

  const permissions = useMemo(() => getPermission().join(', '), [
    getPermission,
  ]);

  const handleProfile = () => {
    history.push('/profile');
  };

  const handleEstablishments = () => {
    clearCurrentEstablishment();
    dispatch(actionClearCurrentEstablishment());
    history.push('/establishments');
  };

  const handleLogout = () => {
    clearCurrentEstablishment();
    dispatch(logout());
    history.push('/');
  };

  const DropdownMenu = (
    <Menu>
      <MenuItem onClick={handleProfile}>
        {translate('profile.my_profile')}
      </MenuItem>
      <MenuItem onClick={handleEstablishments}>
        {translate('establishment.plural_title')}
      </MenuItem>
      <SignoutButton onClick={handleLogout}>
        {translate('general.disconnect')}
      </SignoutButton>
    </Menu>
  );

  return (
    <Container data-testid="main-header">
      {showBackButton && (
        <BackButton
          data-testid="back-button"
          onClick={() => secureGoBack(history, lastLocation, '/establishments')}
        >
          <img src={BackButtonImage} alt={translate('general.back_icon')} />
        </BackButton>
      )}
      <Title data-testid="title">{title}</Title>
      <IconsContainer>
        {establishment && has([], [Roles.ADMIN]) && (
          <button
            onClick={() => {
              history.push('/integrations');
            }}
            className="btn-clean"
            type="button"
          >
            <img
              src={
                activePath === '/integrations'
                  ? IntegrationImageActive
                  : IntegrationImage
              }
              alt={translate('integration.plural_title')}
            />
          </button>
        )}
      </IconsContainer>
      {user && (
        <div data-testid="user-info">
          <Dropdown
            trigger={['click']}
            overlay={DropdownMenu}
            placement="bottomLeft"
          >
            <InfoContainer>
              <div>
                <UsernameText strong>{getShortName(user.name)}</UsernameText>
                <div className="roles">
                  <span>{permissions}</span>
                </div>
              </div>
              <Avatar
                src={user.avatar?.location}
                size="large"
                icon={<UserOutlined />}
              />
            </InfoContainer>
          </Dropdown>
        </div>
      )}
    </Container>
  );
}

export default memo(MainHeader);
