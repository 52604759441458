import { memo, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { update } from 'store/ducks/user';
import { translate } from 'config/i18n';
import { User, UserState } from 'interfaces/user';
import { Store } from 'antd/lib/form/interface';
import { Col, Row, Form } from 'antd';
// import { isCPF } from 'services/validation';
import { ReduxStore } from 'interfaces/reduxStore';
import { usePermissions } from 'hooks/usePermissions';
import { Modal, Button, Input, Label } from './styles';

interface ModalProps {
  user: User | null;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

function EditRoleModal({ user, visible, setVisible }: ModalProps) {
  const dispatch = useDispatch();
  const { addLoggedUser } = usePermissions();
  const { loading } = useSelector<ReduxStore, UserState>(state => state.user);
  const [form] = Form.useForm();

  const handleForm = useCallback(
    async (data: Store) => {
      dispatch(
        update({ name: data.name }, userResponse => {
          setVisible(false);
          addLoggedUser(userResponse);
        }),
      );
    },
    [dispatch, setVisible, addLoggedUser],
  );

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">
        {translate('profile.registration_data_updating')}
      </div>
      <div className="modal-content">
        <Form
          form={form}
          onFinish={handleForm}
          initialValues={{
            name: user?.name,
            // document: user?.document,
          }}
        >
          <Row>
            <Col sm={24}>
              <Label htmlFor="name">{translate('profile.name')}</Label>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: translate('validation_messages.required_field', {
                      field: 'profile.name',
                    }),
                  },
                ]}
              >
                <Input placeholder={translate('profile.name')} />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
            <Col sm={24}>
              <Form.Item
                name="document"
                rules={[
                  { required: true, message: translate('validation_messages.required_field',
                  { field: 'profile.document' }) },
                  { validator: isCPF },
                ]}
              >
                <Input placeholder={translate('profile.document')} />
              </Form.Item>
            </Col>
          </Row> */}
          <Row>
            <Col sm={24}>
              <Button
                disabled={loading}
                onClick={() => {
                  form.submit();
                }}
                htmlType="submit"
                type="primary"
                size="large"
              >
                {loading
                  ? `${translate('general.loading')}...`
                  : translate('general.confirm')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default memo(EditRoleModal);
