import styled from 'styled-components';
import { Button as btn } from 'antd';
import colors from 'styles/colors';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const Button = styled(btn)`
  max-width: 240px;
`;

export const Content = styled.div`
  table tbody tr td,
  table thead tr th {
    text-align: center !important;
  }

  .favorite-icon {
    color: #fe6a21;
    font-size: 24px;
  }

  .actions-icon {
    font-size: 24px;
  }
`;

export const SeeMoreButton = styled.button`
  color: ${colors.primary};
`;

export const DateTimeContainer = styled.div`
  width: fit-content;
  text-align: left;
  margin: 0 auto;

  div {
    display: flex;
  }
  icon {
    margin-right: 5px;
  }
`;
