import styled from 'styled-components';
import { Row, Button as DefaultButton } from 'antd';

export const FormTitle = styled.h2`
  font-weight: 300;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

export const ButtonContainer = styled(Row)`
  margin-top: 3rem;
  flex-wrap: wrap;
`;

export const Button = styled(DefaultButton)`
  min-width: 45%;
  margin-bottom: 0.5rem;
`;
