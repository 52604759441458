import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    body {
      background-color: #FAFAFA
    }

    button {
      cursor: pointer;
    }

    .cancel-button {
      color: #67687A;
    }

    .ant-table {
      background: transparent;
    }

    .ant-form-item-has-error .ant-input:focus {
      border-right-width: 1px !important;
    }

    .align-center {
      text-align: center;
    }

    .ant-btn {
      box-shadow: none;
      border-radius: 4px;
    }

    .btn-success {
      color: #fff;
      background: #00D95A;
      border-color: #00D95A;
    }

    .btn-success:hover, .btn-success:focus {
      color: #fff;
      background: #38f586;
      border-color: #38f586;
    }

    .btn-success:active{
      color: #fff;
      background: #009e42;
      border-color: #009e42;
    }

    .btn-danger {
      color: #fff;
      background: #fe1301;
      border-color: #fe1301;
    }

    .btn-danger:hover, .btn-danger:focus {
      color: #fff;
      background: #ff4e40;
      border-color: #ff4e40;
    }

    .btn-danger:active{
      color: #fff;
      background: #bf0f01;
      border-color: #bf0f01;
    }

    .btn-blue {
      color: #fff;
      background: #229cff;
      border-color: #229cff;
    }

    .btn-blue:hover, .btn-blue:focus {
      color: #fff;
      background: #5db6ff;
      border-color: #5db6ff;
    }

    .btn-blue:active{
      color: #fff;
      background: #0162b1;
      border-color: #0162b1;
    }

    button.btn-clean, button.btn-clean:focus {
        background: transparent;
        border: 0;
        outline: 0;
    }

    .ant-table-thead > tr > th {
      color: #fe6a21;
      background: transparent;
    }

    .ant-table-tbody > tr:nth-of-type(even) > td {
      background: #F8F8F8;
    }

    .ant-table-tbody > tr td:last-of-type {
      min-width: 85px;
    }

    .ant-pagination-options-size-changer {
      > div.ant-select-selector {
        height: 32px !important;
      }

      .ant-select-selection-item {
        line-height: 27px !important;
      }
    }
`;
