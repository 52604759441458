import styled from 'styled-components';
import { Typography, Tabs as DefaultTabs } from 'antd';

export const ForgetPasswordLink = styled.a`
  margin-bottom: 1.6rem;
  color: #8a8a8a;
  font-weight: 600;
`;

export const NoAccountText = styled(Typography.Text)`
  margin-top: 2rem;
  font-weight: 300;
`;

export const Title = styled.h1`
  font-size: 2.2rem;
  margin-bottom: 0;
  font-weight: 300;
  line-height: 3rem;
`;

export const Tabs = styled(DefaultTabs)`
  display: block;

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 100%;
    color: #c5c5c5;
    display: inline-block;
    text-align: center;
  }

  .ant-tabs-ink-bar-animated.ant-tabs-ink-bar {
    height: 3px;
  }

  .ant-tabs-tab-active > .ant-tabs-tab-btn {
    color: @heading-color;
  }

  .ant-tabs-nav::before {
    display: none;
  }
`;

export const TabBarItem = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
`;

export const FormTitle = styled.h2`
  font-weight: 300;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

export const SocialLoginContainer = styled.div`
  text-align: center;
  margin-top: 50px;

  > div {
    margin-bottom: -12px;
  }

  p {
    color: #858585;
    font-weight: 300;
  }

  a + a {
    margin-left: 15px;
  }
`;
