import styled from 'styled-components';
import { Button as btn, Modal as antdModal } from 'antd';

export const Modal = styled(antdModal)`
  max-width: 400px;
  max-height: 400px;

  div.align-center {
    text-align: center;
  }

  span.ant-modal-close-x {
    color: #fe6a21;
  }

  div.modal-content {
    color: #535353;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    border-radius: 15px;
    padding: 15px;
    padding-top: 10px;
    margin: 15px 0;
    text-align: center;
  }
`;

export const Button = styled(btn)`
  max-width: 240px;
  width: 130px;
  height: 47px;
  margin: 0 auto;
  margin-bottom: 30px;

  & + & {
    margin-left: 15px;
  }

  &:nth-of-type(1) {
    background: #979797;
    border-color: #979797;
    color: white;
  }

  &:nth-of-type(2) {
    background: red;
    border-color: red;
  }
`;
