import {
  useCallback,
  memo,
  useEffect,
  createRef,
  SyntheticEvent,
  KeyboardEvent,
  ClipboardEvent,
} from 'react';
import { CodeContainer, CharacterInput } from './styles';

const focusNextInput = (event: SyntheticEvent<HTMLInputElement>) => {
  const { form } = event.currentTarget;

  const index = Array.prototype.indexOf.call(form, event.target);
  const nextElement = (form as HTMLFormElement).elements[index + 1];
  if (nextElement) (nextElement as HTMLInputElement).focus();
};

const focusPreviousInput = (event: SyntheticEvent<HTMLInputElement>) => {
  const { form } = event.currentTarget;

  const index = Array.prototype.indexOf.call(form, event.target);
  const nextElement = (form as HTMLFormElement).elements[index - 1];
  if (nextElement) (nextElement as HTMLInputElement).focus();
};

const modifyCode = (
  character: string,
  index: number,
  initialCode: string[],
) => {
  const newCode = [...initialCode];
  newCode[index] = character;
  return newCode;
};

export interface CodeInputProps {
  length: number;
  value?: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
}

function CodeInput({ disabled, length, value = [], onChange }: CodeInputProps) {
  const initRef = createRef<HTMLInputElement>();

  const handleChange = useCallback(
    (codeIndex: number) => (event: SyntheticEvent<HTMLInputElement>) => {
      // eslint-disable-next-line no-shadow
      const { value: character } = event.currentTarget;

      onChange(modifyCode(character, codeIndex, value));
      if (character) focusNextInput(event);
    },
    [value, onChange],
  );

  const handleKeyDown = useCallback(
    (codeIndex: number) => (event: KeyboardEvent<HTMLInputElement>) => {
      const { key } = event;

      if (key === 'Tab') return;

      onChange(modifyCode('', codeIndex, value));
    },
    [value, onChange],
  );

  const handleKeyUp = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;

    if (key !== 'Backspace') return;

    focusPreviousInput(event);
  }, []);

  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      event.stopPropagation();
      event.preventDefault();

      const { clipboardData } = event;
      const pastedData = clipboardData.getData('Text');

      if (pastedData.length === length) onChange(pastedData.split(''));
    },
    [length, onChange],
  );

  useEffect(() => {
    if (!disabled && initRef) initRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <CodeContainer>
      {[...Array(length)].map((_e, index) => (
        <CharacterInput
          name={`code[${index}]`}
          data-testid={`code[${index}]`}
          autoComplete="off"
          ref={index === 0 ? initRef : null}
          // eslint-disable-next-line react/no-array-index-key
          key={`code-${index}`}
          value={value[index] || ''}
          onPaste={handlePaste}
          onKeyUp={handleKeyUp}
          disabled={disabled}
          onKeyDown={handleKeyDown(index)}
          onChange={handleChange(index)}
        />
      ))}
    </CodeContainer>
  );
}

export default memo(CodeInput);
