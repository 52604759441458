import { memo, useState, useCallback } from 'react';
import { Col, Input, notification, Row } from 'antd';

import api from 'services/api';
import { Product } from 'interfaces/product';
import { translate } from 'config/i18n';
import { gutter } from 'constants/styles';
import { Modal, Button, Label, PricePrefix, PriceField } from './styles';

interface ModalProps {
  product: Product;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  callback?: (quantity: number) => void;
}

interface IPriceValues {
  value: string;
}

function AddStockModal({ product, visible, setVisible, callback }: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState('');
  const [unitCost, setUnitCost] = useState('');

  const resetFields = useCallback(() => {
    setQuantity('');
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!quantity) {
      notification.error({
        message: translate('general.error'),
        description: translate('validation_messages.required_field', {
          field: 'product.quantity',
        }),
      });
      return;
    }

    try {
      setLoading(true);

      let formattedUnitCost =
        typeof unitCost !== 'number'
          ? Number(unitCost.replace(/\./g, '').replace(',', '.'))
          : unitCost;

      formattedUnitCost = +(formattedUnitCost * 100).toFixed();

      await api.post(`trade/products/${product.id}/stock/add`, {
        quantity,
        unitCost: formattedUnitCost,
      });

      if (callback) callback(Number(product.stock) + Number(quantity));

      notification.success({
        message: translate('general.success'),
        description: translate('product.stock_success_message'),
      });
      setVisible(false);
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
    }
  }, [setVisible, product, callback, quantity, unitCost]);

  const setOnlyNumber = (
    e: React.FormEvent<HTMLInputElement>,
    callbackFN: (value: string) => void,
  ) => {
    const re = /^[0-9\b]+$/;

    if (e.currentTarget.value === '' || re.test(e.currentTarget.value)) {
      callbackFN(e.currentTarget.value);
    }
  };

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      afterClose={resetFields}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">{translate('product.add_stock')}</div>
      <div className="modal-content">
        <Row gutter={gutter}>
          {product?.image && (
            <Col sm={12}>
              <div className="image-container">
                <img
                  src={product?.image?.location}
                  alt={translate('product.product_image')}
                />
                <div>
                  <span>{product?.name}</span>
                  <span>{product?.productCategory?.name}</span>
                </div>
              </div>
            </Col>
          )}
          <Col sm={product?.image ? 12 : 24}>
            <Label>{translate('product.quantity')}</Label>
            <Input
              placeholder={translate('general.eg', { eg: '100' })}
              value={quantity}
              onChange={e => {
                setOnlyNumber(e, setQuantity);
              }}
            />

            <Label>{translate('product.unit_cost')}</Label>
            <div className="unit-cost-container">
              <PricePrefix>{translate('general.price_prefix')}</PricePrefix>
              <PriceField
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={false}
                className="ant-input"
                onValueChange={({ value }: IPriceValues) => {
                  setUnitCost(value);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="align-center">
        <Button
          onClick={handleSubmit}
          disabled={loading}
          htmlType="button"
          type="primary"
          size="large"
        >
          {loading
            ? `${translate('general.loading')}...`
            : translate('general.done')}
        </Button>
      </div>
    </Modal>
  );
}

export default memo(AddStockModal);
