import styled from 'styled-components';
import { Modal as antdModal } from 'antd';

export const Modal = styled(antdModal)`
  width: 528px !important;

  span.ant-modal-close-x {
    display: none;
  }

  div.icon-container {
    button {
      width: 60px;
      height: 60px;

      img {
        max-height: 40px;
        max-width: 40px;
        width: 80%;
      }
    }
  }
`;

export const SearchContainer = styled.div`
  background: #f1f1f1;
  padding: 10px 15px;
  border-radius: 6px;
  margin-bottom: 10px;

  input {
    background: transparent;
    border: 0;
    outline: 0;
    font-size: 17px;
    position: relative;
    top: 2px;
    left: 5px;
  }
`;
