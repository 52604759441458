import { SubmitButton } from '../styles';
import { Header } from './Header';
import { Container, Title, InputPassword, ErrorMessage } from './styles';
import { useNewPassword } from './useNewPassword';

function NewPassword(): JSX.Element {
  const {
    loading,
    errorMessage,
    password,
    confirmPassword,
    setPassword,
    setConfirmPassword,
    handleSubmit,
  } = useNewPassword();

  return (
    <>
      <Header />
      <Container>
        <Title>Digite sua nova senha</Title>

        <InputPassword
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="Nova senha"
        />

        <InputPassword
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          placeholder="Confirme a nova senha"
        />
        <ErrorMessage>{errorMessage}</ErrorMessage>

        <SubmitButton
          disabled={loading}
          htmlType="submit"
          type="primary"
          size="large"
          style={{ width: 160 }}
          onClick={() => handleSubmit()}
        >
          {loading ? 'Enviando' : 'Confirmar'}
        </SubmitButton>
      </Container>
    </>
  );
}

export { NewPassword };
