import styled from 'styled-components';
import { Button as btn, Modal as antdModal } from 'antd';

export const Modal = styled(antdModal)`
  div.align-center {
    text-align: center;
  }

  span.ant-modal-close-x {
    color: #fe6a21;
  }

  div.modal-content {
    background: #f6f6f6;
    border-radius: 15px;
    padding: 15px;
    padding-top: 10px;
    margin: 15px 0;
  }

  div.modal-header {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 15px;
    color: #5d5e5d;
  }

  .select-roles {
    width: 100%;
  }

  .ant-select-selection-item {
    opacity: 0.4;
  }

  .ant-select-selector {
    background: #f6f6f6;
  }

  .ant-select-arrow {
    color: #fe6a21;
  }
`;

export const Button = styled(btn)`
  max-width: 240px;
  margin: 0 auto;
`;
