import CampaingTypeCreditIcon from 'assets/svg/campaign-type-credit.svg';
import CampaingTypeProductIcon from 'assets/svg/campaign-type-product.svg';
import CampaingTypeTicketIcon from 'assets/svg/campaign-type-ticket.svg';
import CampaingTypeCreditSelectedIcon from 'assets/svg/campaign-type-credit-selected.svg';
import CampaingTypeProductSelectedIcon from 'assets/svg/campaign-type-product-selected.svg';

import { translate } from 'config/i18n';
import { Container } from './styles';

interface CampaignTypeProps {
  selectedType: string | null;
  setSelectedType: null | ((type: string) => void);
  cardName: string;
}

function CampaignTypeCard({
  selectedType,
  setSelectedType,
  cardName,
}: CampaignTypeProps): JSX.Element {
  const isSelected = selectedType === cardName;

  function getImage() {
    switch (cardName) {
      case 'credit':
        return isSelected
          ? CampaingTypeCreditSelectedIcon
          : CampaingTypeCreditIcon;
        break;
      case 'product':
        return isSelected
          ? CampaingTypeProductSelectedIcon
          : CampaingTypeProductIcon;
        break;
      case 'ticket':
        return isSelected ? CampaingTypeTicketIcon : CampaingTypeTicketIcon;
        break;
      default:
        return '';
        break;
    }
  }

  return (
    <Container
      selected={isSelected}
      onClick={() => {
        if (setSelectedType) {
          setSelectedType(cardName);
        }
      }}
    >
      <img src={getImage()} alt={translate(`campaign.${cardName}`)} />
      <span>{translate(`campaign.${cardName}`)}</span>
    </Container>
  );
}

export default CampaignTypeCard;
