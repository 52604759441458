import styled from 'styled-components';
import { Input as AntdInput, Button as ShadowedButton } from 'antd';
import NumberFormat from 'react-number-format';

export const FormContainer = styled.div``;

export const Section = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px 0 20px 0;

  textarea {
    resize: none;
  }

  .ant-form-item-control-input-content > .ant-input {
    padding-top: 22.4px;
    padding-bottom: 8.4px;
  }

  .label-container {
    align-items: center;
    display: flex;

    label {
      font-size: 18px;
    }
  }

  .clean-button {
    padding-top: initial !important;
    padding-bottom: initial !important;
    height: 60px !important;
  }
`;

export const SectionTitle = styled.h1`
  font-family: 'Titillium Web', sans-serif;
  color: #ff6d08;
  font-weight: 600;
  display: flex;
  align-items: center;

  div {
    background: rgba(0, 0, 0, 0.1);
    height: 2px;
    width: 100%;
    margin-left: 15px;
  }
`;

export const Input = styled(AntdInput)`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 18px;
  height: 60px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;

  button + button {
    margin-left: 20px;
  }
`;

export const Button = styled(ShadowedButton)`
  text-shadow: none;
  box-shadow: none;
`;

export const PricePrefix = styled.span`
  position: absolute;
  z-index: 1;
  top: 15px;
  font-size: 20px;
  color: #fe6a21;
  left: 15px;

  &.ml {
    left: 30px;
  }
`;

export const PriceField = styled(NumberFormat)`
  text-indent: 25px;
`;
