import bar from 'assets/images/bar.png';
import restaurant from 'assets/images/restaurant.png';
import club from 'assets/images/club.png';
import nightClub from 'assets/images/nightClub.png';
import party from 'assets/images/party.png';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { Establishment } from 'interfaces/establishment';
import AddCategoriesModal from './AddCategoriesModal';
import { Container } from './styles';

export type CategoryBaseType =
  | 'bar'
  | 'restaurant'
  | 'club'
  | 'nightClub'
  | 'party'
  | undefined;

function CategoryBase({ callback }: { callback?: () => void }): JSX.Element {
  const [selected, setSelected] = useState<CategoryBaseType>();
  const [addCategoriesModalVisible, setAddCategoriesModalVisible] = useState(
    false,
  );
  const establishment = useSelector<ReduxStore, Establishment | null>(
    state => state.establishment.currentEstablishment,
  );

  const selectCategory = (category: CategoryBaseType) => {
    setSelected(category);
    setAddCategoriesModalVisible(true);
  };

  return (
    <Container>
      <div className="card" style={{ backgroundImage: `url(${bar})` }}>
        <button
          type="button"
          className="btn-clean"
          onClick={() => selectCategory('bar')}
        >
          <span>Bar</span>
        </button>
      </div>
      <div className="card" style={{ backgroundImage: `url(${restaurant})` }}>
        <button
          type="button"
          className="btn-clean"
          onClick={() => selectCategory('restaurant')}
        >
          <span>Restaurante</span>
        </button>
      </div>
      <div className="card" style={{ backgroundImage: `url(${club})` }}>
        <button
          type="button"
          className="btn-clean"
          onClick={() => selectCategory('club')}
        >
          <span>Parque aquático</span>
        </button>
      </div>
      <div className="card" style={{ backgroundImage: `url(${nightClub})` }}>
        <button
          type="button"
          className="btn-clean"
          onClick={() => selectCategory('nightClub')}
        >
          <span>Casa noturna</span>
        </button>
      </div>
      <div className="card" style={{ backgroundImage: `url(${party})` }}>
        <button
          type="button"
          className="btn-clean"
          onClick={() => selectCategory('party')}
        >
          <span>Festival</span>
        </button>
      </div>

      {!!selected && (
        <AddCategoriesModal
          visible={addCategoriesModalVisible}
          setVisible={setAddCategoriesModalVisible}
          categoryBase={selected}
          establishmentId={establishment!.id}
          callBack={callback}
        />
      )}
    </Container>
  );
}

export default CategoryBase;
