import { memo, useState } from 'react';

import OpenOperationImg from 'assets/svg/abrir_operacao.svg';

import { translate } from 'config/i18n';

import api from 'services/api';
import { Input, notification } from 'antd';
import { Establishment } from 'interfaces/establishment';
import { Modal, Button, OperationImage } from './styles';

interface ModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  callback: () => void;
  currentEstablishment: Establishment;
}

function ManageOperationModal({
  visible,
  setVisible,
  callback,
  currentEstablishment,
}: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleOpenOperation = async () => {
    try {
      setErrorMessage('');
      let hasError = false;
      setLoading(true);

      if (!title) {
        setErrorMessage(
          translate('validation_messages.required_field', {
            field: 'operation.form_title',
          }),
        );
        hasError = true;
      } else if (title.trim().length < 3 || title.trim().length > 50) {
        setErrorMessage(
          translate('validation_messages.between_characters', {
            field: 'operation.form_title',
            minLength: 3,
            maxLength: 3,
          }),
        );
        hasError = true;
      }

      if (!hasError) {
        await api.post(
          `trade/establishments/${currentEstablishment.id}/operations`,
          {
            title,
          },
        );

        notification.success({
          message: translate('general.success'),
          description: translate('operation.was_opened'),
        });

        setVisible(false);
        callback?.();
      }
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
        setErrorMessage('');
        setTitle('');
      }}
    >
      <div className="modal-header">
        <OperationImage src={OpenOperationImg} />
      </div>
      <div className="modal-content">
        Informe um título para seguir com a <strong>abertura</strong> da
        operação
        <Input
          maxLength={50}
          placeholder={translate('operation.form_title')}
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
        <span className="errorMessage">{errorMessage}</span>
      </div>
      <div className="align-center">
        <Button
          onClick={handleOpenOperation}
          disabled={loading}
          htmlType="button"
          type="primary"
          size="large"
        >
          {loading
            ? `${translate('general.loading')}...`
            : translate('general.confirm')}
        </Button>
      </div>
    </Modal>
  );
}

export default memo(ManageOperationModal);
