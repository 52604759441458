import {
  // useState,
  // useCallback
  useEffect,
  useState,
} from 'react';
// import { Form, Select, Row, Col } from 'antd';

import { translate } from 'config/i18n';
import Page from 'components/Page';
// import { gutter } from 'constants/styles';

import api from 'services/api';
import { ReduxStore } from 'interfaces/reduxStore';
import { Establishment } from 'interfaces/establishment';
import { useSelector } from 'react-redux';
import { Integration } from 'interfaces/integration';

import {
  // Button,
  // SearchIcon,
  // Input,
  // StatusFilterContainer,
  // Separator,
  // FilterListContainer,
  Content,
  Header,
  Container,
} from './styles';
import Card from './Card';

function Integrations(): JSX.Element {
  const establishment = useSelector<ReduxStore, Establishment | null>(
    state => state.establishment.currentEstablishment,
  );

  const [integrations, setIntegrations] = useState<Integration[]>([]);

  // const [statusFilter, setStatusFilter] = useState('all');
  // const [nameFilter, setNameFilter] = useState('Fanapp');
  // const [conditionsFilter, setConditionsFilter] = useState([
  //   'Condição 1',
  //   'Condição 2',
  //   'Condição 3',
  // ]);
  // const [categoriesFilter, setCategoriesFilter] = useState([
  //   'Voucher',
  //   'Payment',
  // ]);

  // const [condicoesLst] = useState([
  //   { id: 1, nome: 'Condição 1' },
  //   { id: 2, nome: 'Condição 2' },
  // ]);

  // const categories = ['Voucher', 'Payment'];

  useEffect(() => {
    async function getIntegrations() {
      try {
        const response = await api.get(
          `trade/establishments/${establishment?.id}/integrations`,
        );

        setIntegrations(response.data.data);
      } catch (err) {
        //
      }
    }

    getIntegrations();
  }, [establishment]);

  // const handleSelectCategory = useCallback(
  //   (value: string) => {
  //     if (!categoriesFilter.find(x => x === value))
  //       setCategoriesFilter(x => [...x, value]);
  //   },
  //   [categoriesFilter],
  // );

  return (
    <Page title={translate('integration.plural_title')}>
      <Content>
        <Header>
          {/* <Row gutter={gutter}>
            <Col sm={24}>
              <Form.Item>
                <Input
                  prefix={<SearchIcon />}
                  placeholder={`${translate('general.search')}...`}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter} className="valign-middle">
            <Col sm={10}>
              <StatusFilterContainer>
                <button
                  type="button"
                  onClick={() => setStatusFilter('all')}
                  className={`btn-clean status-filter-card ${
                    statusFilter === 'all' && 'selected'
                  }`}
                >
                  <div>
                    <div />
                    <span>Todos</span>
                  </div>
                </button>
                <button
                  type="button"
                  onClick={() => setStatusFilter('enabled')}
                  className={`btn-clean status-filter-card ${
                    statusFilter === 'enabled' && 'selected'
                  }`}
                >
                  <div>
                    <div />
                    <span>Ativado</span>
                  </div>
                </button>
                <button
                  type="button"
                  onClick={() => setStatusFilter('disabled')}
                  className={`btn-clean status-filter-card ${
                    statusFilter === 'disabled' && 'selected'
                  }`}
                >
                  <div>
                    <div />
                    <span>Desativado</span>
                  </div>
                </button>
              </StatusFilterContainer>
            </Col>
            <Col sm={5}>
              <Select
                placeholder="Condições"
                size="small"
                onChange={(value: string) => {
                  setConditionsFilter(x => [...x, value]);
                }}
              >
                {condicoesLst.map(item => (
                  <Select.Option key={item.id} value={item.nome}>
                    {item.nome}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col sm={5}>
              <Select
                placeholder="Categoria"
                size="small"
                onChange={handleSelectCategory}
              >
                {categories.map(item => (
                  <Select.Option key={item} selected={false} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col sm={4}>
              <Button type="primary">Buscar</Button>
            </Col>
          </Row>
          <Separator />
          <FilterListContainer>
            <div>
              <span className="title">Buscas por</span>
              {nameFilter && (
                <div className="filter-list-card search">
                  <span>Busca: </span>
                  <span>{nameFilter}</span>
                  <button
                    onClick={() => {
                      setNameFilter('');
                    }}
                    type="button"
                    className="btn-clean"
                  >
                    x
                  </button>
                </div>
              )}
              {conditionsFilter.map(condition => (
                <div key={condition} className="filter-list-card condition">
                  <span>Condição: </span>
                  <span>{condition}</span>
                  <button
                    onClick={() => {
                      setConditionsFilter(
                        conditionsFilter.filter(x => x !== condition),
                      );
                    }}
                    type="button"
                    className="btn-clean"
                  >
                    x
                  </button>
                </div>
              ))}
              {categoriesFilter.map(category => (
                <div key={category} className="filter-list-card category">
                  <span>Categoria: </span>
                  <span>{category}</span>
                  <button
                    onClick={() => {
                      setCategoriesFilter(
                        categoriesFilter.filter(x => x !== category),
                      );
                    }}
                    type="button"
                    className="btn-clean"
                  >
                    x
                  </button>
                </div>
              ))}
            </div>
            <button type="button" className="btn-clean">
              Limpar
            </button>
          </FilterListContainer> */}
        </Header>
        <Container>
          {integrations.map((x, index) => (
            <Card integration={x} key={`${index}_${x.name}`} />
          ))}
        </Container>
      </Content>
    </Page>
  );
}

export default Integrations;
