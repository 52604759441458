import { memo, useCallback, useState, useEffect } from 'react';

import { translate } from 'config/i18n';
import { Col, Row, Form, notification } from 'antd';
import api from 'services/api';
import CodeInput from 'components/CodeInput';
import { Modal, LinkButton } from './styles';

interface ModalProps {
  phone: string | null;
  email: string | null;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const CODE_LENGTH = 6;

function ConfirmCodeModal({ phone, email, visible, setVisible }: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState<string[]>([]);
  const [username, setUsername] = useState<string | null>(null);

  useEffect(() => {
    setUsername(phone || email);
  }, [phone, email]);

  const handleForm = useCallback(
    async stringCode => {
      try {
        setLoading(true);
        const uri = phone ? 'me/phone/confirm' : 'me/email/confirm';
        await api.post(uri, { code: stringCode });
        setCode([]);
        setVisible(false);
        notification.success({
          message: translate('general.success'),
          description: translate(
            phone
              ? 'profile.phone_updated_succeed'
              : 'profile.email_updated_succeed',
          ),
        });
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('profile.code_confirm_failed'),
        });
      } finally {
        setLoading(false);
      }
    },
    [setVisible, phone],
  );

  useEffect(() => {
    const stringCode = code.join('');
    if (stringCode.length === CODE_LENGTH) {
      handleForm(stringCode);
    }
  }, [code, handleForm]);

  const handleCodeChange = useCallback((value: string[]) => {
    setCode(value);
  }, []);

  const handleResend = useCallback(async () => {
    try {
      setLoading(true);
      if (phone) await api.put('me/phone', { phone });
      else await api.put('me/email', { email });
      notification.success({
        message: translate('general.success'),
        description: translate('profile.code_resend_succeed'),
      });
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('profile.code_resend_failed'),
      });
    } finally {
      setLoading(false);
    }
  }, [phone, email]);

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">
        {translate('profile.field_confirmation', {
          field: phone ? 'profile.phone' : 'profile.email',
        })}
        <p>
          {translate('profile.type_code_sent_to_field', { field: username })}
        </p>
      </div>
      <div className="modal-content">
        <Form onFinish={handleForm}>
          <Row>
            <Col sm={24}>
              <CodeInput
                disabled={loading}
                onChange={handleCodeChange}
                value={code}
                length={CODE_LENGTH}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} className="align-center">
              <LinkButton
                disabled={loading}
                onClick={handleResend}
                htmlType="submit"
                type="link"
                size="large"
              >
                {translate('auth.resend_code')}
              </LinkButton>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default memo(ConfirmCodeModal);
