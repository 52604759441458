import { Container, BackgroundCol, FormCol, Background, Logo } from '../styles';

interface LoginLayoutProps {
  children: React.ReactNode;
}

function LoginLayout({ children }: LoginLayoutProps): JSX.Element {
  return (
    <Container>
      <BackgroundCol md={10} xs={24}>
        <Background />
        <Logo />
      </BackgroundCol>
      <FormCol md={14} xs={24}>
        {children}
      </FormCol>
    </Container>
  );
}

export { LoginLayout };
