import { memo, useEffect, useState, useCallback } from 'react';
import { notification, Select } from 'antd';
import { useSelector } from 'react-redux';

import { ReduxStore } from 'interfaces/reduxStore';
import { EstablishmentState } from 'interfaces/establishment';
import { Role } from 'interfaces/role';
import api from 'services/api';
import { establishmentRole } from 'helpers/mapper';
import { Employee } from 'interfaces/employee';
import { translate } from 'config/i18n';
import { Modal, ListItem, Button } from './styles';

interface ModalProps {
  employee: Employee;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  callback: (roles: Array<Role>) => void;
}

function EditRoleModal({
  employee,
  visible,
  setVisible,
  callback,
}: ModalProps) {
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );

  const [roles, setRoles] = useState<Array<Role>>([]);
  const [preLoading, setPreLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [userRoles, setUserRoles] = useState<Array<Role>>([]);

  useEffect(() => {
    async function getRolesFromServer() {
      try {
        const { data } = await api.get(
          `trade/establishments/${currentEstablishment?.id}/roles`,
        );

        setUserRoles(
          employee.roles.map((x: Role) => ({
            ...x,
            formattedName: establishmentRole[x.name],
          })),
        );

        const filteredRoles = data.data.filter(
          (x: Role) => !employee.roles.map((y: Role) => y.id).includes(x.id),
        );
        setRoles(
          filteredRoles.map((x: Role) => ({
            ...x,
            formattedName: establishmentRole[x.name],
          })),
        );
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setPreLoading(false);
      }
    }

    if (visible) getRolesFromServer();
  }, [currentEstablishment, visible, employee]);

  useEffect(() => {
    const selectTitle = document.getElementsByClassName(
      'ant-select-selection-item',
    )[0] as HTMLSpanElement;

    if (selectTitle) selectTitle.innerText = 'Permissão';
  }, [roles]);

  const handleSelectRole = useCallback(
    (roleId: string) => {
      const selectedRole = roles.find(role => role.id === roleId);

      if (selectedRole) {
        setUserRoles([...userRoles, selectedRole]);
        setRoles(roles.filter(role => role.id !== roleId));
      }
    },
    [roles, userRoles],
  );

  const handleRemoveRole = useCallback(
    (roleId: string) => {
      const selectedRole = userRoles.find(role => role.id === roleId);

      if (selectedRole) {
        setRoles([...roles, selectedRole].sort((a, b) => (a < b ? -1 : 1)));
        setUserRoles(userRoles.filter(role => role.id !== roleId));
      }
    },
    [userRoles, roles],
  );

  const resetFields = useCallback(() => {
    setPreLoading(true);
    setUserRoles([]);
  }, []);

  const handleSubmit = useCallback(async () => {
    let error = false;

    if (userRoles.length === 0) {
      error = true;
      notification.error({
        message: translate('general.error'),
        description: translate('invite.select_at_least_one_role'),
      });
    }

    if (error) return;

    try {
      setLoading(true);

      await api.put(`trade/employees/${employee.id}/roles`, {
        roles: userRoles.map((x: Role) => x.id),
      });

      callback(userRoles);

      notification.success({
        message: translate('general.success'),
        description: translate('invite.permissions_have_been_updated'),
      });
      setVisible(false);
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
    }
  }, [userRoles, setVisible, employee, callback]);

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      afterClose={resetFields}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">{translate('employee.employee_role')}</div>
      <div className="modal-content">
        <Select
          className="select-roles"
          placeholder={
            preLoading
              ? `${translate('general.loading')}...`
              : translate('employee.role')
          }
          onChange={handleSelectRole}
          disabled={preLoading}
        >
          {roles.map(role => (
            <Select.Option key={role.id} value={role.id}>
              {role.formattedName}
            </Select.Option>
          ))}
        </Select>
        <div>
          <ListItem>
            {userRoles.map(role => (
              <li key={role.id}>
                {role.formattedName}
                <button
                  className="btn-clean"
                  type="button"
                  onClick={() => handleRemoveRole(role.id)}
                >
                  x
                </button>
              </li>
            ))}
          </ListItem>
        </div>
      </div>
      <div className="align-center">
        <Button
          onClick={handleSubmit}
          disabled={loading}
          htmlType="button"
          type="primary"
          size="large"
        >
          {loading
            ? `${translate('general.loading')}...`
            : translate('general.update')}
        </Button>
      </div>
    </Modal>
  );
}

export default memo(EditRoleModal);
