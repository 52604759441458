import { notification } from 'antd';
import ImageSrc from 'assets/svg/empty-integration-image.svg';
import ConfirmModal from 'components/ConfirmModal';
import { translate } from 'config/i18n';
import { usePermissions } from 'hooks/usePermissions';
import { EstablishmentState } from 'interfaces/establishment';
import { Integration } from 'interfaces/integration';
import { ReduxStore } from 'interfaces/reduxStore';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from 'services/api';

import { Container, ImageContainer, Content } from './styles';

interface IntegrationCardProps {
  integration: Integration;
}

function Card({ integration }: IntegrationCardProps): JSX.Element {
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );
  const [loading, setLoading] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(
    !(!integration.config || integration.config.disabledAt),
  );

  const [integrationName] = useState(() =>
    integration.type === 'external'
      ? integration.name
      : translate(`integration.${integration.slug}`),
  );

  const history = useHistory();
  const { refreshCurrentEstablishment } = usePermissions();

  async function handleStockModule() {
    try {
      setLoading(true);

      const action = isEnabled ? 'deactivate' : 'activate';

      await api.post(
        `trade/establishments/${currentEstablishment?.id}/integrations/stock/${action}`,
      );

      refreshCurrentEstablishment(currentEstablishment?.id as string);

      notification.success({
        message: translate('general.success'),
        description: translate('establishment.integration_configured'),
      });
      setIsEnabled(!isEnabled);
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
      setConfirmModalVisible(false);
    }
  }

  return (
    <Container>
      <ImageContainer>
        <div className={isEnabled ? 'ativado' : 'desativado'}>
          {isEnabled
            ? translate('general.enabled')
            : translate('general.disabled')}
        </div>
        <img src={ImageSrc} alt={translate('integration.image')} />
      </ImageContainer>
      <Content>
        <span className="title">{integrationName}</span>
        <span className="category">{integration.category}</span>
        <span className="condition">Gratuito</span>
        <span className="description">{integration.description}</span>

        {integration.name !== 'stock' ? (
          <button
            type="button"
            className="btn-clean"
            onClick={() => {
              history.push(`/integrations/${integration.name}`, {
                config: integration.config,
                integrationName,
              });
            }}
          >
            {translate('general.configure')}
          </button>
        ) : (
          <button
            type="button"
            className="btn-clean"
            onClick={() =>
              !isEnabled ? handleStockModule() : setConfirmModalVisible(true)
            }
          >
            {loading
              ? translate('general.loading')
              : isEnabled
              ? translate('general.disable')
              : translate('general.enable')}
          </button>
        )}
      </Content>

      <ConfirmModal
        loading={loading}
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        confirmText={translate('integration.disable_confirmation', {
          integrationName: translate(`integration.${integration.name}`),
        })}
        confirmCallback={handleStockModule}
      />
    </Container>
  );
}

export default Card;
