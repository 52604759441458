import styled from 'styled-components';
import { Button as btn, Modal as antdModal } from 'antd';

export const Modal = styled(antdModal)`
  div.align-center {
    text-align: center;
  }

  width: 600px !important;

  span.ant-modal-close-x {
    color: black;
  }

  div.modal-content {
    border-radius: 15px;
    /* padding: 15px; */
    padding-top: 10px;
    margin: 15px 0;

    .image-container {
      background: #f6f6f6;
      border-radius: 15px;
      padding: 10px;
      height: 120px;
      display: flex;
      align-items: center;

      > div {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      span {
        font-size: 18px;
      }

      span:first-of-type {
        font-weight: bold;
      }

      img {
        height: 100%;
      }
    }
  }

  div.modal-header {
    font-size: 25px;
    font-weight: bold;
    padding-bottom: 15px;
    color: black;
    border-bottom: 1px solid #ececec;
  }

  .select-roles {
    width: 100%;
  }

  .ant-select-selection-item {
    opacity: 0.4;
  }

  .ant-select-selector {
    background: #f6f6f6;
  }

  .ant-select-arrow {
    color: black;
  }

  .unit-cost-container {
    position: relative;
  }
`;

export const Button = styled(btn)`
  width: 100%;
  margin: 67px auto 0;
  height: 55px;
  font-size: 19px;
`;

export const AddIconButton = styled.button`
  margin-bottom: 20px;
  font-size: 17px;
  color: #67687a;
  padding: 4px 18px;
  border-radius: 5px;

  &:hover {
    background: whitesmoke;
  }

  span {
    position: relative;
    top: -2px;
    font-weight: 600;
    margin-right: 3px;
  }
`;

export const Label = styled.label`
  font-size: 18px;
  margin-bottom: 5px;
  margin-left: 5px;
  display: block;
  color: black;

  input + & {
    margin-top: 20px;
  }
`;

export const PlaceholderIcon = styled.button`
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background: #ffefe9;
  outline: 0;
  border: 0;
  margin-bottom: 15px;

  img {
    max-height: 50px;
    max-width: 50px;
    width: 80%;
  }
`;
