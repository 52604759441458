import styled from 'styled-components';
import { EllipsisOutlined as EllipsisOutlinedIcon } from '@ant-design/icons';
import { Button as btn, Dropdown as DefaultDropdown, Menu } from 'antd';
import Excel from 'assets/svg/excel.svg';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;

  align-items: center;
  margin-top: 45px;
`;

export const Button = styled(btn)`
  max-width: 240px;
`;

export const Content = styled.div`
  table tbody tr td,
  table thead tr th {
    text-align: center !important;
  }

  .favorite-icon {
    color: #fe6a21;
    font-size: 24px;
  }

  .actions-icon {
    font-size: 24px;
  }
`;

export const ImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background: #ffefe9;

  img {
    height: 30%;
  }
`;

export const ImageContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50px;
  height: 50px;

  img {
    height: 100%;
  }
`;

export const SwitchContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Dropdown = styled(DefaultDropdown)`
  cursor: pointer;
  padding: 0;

  ul {
    padding: 0;
  }
`;

export const MenuItem = styled(Menu.Item)`
  margin: 0;
  padding: 5px 20px;
  position: relative;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: flex-end;

  .page-counter {
    margin-right: 10px;
  }
`;

export const EllipsisOutlinedRotaded = styled(EllipsisOutlinedIcon)`
  transform: rotate(90deg);
`;

export const ExcelIcon = styled.img.attrs({
  src: Excel,
})`
  top: -2px;
  position: relative;
  left: -5px;
`;

export const HeaderTitle = styled.div`
  margin-right: auto;
  max-width: 450px;
  color: #65666b;

  span:first-of-type {
    display: block;
    font-weight: 600;
    font-size: 23px;
    color: #101115;
  }
`;
