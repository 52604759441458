import styled, { css } from 'styled-components';
import DTTable from 'components/DataTable';
import { Button as btn, Dropdown as DefaultDropdown, Menu } from 'antd';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const Button = styled(btn)`
  max-width: 240px;
`;

export const Content = styled.div`
  margin-bottom: 30px;

  .loading-div {
    padding-left: 30px;
    font-size: 18px;
    font-style: italic;
    color: #999;
    margin-top: 20px;
  }

  .align-center {
    text-align: center;
  }

  .hide-link {
    margin: 30px 0 20px;
    text-align: center;
    width: 100%;

    span {
      text-decoration: underline;
      font-size: 18px;
    }
  }

  .actions-icon {
    font-size: 24px;
  }
`;

interface PlaceHolderProps {
  avatar?: string;
}

export const ImagePlaceholder = styled.div<PlaceHolderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  background: #ffefe9;
  background-size: cover;

  ${props =>
    props.avatar &&
    css`
      background-image: url(${props.avatar});
    `}

  img {
    ${props =>
      props.avatar &&
      css`
        display: none;
      `}

    height: 30%;
  }
`;

export const DataTable = styled(DTTable)`
  white-space: pre;

  .btn-clean img {
    width: 20px;
  }
`;

export const SwitchContainer = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
`;

export const Dropdown = styled(DefaultDropdown)`
  cursor: pointer;
  padding: 0;

  ul {
    padding: 0;
  }
`;

export const MenuItem = styled(Menu.Item)`
  margin: 0;
  padding: 5px 20px;
  position: relative;
`;
