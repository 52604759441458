import { useState, useCallback } from 'react';
import { Form, Row, Col, notification, Switch } from 'antd';

import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';

import api from 'services/api';
import Page from 'components/Page';
import FloatLabel from 'components/FloatLabel';

import { gutter } from 'constants/styles';
import { EstablishmentState } from 'interfaces/establishment';
import { translate } from 'config/i18n';
import { useHistory, useLocation } from 'react-router-dom';
import { RuleObject } from 'antd/lib/form';
import { usePermissions } from 'hooks/usePermissions';
import ConfirmModal from 'components/ConfirmModal';
import {
  FormContainer,
  Section,
  Input,
  Header,
  Button,
  SwitchContainer,
} from './styles';

interface ICSC {
  id: string;
  code: string;
}

interface IEnotasConfig {
  disabledAt: string;
  accountingEmail: string;
  simplesNacional: boolean;
  serieNFe?: string;
  sequenciaNFe?: string;
  companyId?: string;
  csc?: {
    production: ICSC;
    homologation: ICSC;
  };
}

interface INavState {
  config: IEnotasConfig;
  integrationName: string;
}

export default function EnotasConfiguration(): JSX.Element {
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );
  const {
    state: { config, integrationName },
  } = useLocation<INavState>();
  const [showCscConfig, setShowCscConfig] = useState(!config?.companyId);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const [form] = Form.useForm();
  const history = useHistory();
  const { refreshCurrentEstablishment } = usePermissions();

  const [loading, setLoading] = useState(false);
  const [simplesNacional, setSimplesNacional] = useState(
    config?.simplesNacional,
  );

  const handleForm = useCallback(
    async data => {
      try {
        setLoading(true);

        const formData = {
          simplesNacional: simplesNacional || false,
          accountingEmail: data.accountingEmail,
          serieNFe: data.serieNFe,
          sequenciaNFe: data.sequenciaNFe,
          csc: {
            production: {
              id: data.cscProductionId,
              code: data.cscProductionCode,
            },
            homologation: {
              id: data.cscHomologationId,
              code: data.cscHomologationCode,
            },
          },
        } as IEnotasConfig;

        if (!showCscConfig) {
          delete formData.csc;
          delete formData.serieNFe;
          delete formData.sequenciaNFe;
        }

        if (!formData.serieNFe) delete formData.serieNFe;
        if (!formData.sequenciaNFe) delete formData.sequenciaNFe;

        await api.put(
          `trade/establishments/${currentEstablishment?.id}/integrations/e-notas`,
          formData,
        );

        refreshCurrentEstablishment(currentEstablishment?.id as string);

        notification.success({
          message: translate('general.success'),
          description: translate('establishment.integration_configured'),
        });
        history.push('/integrations');
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    },
    [
      currentEstablishment,
      history,
      showCscConfig,
      simplesNacional,
      refreshCurrentEstablishment,
    ],
  );

  const onlyNumbers = (
    _rule: RuleObject,
    value: string,
    callback: (error?: string) => void,
  ): void => {
    try {
      if (!value) callback();
      const price =
        typeof value === 'number' ? value : Number(value.replace(',', '.'));

      const errorMessage = translate(
        'validation_messages.number_must_be_grater_than',
        { field: translate('integration.nfe_sequence'), length: 'zero' },
      );
      if (price <= 0) throw new Error(errorMessage);

      if (!/^\d+$/.test(price.toString())) {
        throw new Error(
          translate('validation_messages.must_be_a_number', {
            field: 'integration.nfe_sequence',
          }),
        );
      }

      callback();
    } catch (error: any) {
      callback(error);
    }
  };

  const disable = useCallback(async () => {
    try {
      setLoading(true);

      await api.post(
        `trade/establishments/${currentEstablishment?.id}/integrations/e-notas/deactivate`,
      );

      refreshCurrentEstablishment(currentEstablishment?.id as string);

      notification.success({
        message: translate('general.success'),
        description: translate('integration.disabled'),
      });
      history.push('/integrations');
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
      setConfirmModalVisible(false);
    }
  }, [currentEstablishment, history, refreshCurrentEstablishment]);

  return (
    <Page
      displayDrawer
      title={`${translate('general.configuration')}: ${integrationName}`}
      SubHeader={
        <Header>
          {!!config && !config.disabledAt && (
            <Button
              disabled={loading}
              onClick={() => setConfirmModalVisible(true)}
              htmlType="button"
              className="disable-button"
              type="default"
            >
              {translate('integration.disable')}
            </Button>
          )}
          <Button
            onClick={() => {
              history.push('/integrations');
            }}
            htmlType="button"
            type="default"
          >
            {translate('general.cancel')}
          </Button>

          <Button
            disabled={loading}
            onClick={() => {
              form.submit();
            }}
            htmlType="submit"
            type="primary"
          >
            {loading ? translate('general.saving') : translate('general.save')}
          </Button>
        </Header>
      }
    >
      <FormContainer>
        <Form initialValues={config} form={form} onFinish={handleForm}>
          <Section>
            <Row gutter={gutter}>
              <Col sm={20}>
                <SwitchContainer>
                  <label>Simples Nacional</label>
                  <Form.Item name="simplesNacional">
                    <Switch
                      checked={simplesNacional}
                      onChange={() => {
                        setSimplesNacional(!simplesNacional);
                      }}
                    />
                  </Form.Item>
                </SwitchContainer>
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col sm={20}>
                <FloatLabel
                  label={`${translate('integration.accounting_email')}*`}
                >
                  <Form.Item
                    name="accountingEmail"
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'validation_messages.required_field',
                          { field: 'integration.accounting_email' },
                        ),
                      },
                      {
                        type: 'email',
                        message: translate(
                          'validation_messages.invalid_field',
                          { field: 'integration.accounting_email' },
                        ),
                      },
                    ]}
                  >
                    <Input maxLength={32} />
                  </Form.Item>
                </FloatLabel>
              </Col>
            </Row>

            {config?.companyId && (
              <Button
                className="csc-config"
                disabled={loading}
                onClick={() => {
                  setShowCscConfig(!showCscConfig);
                }}
                htmlType="button"
                type="primary"
              >
                {translate('integration.show_csc_config')}
              </Button>
            )}

            {showCscConfig && (
              <>
                <Row gutter={gutter}>
                  <Col sm={20}>
                    <FloatLabel label={`${translate('integration.nfe_serie')}`}>
                      <Form.Item
                        name="serieNFe"
                        rules={[{ validator: onlyNumbers }]}
                      >
                        <Input maxLength={4} />
                      </Form.Item>
                    </FloatLabel>
                  </Col>
                </Row>
                <Row gutter={gutter}>
                  <Col sm={20}>
                    <FloatLabel
                      label={`${translate('integration.nfe_sequence')}`}
                    >
                      <Form.Item
                        name="sequenciaNFe"
                        rules={[{ validator: onlyNumbers }]}
                      >
                        <Input maxLength={4} />
                      </Form.Item>
                    </FloatLabel>
                  </Col>
                </Row>
                <h1>CSC</h1>
                <h3>{translate('integration.production')}</h3>
                <Row gutter={gutter}>
                  <Col sm={20}>
                    <FloatLabel label={`${translate('integration.id')}*`}>
                      <Form.Item
                        name="cscProductionId"
                        rules={[
                          {
                            required: true,
                            message: translate(
                              'validation_messages.required_field',
                              { field: 'integration.id' },
                            ),
                          },
                        ]}
                      >
                        <Input maxLength={32} />
                      </Form.Item>
                    </FloatLabel>
                  </Col>
                </Row>
                <Row gutter={gutter}>
                  <Col sm={20}>
                    <FloatLabel label={`${translate('integration.code')}*`}>
                      <Form.Item
                        name="cscProductionCode"
                        rules={[
                          {
                            required: true,
                            message: translate(
                              'validation_messages.required_field',
                              { field: 'integration.code' },
                            ),
                          },
                        ]}
                      >
                        <Input maxLength={32} />
                      </Form.Item>
                    </FloatLabel>
                  </Col>
                </Row>
                <h3>{translate('integration.homologation')}</h3>
                <Row gutter={gutter}>
                  <Col sm={20}>
                    <FloatLabel label={`${translate('integration.id')}*`}>
                      <Form.Item
                        name="cscHomologationId"
                        rules={[
                          {
                            required: true,
                            message: translate(
                              'validation_messages.required_field',
                              { field: 'integration.id' },
                            ),
                            validateTrigger: 'onBlur',
                          },
                        ]}
                      >
                        <Input maxLength={32} />
                      </Form.Item>
                    </FloatLabel>
                  </Col>
                </Row>
                <Row gutter={gutter}>
                  <Col sm={20}>
                    <FloatLabel label={`${translate('integration.code')}*`}>
                      <Form.Item
                        name="cscHomologationCode"
                        rules={[
                          {
                            required: true,
                            message: translate(
                              'validation_messages.required_field',
                              { field: 'integration.code' },
                            ),
                            validateTrigger: 'onBlur',
                          },
                        ]}
                      >
                        <Input maxLength={32} />
                      </Form.Item>
                    </FloatLabel>
                  </Col>
                </Row>
              </>
            )}
          </Section>
        </Form>
      </FormContainer>

      <ConfirmModal
        loading={loading}
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
        confirmText={translate('integration.disable_confirmation', {
          integrationName: 'ENotas',
        })}
        confirmCallback={disable}
      />
    </Page>
  );
}
