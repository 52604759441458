import * as Sentry from '@sentry/browser';

export function setUser(id: string): void {
  Sentry.setUser({ id });
}

export function clearUser(): void {
  Sentry.configureScope(scope => scope.setUser(null));
}

export function setTag({
  tagName,
  value,
}: {
  tagName: string;
  value: string | undefined;
}): void {
  Sentry.setTag(tagName, value);
}
