interface IGutter {
  xs: number;
  sm: number;
  md: number;
  lg: number;
}

export const gutter: IGutter = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};

export const defaultPageSize = 100;
