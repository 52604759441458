import { memo, useCallback, useState } from 'react';

import { translate } from 'config/i18n';
import { Store } from 'antd/lib/form/interface';
import { Col, Row, Form, notification } from 'antd';
import api from 'services/api';
import { Modal, Button, Input } from './styles';

interface ModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  callback: (email: string | null, phone: string | null) => void;
}

function ChangeEmailModal({ visible, setVisible, callback }: ModalProps) {
  const [loading, setLoading] = useState(false);

  const handleForm = useCallback(
    async (data: Store) => {
      try {
        setLoading(true);
        await api.put('me/email', data);
        setVisible(false);
        callback(data.email, null);
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('profile.email_updated_failed'),
        });
      } finally {
        setLoading(false);
      }
    },
    [setVisible, callback],
  );

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">
        {translate('profile.email_editing')}
        <p>{translate('profile.type_new_email')}</p>
      </div>
      <div className="modal-content">
        <Form onFinish={handleForm}>
          <Row>
            <Col sm={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: translate('validation_messages.required_field', {
                      field: 'profile.new_email',
                    }),
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder={translate('profile.new_email')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <Button
                disabled={loading}
                htmlType="submit"
                type="primary"
                size="large"
              >
                {loading
                  ? `${translate('general.loading')}...`
                  : translate('general.confirm')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default memo(ChangeEmailModal);
