import { memo } from 'react';
import { Alert, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { translate } from 'config/i18n';
import { ButtonContainer } from './styles';

function NoCodeInfo() {
  const history = useHistory();
  return (
    <>
      <Alert
        type="error"
        message="Erro"
        showIcon
        description={translate('auth.confirmation_code_not_provided')}
      />
      <ButtonContainer justify="center">
        <Button
          onClick={() => history.push(`/signup${window.location.search}`)}
          size="large"
        >
          {translate('auth.request_another_code')}
        </Button>
      </ButtonContainer>
    </>
  );
}

export default memo(NoCodeInfo);
