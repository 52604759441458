import { memo, useState, useCallback, useEffect } from 'react';

import Cookies from 'universal-cookie';
import InputMask from 'react-input-mask';
import {
  parse,
  format,
  startOfHour,
  startOfMinute,
  subHours,
  addHours,
  parseISO,
  isBefore,
} from 'date-fns';

import Page from 'components/Page';
import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { Establishment } from 'interfaces/establishment';
import api from 'services/api';
import { Col, notification, Row, Form } from 'antd';
import LoadingImg from 'assets/images/loading.gif';
import { gutter } from 'constants/styles';
import { Store } from 'antd/lib/form/interface';
import { translate } from 'config/i18n';
import { LoadingContainer, IFrame, Input, Button } from './styles';

const cookies = new Cookies();

function Employees() {
  const [form] = Form.useForm();
  const [metabaseUrl, setMetabaseUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const establishment = useSelector<ReduxStore, Establishment | null>(
    state => state.establishment.currentEstablishment,
  );

  const getChartsFromServer = useCallback(
    async (initialDate: string, finalDate: string) => {
      try {
        setLoading(true);
        const response = await api.get(
          `trade/establishments/${establishment?.id}/dashboards/employee-panel?initialDate=${initialDate}&finalDate=${finalDate}`,
        );
        setMetabaseUrl(response.data.data.href);
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
        setLoading(false);
      }
    },
    [establishment],
  );

  useEffect(() => {
    if (!cookies.get('dashboard_initial')) {
      const currentDate = startOfMinute(startOfHour(new Date()));

      getChartsFromServer(
        subHours(currentDate, 12).toISOString(),
        addHours(currentDate, 12).toISOString(),
      );
    } else {
      form.setFieldsValue({
        initialDate: format(
          parseISO(cookies.get('dashboard_initial')),
          'dd/MM/yyyy HH:mm',
        ),
        finalDate: format(
          parseISO(cookies.get('dashboard_final')),
          'dd/MM/yyyy HH:mm',
        ),
      });

      getChartsFromServer(
        cookies.get('dashboard_initial'),
        cookies.get('dashboard_final'),
      );
    }
  }, [getChartsFromServer, form]);

  const handleOnloadIFrame = useCallback(() => {
    setLoading(false);
  }, []);

  const handleForm = useCallback(
    (data: Store) => {
      let initialDate = '';
      let finalDate = '';

      try {
        initialDate = parse(
          data.initialDate,
          'dd/MM/yyyy HH:mm',
          new Date(),
        ).toISOString();
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('dashboard.invalid_initial_date'),
        });
        return;
      }

      try {
        finalDate = parse(
          data.finalDate,
          'dd/MM/yyyy HH:mm',
          new Date(),
        ).toISOString();
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('dashboard.invalid_final_date'),
        });
        return;
      }

      if (
        isBefore(
          parse(data.finalDate, 'dd/MM/yyyy HH:mm', new Date()),
          parse(data.initialDate, 'dd/MM/yyyy HH:mm', new Date()),
        )
      ) {
        notification.error({
          message: translate('general.error'),
          description: translate(
            'validation_messages.date_must_be_grater_than_field',
            {
              grater: 'dashboard.final_date',
              smaller: 'dashboard.initial_date',
            },
          ),
        });
        return;
      }

      cookies.set('dashboard_initial', initialDate, {
        sameSite: 'strict',
      });

      cookies.set('dashboard_final', finalDate, {
        sameSite: 'strict',
      });

      getChartsFromServer(initialDate, finalDate);
    },
    [getChartsFromServer],
  );

  return (
    <Page
      title="Dashboard"
      tabs={[
        {
          label: translate('dashboard.sales'),
          route: '/dashboard',
        },
        {
          label: translate('dashboard.employees'),
          route: '/dashboard/employees',
        },
      ]}
      SubHeader={
        <Form
          form={form}
          onFinish={handleForm}
          initialValues={{
            initialDate: format(
              subHours(startOfMinute(startOfHour(new Date())), 12),
              'dd/MM/yyyy HH:mm',
            ),
            finalDate: format(
              addHours(startOfMinute(startOfHour(new Date())), 12),
              'dd/MM/yyyy HH:mm',
            ),
          }}
        >
          <Row gutter={gutter}>
            <Col sm={24} md={8}>
              <Form.Item
                name="initialDate"
                rules={[
                  {
                    required: true,
                    message: translate('validation_messages.required_field', {
                      field: 'dashboard.initial_date',
                    }),
                  },
                  {
                    pattern: new RegExp(/^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/),
                    message: translate(
                      'validation_messages.must_be_in_format',
                      {
                        field: 'dashboard.initial_date',
                        format: 'dd/mm/aaaa hh:mm',
                      },
                    ),
                  },
                ]}
              >
                <InputMask mask={translate('masks.date_time')}>
                  <Input placeholder={translate('dashboard.initial_date')} />
                </InputMask>
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item
                name="finalDate"
                rules={[
                  {
                    required: true,
                    message: translate('validation_messages.required_field', {
                      field: 'dashboard.final_date',
                    }),
                  },
                  {
                    pattern: new RegExp(/^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/),
                    message: translate(
                      'validation_messages.must_be_in_format',
                      {
                        field: 'dashboard.final_date',
                        format: 'dd/mm/aaaa hh:mm',
                      },
                    ),
                  },
                ]}
              >
                <InputMask mask={translate('masks.date_time')}>
                  <Input placeholder={translate('dashboard.final_date')} />
                </InputMask>
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Button disabled={loading} htmlType="submit" type="primary">
                {loading
                  ? translate('general.loading')
                  : translate('general.send')}
              </Button>
            </Col>
          </Row>
        </Form>
      }
    >
      {loading && (
        <LoadingContainer>
          <img src={LoadingImg} alt={translate('general.loading')} />
        </LoadingContainer>
      )}
      {metabaseUrl && (
        <IFrame
          onLoad={handleOnloadIFrame}
          style={{
            display: loading ? 'none' : 'block',
          }}
          src={metabaseUrl}
          title={translate('dashboard.employee_panel')}
          className="employees"
        />
      )}
    </Page>
  );
}

export default memo(Employees);
