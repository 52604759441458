import styled from 'styled-components';
import { Input as AntdInput, Button as ShadowedButton } from 'antd';

export const FormContainer = styled.div``;

export const Section = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px 0 20px 0;

  .ant-form-item-control-input-content > .ant-input {
    padding-top: 22.4px;
    padding-bottom: 8.4px;
  }
`;

export const SectionTitle = styled.h1`
  font-family: 'Titillium Web', sans-serif;
  color: #ff6d08;
  font-weight: 600;
`;

export const Input = styled(AntdInput)`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 18px;
  height: 60px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;

  button + button {
    margin-left: 20px;
  }

  .disable-button {
    color: #ff6d08;
    border-color: #ff6d08;
    margin-right: auto;
  }
`;

export const Button = styled(ShadowedButton)`
  text-shadow: none;
  box-shadow: none;
`;
