import styled from 'styled-components';
import { Button as ShadowedButton } from 'antd';

export const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 33px;
      font-weight: 700;
      color: #101116;
      margin: 0;
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #101116;
      margin: 0;
    }
  }

  .infos {
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 20px 30px;
    gap: 34px;
    margin-top: 30px;

    .label {
      font-weight: 700;
      color: #101116;
    }

    span:not(.label) {
      color: #65666b;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    > div.col {
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
    }
  }

  main {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;

    aside {
      padding: 30px;
      background: white;
      border-radius: 3px;

      h1 {
        color: #101116;
        font-weight: 700;
        font-size: 19px;
      }
    }

    .payment-forms {
      max-width: 400px;
      flex: 1;
      font-size: 16px;
      height: fit-content;

      > div:not(:last-of-type) {
        height: 40px;
        border-bottom: 1px solid #e3e4e7;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span:last-of-type {
          font-weight: 600;
        }
      }

      > div:last-of-type {
        margin-top: 30px;
        color: #101116;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span:last-of-type {
          font-size: 23px;
          font-weight: 700;
        }
      }
    }

    .reversal-list {
      flex: 1;

      .row.disabled {
        opacity: 0.3;

        button {
          cursor: initial;
        }
      }

      .col {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: flex-start;
      }

      .radio-container {
        margin: 35px 0;
        display: flex;
        color: #101116;
        font-weight: 600;
        align-items: center;
        gap: 15px;

        label {
          position: relative;
          top: -1px;
        }
      }

      > div:not(:first-of-type) {
        padding: 10px 0;
        gap: 30px;
        border-bottom: 1px solid #e3e4e7;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span:last-of-type {
          font-weight: 600;
        }
      }

      .quantity {
        font-weight: 700;
        color: #101116;
        align-self: flex-start;
      }

      .product-name {
        color: #101116;
      }

      .unit-price {
        color: #65666b;
        font-size: 14px;
      }

      .controls {
        display: flex;
        gap: 20px;
        align-items: center;

        .control {
          all: unset;
          &:first-of-type {
            font-size: 40px;
          }

          line-height: 0;
          color: #101116;
          font-size: 28px;
          cursor: pointer;
        }

        .value {
          border: 1px solid #e3e4e7;
          padding: 5px 10px;
          border-radius: 3px;
          font-size: 19px;
          line-height: 24px;
          color: #101116;
          font-weight: 600;
        }
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0;

  button + button {
    margin-left: 20px;
  }
`;

export const Button = styled(ShadowedButton)`
  text-shadow: none;
  box-shadow: none;
`;
