import { translate } from 'config/i18n';
import { HighlightText, Subtitle, HeaderContainer } from '../styles';
import { Title } from './styles';

function Header(): JSX.Element {
  return (
    <HeaderContainer>
      <Title>
        {translate('auth.welcome_to')}{' '}
        <HighlightText strong>FanPDV</HighlightText>
      </Title>
      <Subtitle strong>
        {translate('auth.best_establishments_are_here')}
      </Subtitle>
    </HeaderContainer>
  );
}

export default Header;
