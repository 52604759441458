import styled from 'styled-components';
import { Modal as antdModal } from 'antd';

export const Modal = styled(antdModal)`
  div.align-center {
    text-align: center;
  }

  width: 600px !important;

  > div {
    padding: 15px;
  }

  span.ant-modal-close-x {
    color: #101116;
    width: 28px;
    height: 28px;
    background: #f1f1f3;
    line-height: 28px;
    border-radius: 50%;
    margin: 30px 30px 0 0;
  }

  div.modal-content {
    font-size: 16px;

    > div:not(:last-of-type) {
      height: 40px;
      border-bottom: 1px solid #e3e4e7;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span:last-of-type {
        font-weight: 600;
      }
    }

    > div:last-of-type {
      margin-top: 30px;
      color: #101116;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span:last-of-type {
        font-size: 23px;
        font-weight: 700;
      }
    }
  }

  div.modal-header {
    margin-top: 45px;
    font-size: 25px;
    font-weight: 500;
    padding-bottom: 15px;
    color: #101116;
  }

  .select-roles {
    width: 100%;
  }

  .ant-select-selection-item {
    opacity: 0.4;
  }

  .ant-select-selector {
    background: #f6f6f6;
  }

  .ant-select-arrow {
    color: #101116;
  }
`;
