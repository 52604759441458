import { memo, useCallback, useState, useEffect } from 'react';

import { translate } from 'config/i18n';
import { Col, Row, Form, notification } from 'antd';
import api from 'services/api';
import CodeInput from 'components/CodeInput';
import { Modal, LinkButton } from './styles';

interface ModalProps {
  email: string | undefined;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  callback: (stringCode: string) => void;
}

const CODE_LENGTH = 6;

function ChangePasswordCodeModal({
  email,
  visible,
  setVisible,
  callback,
}: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [preLoading, setPreLoading] = useState(true);
  const [code, setCode] = useState<string[]>([]);

  useEffect(() => {
    async function resetPassword() {
      try {
        await api.post('reset-password', { email });
        notification.success({
          message: translate('general.success'),
          description: translate('profile.code_send_succeed'),
        });
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('profile.code_send_failed'),
        });
      } finally {
        setPreLoading(false);
      }
    }

    if (visible) {
      resetPassword();
    }
  }, [visible, email]);

  const handleForm = useCallback(
    async stringCode => {
      try {
        setLoading(true);
        await api.post('reset-password/verify-code', { code: stringCode });
        setCode([]);
        setVisible(false);
        callback(stringCode);
      } catch (err) {
        notification.error({
          message: translate('general.error'),
          description: translate('profile.code_confirm_failed'),
        });
      } finally {
        setLoading(false);
      }
    },
    [setVisible, callback],
  );

  useEffect(() => {
    const stringCode = code.join('');
    if (stringCode.length === CODE_LENGTH) {
      handleForm(stringCode);
    }
  }, [code, handleForm]);

  const handleCodeChange = useCallback((value: string[]) => {
    setCode(value);
  }, []);

  const handleResend = useCallback(async () => {
    try {
      await api.post('reset-password', { email });
      notification.success({
        message: translate('general.success'),
        description: translate('profile.code_resend_succeed'),
      });
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('profile.code_resend_failed'),
      });
    } finally {
      setLoading(false);
    }
  }, [email]);

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">
        {translate('profile.field_confirmation', { field: 'profile.email' })}
        <p>{translate('profile.type_code_sent_to_field', { field: email })}</p>
      </div>
      <div className="modal-content">
        <Form onFinish={handleForm}>
          <Row>
            <Col sm={24}>
              <CodeInput
                disabled={loading || preLoading}
                onChange={handleCodeChange}
                value={code}
                length={CODE_LENGTH}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} className="align-center">
              <LinkButton
                disabled={loading || preLoading}
                onClick={handleResend}
                htmlType="submit"
                type="link"
                size="large"
              >
                {preLoading
                  ? translate('general.loading')
                  : translate('auth.resend_code')}
              </LinkButton>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

export default memo(ChangePasswordCodeModal);
