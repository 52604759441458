import { useCallback, useState } from 'react';
import { Form, Row } from 'antd';
import { UserState } from 'interfaces/user';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { Store } from 'antd/lib/form/interface';
import { signup } from 'store/ducks/user';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useQuery } from 'hooks/useQuery';
import { translate } from 'config/i18n';
import { SubmitButton } from '../styles';
import { FormTitle } from './styles';

function PhoneForm(): JSX.Element {
  const dispatch = useDispatch();
  const query = useQuery();
  const { loading } = useSelector<ReduxStore, UserState>(state => state.user);
  const history = useHistory();
  const [redirectUrl] = useState<string | null>(query.get('redirect'));

  const handleForm = useCallback(
    (input: Store) => {
      dispatch(
        signup({ phone: `+${input.phone}`, redirect: redirectUrl }, () => {
          history.push(
            `/signup/confirm${
              window.location.search ? `${window.location.search}&` : '?'
            }phone=+${input.phone}`,
          );
        }),
      );
    },
    [dispatch, history, redirectUrl],
  );

  return (
    <>
      <FormTitle>
        {translate('auth.register_using')}
        <strong>{translate('auth.phone')}</strong>
      </FormTitle>
      <Form onFinish={handleForm}>
        <Form.Item
          name="phone"
          validateFirst
          rules={[
            {
              required: true,
              message: translate('validation_messages.required_field', {
                field: 'auth.phone',
              }),
              validateTrigger: 'onBlur',
            },
            {
              min: 12,
              message: translate('auth.phone_min_length', { minLength: 12 }),
              validateTrigger: 'onBlur',
            },
          ]}
        >
          <PhoneInput country="br" inputClass="ant-input" />
        </Form.Item>

        <Row justify="center">
          <SubmitButton
            disabled={loading}
            htmlType="submit"
            type="primary"
            size="large"
          >
            {loading
              ? translate('general.sending')
              : translate('general.confirm')}
          </SubmitButton>
        </Row>
      </Form>
    </>
  );
}

export default PhoneForm;
