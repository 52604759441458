import { RuleObject } from 'antd/lib/form';
import { translate } from 'config/i18n';
import { isValid, parse } from 'date-fns';
import { cnpj } from 'cpf-cnpj-validator';

export const isEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isCPF = (
  _rule: RuleObject,
  value: string,
  callback: (error?: string) => void,
): void => {
  try {
    if (!value) callback();
    const errorMessage = 'CPF inválido!';
    const cpf = value.replace(/[^\d]+/g, '');
    if (cpf === '') throw new Error(errorMessage);
    // Elimina CPFs invalidos conhecidos
    if (
      cpf.length !== 11 ||
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    )
      throw new Error(errorMessage);

    // Validate first digit
    let add = 0;
    for (let i = 0; i < 9; i += 1) {
      add += parseInt(cpf.charAt(i), 10) * (10 - i);
    }
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }
    if (rev !== parseInt(cpf.charAt(9), 10)) throw new Error(errorMessage);

    // Validate 2nd digit
    add = 0;
    for (let i = 0; i < 10; i += 1) {
      add += parseInt(cpf.charAt(i), 10) * (11 - i);
    }
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }
    if (rev !== parseInt(cpf.charAt(10), 10)) throw new Error(errorMessage);

    callback();
  } catch (error: any) {
    callback(error);
  }
};

export const isPositiveNumber = (
  _rule: RuleObject,
  value: string,
  callback: (error?: string) => void,
): void => {
  try {
    if (!value) callback();
    const price =
      typeof value === 'number' ? value : Number(value.replace(',', '.'));

    const errorMessage = translate(
      'validation_messages.number_must_be_grater_than',
      { field: translate('product.price'), length: 'zero' },
    );
    if (price <= 0) throw new Error(errorMessage);
    callback();
  } catch (error: any) {
    callback(error);
  }
};

export const isValidDate = (
  _rule: RuleObject,
  value: string,
  callback: (error?: string) => void,
): void => {
  try {
    if (!value) callback();

    const errorMessage = translate('validation_messages.invalid_date', {
      field: translate('establishment.partner_birthdate'),
      length: 'zero',
    });

    if (!isValid(parse(value, 'dd/MM/yyyy', new Date()))) {
      throw new Error(errorMessage);
    }
    callback();
  } catch (error: any) {
    callback(error);
  }
};

export const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const onlyNumbers = (
  _rule: RuleObject,
  value: string,
  callback: (error?: string) => void,
  field: string,
): void => {
  try {
    if (!value) callback();
    const price =
      typeof value === 'number' ? value : Number(value.replace(',', '.'));

    const errorMessage = translate(
      'validation_messages.number_must_be_grater_than',
      { field, length: 'zero' },
    );
    if (price <= 0) throw new Error(errorMessage);

    if (!/^\d+$/.test(price.toString())) {
      throw new Error(
        translate('validation_messages.must_be_a_number', {
          field,
        }),
      );
    }

    callback();
  } catch (error: any) {
    callback(error);
  }
};

export const isCNPJ2 = (
  _rule: RuleObject,
  value: string,
  callback: (error?: string) => void,
): void => {
  try {
    if (!value) callback();
    const errorMessage = 'CNPJ inválido!';
    const cnpjValue = value.replace(/[^\d]+/g, '');
    if (cnpjValue === '') throw new Error(errorMessage);

    if (cnpjValue.length !== 14) throw new Error(errorMessage);

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const isString = typeof value === 'string';
    const validTypes =
      isString || Number.isInteger(value) || Array.isArray(value);

    // Elimina valor em formato inválido
    if (!validTypes) throw new Error(errorMessage);

    // Filtro inicial para entradas do tipo string
    if (isString) {
      // Limita ao máximo de 18 caracteres, para CNPJ formatado
      if (value.length > 18) throw new Error(errorMessage);

      // Teste Regex para veificar se é uma string apenas dígitos válida
      const digitsOnly = /^\d{14}$/.test(value);
      // Teste Regex para verificar se é uma string formatada válida
      const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

      if (!digitsOnly && !validFormat) throw new Error(errorMessage);
    }

    // Guarda um array com todos os dígitos do valor
    const match = value.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    // Valida a quantidade de dígitos
    if (numbers.length !== 14) throw new Error(errorMessage);

    // Elimina inválidos com todos os dígitos iguais
    const items = [...new Set(numbers)];
    if (items.length === 1) throw new Error(errorMessage);

    // Cálculo validador
    const calc = (x: number) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor - 1;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    // Separa os 2 últimos dígitos de verificadores
    const digits = numbers.slice(12);

    // Valida 1o. dígito verificador
    const digit0 = calc(12);
    if (digit0 !== digits[0]) throw new Error(errorMessage);

    // Valida 2o. dígito verificador
    const digit1 = calc(13);

    if (digit1 === digits[1]) throw new Error(errorMessage);

    callback();
  } catch (error: any) {
    callback(error);
  }
};

export const isCNPJ = (
  _rule: RuleObject,
  value: string,
  callback: (error?: string) => void,
): void => {
  try {
    if (!value) callback();
    const errorMessage = 'CNPJ inválido!';
    const cnpjValue = value.replace(/[^\d]+/g, '');

    if (!cnpj.isValid(cnpjValue)) throw new Error(errorMessage);

    callback();
  } catch (error: any) {
    callback(error);
  }
};
