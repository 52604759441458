import { translate } from 'config/i18n';

import NotFoundImage from 'assets/svg/404.svg';
import Page from 'components/Page';

import { useHistory } from 'react-router-dom';
import { Container, Button } from './styles';

function NotFound(): JSX.Element {
  const history = useHistory();

  function handleBack() {
    history.push('/dashboard');
  }

  return (
    <Page title="">
      <Container>
        <img src={NotFoundImage} alt="404" />
        <h1>{translate('general_messages.page_not_found')}</h1>
        <p>{translate('general_messages.url_does_not_exist')}</p>

        <Button onClick={handleBack} type="primary">
          {translate('dashboard.title')}
        </Button>
      </Container>
    </Page>
  );
}

export default NotFound;
