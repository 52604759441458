export const isHexColor = (hexColor, requirePound = false) => {
  if (requirePound) {
    return /^#([0-9a-f]{3}){1,2}$/.test(hexColor.toLowerCase());
  }
  return /^#?([0-9a-f]{3}){1,2}$/.test(hexColor.toLowerCase());
};

export const stripPound = (hexColor = '') => {
  if (!isHexColor(hexColor)) return null;
  if (hexColor[0] === '#') {
    return { color: hexColor.substring(1), pound: '#' };
  }
  return { color: hexColor, pound: '' };
};

export const convertThreeToSix = (hexColor = '') => {
  if (!isHexColor(hexColor)) return null;
  const { color, pound } = stripPound(hexColor);
  if (color.length === 3) {
    return (
      pound + color
        .split('')
        .map((hex) => hex + hex)
        .join('')
    );
  }
  return pound + color;
};

export const getLuma = (hexColor = '') => {
  if (!isHexColor(hexColor)) return null;
  let { color } = stripPound(hexColor);
  color = convertThreeToSix(color);

  const rgb = parseInt(color, 16);
  // eslint-disable-next-line no-bitwise
  const red = rgb >> 16;
  // eslint-disable-next-line no-bitwise
  const green = (rgb >> 8) & 0xff;
  // eslint-disable-next-line no-bitwise
  const blue = rgb & 0xff;

  // ITU-R BT.709
  const luma = 0.2126 * red + 0.7152 * green + 0.0722 * blue;

  return luma;
};

export const isDark = (hexColor = '', lumaThreshold = 180) => {
  if (!isHexColor(hexColor)) return null;
  return getLuma(hexColor) < lumaThreshold;
};

export const lightenDarken = (hexColor = '', amt) => {
  if (!isHexColor(hexColor)) return null;
  const { pound } = stripPound(hexColor);
  const color = convertThreeToSix(stripPound(hexColor).color);

  const rgb = parseInt(color, 16);

  // eslint-disable-next-line no-bitwise
  let red = (rgb >> 16) + amt;
  if (red > 255) red = 255;
  else if (red < 0) red = 0;

  // eslint-disable-next-line no-bitwise
  let green = ((rgb >> 8) & 0xff) + amt;
  if (green > 255) green = 255;
  else if (green < 0) green = 0;

  // eslint-disable-next-line no-bitwise
  let blue = (rgb & 0xff) + amt;
  if (blue > 255) blue = 255;
  else if (blue < 0) blue = 0;

  // eslint-disable-next-line no-bitwise
  return pound + (blue | (green << 8) | (red << 16)).toString(16);
};

export const lightToDark = (hexColor = '', lumaThreshold = 180) => {
  if (!isHexColor(hexColor)) return null;
  const luma = getLuma(hexColor);
  if (luma > lumaThreshold) {
    return lightenDarken(hexColor, lumaThreshold - luma);
  }
  return hexColor;
};

export const darkToLight = (hexColor = '', lumaThreshold = 180) => {
  if (!isHexColor(hexColor)) return null;
  const luma = getLuma(hexColor);
  if (luma < lumaThreshold) {
    return lightenDarken(hexColor, lumaThreshold - luma);
  }
  return hexColor;
};

export default {
  isHexColor,
  stripPound,
  convertThreeToSix,
  getLuma,
  isDark,
  lightenDarken,
  lightToDark,
  darkToLight,
};
