export default {
  translations: {
    dashboard: {
      title: 'Dashboard',
      sales: 'Vendas',
      employees: 'Colaboradores',
      financial_panel: 'Painel financeiro',
      employee_panel: 'Painel de Colaboradores',
      granularity: 'Granularidade',
      final_date: 'Data final',
      initial_date: 'Data inicial',
      invalid_initial_date: 'A data inicial informada não é uma data válida.',
      invalid_final_date: 'A data final informada não é uma data válida.',
    },
    profile: {
      title: 'Perfil',
      avatar: 'Avatar',
      my_profile: 'Meu perfil',
      linked_establishments: 'Estabelecimentos vinculados',
      remove_account_confirmation: 'Tem certeza que deseja remover sua conta?',
      your_account_was_removed: 'Sua conta foi removida.',
      avatar_was_removed: 'O avatar foi removido.',
      avatar_was_updated: 'O avatar foi atualizado.',
      email_icon: 'Ícone do e-mail',
      phone_icon: 'Ícone do telefone',
      registration_data_updating: 'Alteração de dados cadastrais',
      name: 'Nome',
      document: 'CPF',
      email: 'Email',
      phone: 'Telefone',
      new_email: 'Novo email',
      new_phone: 'Novo telefone',
      upload_indicator: 'Indicador de upload',
      current_password: 'Senha atual',
      new_password: 'Nova senha',
      change_password: 'Alterar senha',
      add_password: 'Adicionar senha',
      type_new_password: 'Digite uma nova senha para sua conta',
      password_updated_succeed: 'Sua senha foi alterada com sucesso',
      password_updated_failed: 'Ocorreu um erro com a alteração da senha',
      code_confirm_failed: 'Ocorreu um erro com a confirmação do código',
      code_resend_failed: 'Ocorreu um erro com o reenvio do código',
      code_resend_succeed: 'O código foi reenviado com sucesso',
      code_send_failed: 'Ocorreu um erro com o envio do código',
      code_send_succeed: 'O código foi enviado com sucesso',
      email_updated_succeed: 'Seu email foi alterado com sucesso',
      email_updated_failed:
        'Ocorreu um erro com a solicitação de alteração de email',
      phone_updated_succeed: 'Seu telefone foi alterado com sucesso',
      phone_updated_failed:
        'Ocorreu um erro com a solicitação de alteração de telefone',
      email_editing: 'Edição de email',
      phone_editing: 'Edição de telefone',
      type_new_email: 'Digite o novo email que deseja adicionar',
      type_new_phone: 'Digite o novo telefone que deseja adicionar',
      type_code_sent_to_field:
        'Digite o código enviado para {{field}} para validação',
      field_confirmation: 'Confirmação de $t({{field}})',
      no_relationship:
        'Você ainda não está vinculado a nenhum estabelecimento.',
    },
    operation: {
      title: 'Operação',
      title_field: 'Título',
      plural_title: 'Operações',
      open: 'Abrir operação',
      close: 'Fechar operação',
      opening: 'Abertura',
      closure: 'Fechamento',
      form_title: 'Título',
      was_opened: 'Operação aberta com sucesso',
      was_closed: 'Operação fechada com sucesso',
      establishment_options: 'Opções do estabelecimento',
    },
    campaign: {
      title: 'Campanha',
      plural_title: 'Campanhas',
      validDate: 'Data de expiração',
      bonus: 'Bônus',
      limitMinValue: 'Mínimo',
      limitMaxValue: 'Máximo',
      title_field: 'Título',
      situation: 'Situação',
      fixedPrice: 'Valor fixo',
      credit: 'Crédito',
      product: 'Produto',
      ticket: 'Ingresso',
      category: 'Categoria',
      publication: 'Publicação',
      status: 'Status',
      activated: 'Ativado',
      create: 'Criar campanha',
      was_saved: 'Campanha salva',
      delete: 'Remover campanha',
      edit: 'Editar campanha',
      remove_confirmation:
        'Tem certeza que deseja remover a campanha {{campaignName}}?',
      startAt: 'Início da campanha',
      endAt: 'Fim da campanha',
      start_end: 'Início/Fim',
      published: 'Publicada',
      limited: 'Limitada',
      disabled: 'Desabilitada',
      duplicate: 'Duplicar',
      extend: 'Extender',
    },
    product: {
      title: 'Produto',
      plural_title: 'Produtos',
      create: 'Cadastrar produto',
      delete: 'Deletar produto',
      delete_category: 'Deletar categoria',
      edit: 'Editar produto',
      product_image: 'Imagem do produto',
      remove_confirmation:
        'Tem certeza que deseja remover o produto {{productName}}?',

      // FORM FIELDS
      image: 'Imagem',
      name: 'Nome',
      icon: 'Ícone',
      category: 'Categoria',
      delivery_workflow: 'Workflow',
      categories: 'Categorias',
      favorite: 'Favorito',
      status: 'Status',
      price: 'Preço',
      description: 'Descrição',
      brand: 'Marca',

      base_category: 'Categoria base',
      add_category: 'Adicionar categoria',
      category_registration: 'Cadastro de categoria',

      register: 'Cadastro',
      editing: 'Edição',
      form_title: '{{mode}} de produto',

      unit_quantity: 'Quantidade de unidades',
      quantity: 'Quantidade',
      note: 'Observações',
      add_stock: 'Adicionar ao estoque',
      remove_stock: 'Remover do estoque',
      update_stock: 'Corrigir estoque',
      unit_cost: 'Custo unitário',

      stock_success_message: 'O estoque foi atualizado',
      product_category_success_message: 'A categoria foi salva',

      stock_unavailable: 'Quantidade disponível: {{value}}',

      was_enabled_disabled: 'O produto foi $t({{action}}).',
      was_favorited: 'O produto foi $t({{action}}) favoritos.',
      was_saved: 'Produto salvo.',
      tax_management: 'Gestão de impostos',

      export: 'Exportar tabela em XLS',
    },
    pdv: {
      title: 'Últimas vendas',
      date_hour: 'Data/Hora',
      product: 'Produto',
      value: 'Valor',
      operator: 'Operador',
      sales_list: 'Lista de vendas',
      identifier: 'Identificador',
      order_code: 'Código do pedido',
      payment_details: 'Informações de pagamento',
      sale_details: 'Informações da venda',
      payment_total: 'Total da compra',
      sale_reversal: 'Estorno da venda',
      reversed: 'Extornado?',
    },
    employee: {
      title: 'Colaborador',
      plural_title: 'Usuários',
      invite: 'Convidar colaborador',
      delete: 'Deletar colaborador',
      edit: 'Editar colaborador',
      change_operation_code: 'Alterar código de operador',
      operation_code: 'Código de operador',
      standby: 'Aguardando',
      product_image: 'Imagem do colaborador',
      remove_confirmation:
        'Tem certeza que deseja desvincular o colaborador {{employeeName}}?',
      status_change_confirmation:
        'Tem certeza que deseja {{action}} o colaborador {{employeeName}}?',
      show_hide_all: '{{action}} todos',
      waiting: 'Em aguardo',
      others: 'Outros',
      roles: 'Permissões',
      updated_at: 'Atualizado há {{count}} dia',
      updated_at_plural: 'Atualizado há {{count}} dias',
      updated_at_reduce: 'Atualizado $t({{day}})',
      expires_in: 'Expira em {{count}} dia',
      expires_in_plural: 'Expira em {{count}} dias',
      expires_in_reduce: 'Expira $t({{day}})',
      send_invites: 'Enviar convites',
      invite_employees: 'Convide colaboradores',
      employee_role: 'Permissão do colaborador',
      was_enabled_disabled: 'O colaborador foi $t({{action}}).',

      // FORM FIELDS
      image: 'Imagem',
      name: 'Nome',
      phone: 'Telefone',
      email: 'E-mail',
      role: 'Permissão',
      status: 'Status',
    },
    establishment: {
      title: 'Estabelecimento',
      plural_title: 'Estabelecimentos',

      my_establishments: 'Meus estabelecimentos',
      no_relationship:
        'Você ainda não está vinculado a nenhum estabelecimento.',
      create: 'Cadastrar estabelecimento',

      contact: 'Contato',

      operatorCode: 'Código do operador',
      relationship: 'Vínculo',
      logo_placeholder: 'Placeholder da logomarca',
      logo: 'Logomarca',
      email_icon: 'Ícone do e-mail',
      phone_icon: 'Ícone do telefone',
      my_relationship: 'Meu vínculo',
      association_data: 'Dados da associação',
      entry_into: 'Ingresso em',
      entry_date: 'Data de ingresso',
      function: 'Função',

      // ## FORM
      save: 'Salvar',
      saving: 'Salvando',
      general: 'Preferências gerais',
      preferences: 'Preferências',
      remove_relationship_confirmation:
        'Tem certeza que deseja se desvincular deste estabelecimento?',
      remove_confirmation:
        'Tem certeza que deseja remover este estabelecimento?',

      // Preferences
      min_value_in_card_payment: 'Pagamento mínimo com cartão',
      audience_capacity: 'Capacidade de público',
      audience_capacity_count: '{{count}} pessoa',
      audience_capacity_count_plural: '{{count}} pessoas',
      age_group: 'Faixa etária',
      convenience_fee_percentage: 'Taxa de conveniência',
      credit_rate: 'Taxa de crédito',
      debit_rate: 'Taxa de débito',
      show_brands_in_payment: 'Detalhamento de bandeiras',
      device_will_transact: 'Adquirencia on-line',
      reversal_in_cash: 'Estorno sempre em dinheiro',
      cash_management: 'Gestão de caixa',
      sale_installments: 'Venda parcelada de produto',
      card_machine_mode: 'Modo máquina de cartão',
      print_qrcode: 'Imprimir QR Code',
      operation_mode: 'Modo de operação',
      operation_category: 'Categoria de operação',
      seller_id: 'Seller ID',
      configuration_with_zoop: 'Configuração com Zoop',

      // ## FORM
      form_title: '{{mode}} de estabelecimento',
      register: 'Cadastro',
      editing: 'Edição',

      // sections
      identity: 'Identidade',
      public_contact: 'Contato público',
      address: 'Endereço',
      legal_responsible: 'Sócio administrador',
      tax: 'Fiscal',
      national_simple: 'Simples nacional',
      managing_partner: 'Sócio administrador',
      how_to_get: 'Como chegar',

      // fields
      trade_name: 'Nome fantasia ou apelido',
      accent_color: 'Cor de destaque (ex. #582c8b)',
      description: 'Descrição',
      web_site: 'Web site',
      document: 'CNPJ',
      contact_email: 'E-mail de contato',
      email: 'E-mail',
      contact_phone: 'Telefone de contato',
      phone: 'Telefone',
      abreviated_phone: 'Tel',
      company_name: 'Razão social',
      state_registration: 'Inscrição estadual',
      town_registration: 'Inscrição municipal',
      postal_code: 'CEP',
      state: 'Estado',
      city: 'Cidade',
      neighborhood: 'Bairro',
      eg_line1: 'Logradouro (e.g Rua. Avenida)',
      line1: 'Logradouro',
      line2: 'Número',
      line3: 'Complemento',
      full_name: 'Nome completo',
      partner_full_name: 'Nome completo do sócio',
      partner_document: 'CPF',
      partner_birthdate: 'Data de nascimento',
      insert_new_establishment: 'Criar novo estabelecimento',

      // ## END FORM

      establishment_was_removed: 'O estabelecimento foi removido',
      establishment_not_found: 'Estabelecimento não encontrado',
      you_leave_establishment: 'Você se desvinculou do estabelecimento',
      establishment_successfully_saved:
        'O estabelecimento foi salvo com sucesso',
      integration_configured: 'Integração configurada com sucesso',

      changed_records: '{{count}} registro foi alterado.',
      changed_records_plural: '{{count}} registros foram alterados.',

      changed_records_none: 'Nenhum registro foi alterado.',
      changed_records_fallback: '$t(establishment.changed_records_none)',

      error: {
        default: 'Ocorreu um erro com a requisição.',
        404: 'A página não pôde ser encontrada.',
        401: 'Você não tem permissão para acessar o recurso solicitado.',
      },
    },
    invite: {
      title: 'Convite',
      plural_title: 'Convites',
      no_invites: 'Você não possui nenhum convite',
      accepted_invite: 'Convite aceito',
      welcome_to_establishment:
        'Seja bem vindo ao time de colaboradores do estabelecimento',
      your_user_has_permissions: 'Seu usuário possui permissão de ',
      send_another_code: 'Enviar outro código',
      no_avaible_code:
        'Lembre-se de estar logado com o mesmo e-mail ou telefone do convite.',
      cancel_invite: 'Cancelar convite',
      invite_accepted: 'O convite foi aceito',
      invite_rejected: 'O convite foi recusado',
      invite_resent: 'O convite foi reenviado',
      invite_canceled: 'O convite foi cancelado',
      invites_sent: 'Os convites foram enviados',
      no_code_provided: 'Nenhum código de convite foi informado',
      select_at_least_one_email_phone:
        'Selecione pelo menos um email e/ou telefone',
      select_at_least_one_role: 'Selecione pelo menos uma permissão',
      invalid_phone: 'O telefone informado não é um telefone válido',
      invalid_email: 'O email informado não é um email válido',
      data_already_inserted: 'Este dado já foi inserido',
      permissions_have_been_updated: 'As permissões foram atualizadas',
      unexpected_error:
        'Ocorreu um erro inesperado. \nPor favor tente novamente ou entre em contato\ncom o administrador do estabelecimento.',
      counter: '{{count}} convite',
      counter_plural: '{{count}} convites',
      invalid_code: 'Este convite não existe ou não pertence a este usuário.',
      log_in_with_another_account: 'Logar com uma conta diferente',
    },
    auth: {
      sign_in: 'Login',
      welcome_to: 'Bem-vindo ao',
      email: 'E-mail',
      phone: 'Telefone',
      password: 'Senha',
      password_confirmation: 'Confirmação da senha',
      document: 'CPF',
      name: 'Nome',
      dont_have_account: 'Não possui conta?',
      register_clicking_here: 'Registre-se clicando aqui!',
      best_establishments_are_here: 'Os melhores estabelecimentos estão aqui.',
      log_in_using: 'Faça o login usando seu ',
      register_using: 'Cadastre-se usando seu ',
      create_your_account_and_begin_to_use: 'Crie sua conta e comece a usar o ',
      keep_connected: 'Manter conectado',
      forgot_password: 'Esqueceu a senha?',
      phone_min_length:
        'Seu telefone deve conter no mínimo {{minLength}} dígitos, incluindo código do pais e DDD',
      complete_with_phone_email_to:
        'Preencha com o seu email ou telefone para ',
      recover_your_password: 'recuperar sua senha.',
      insert_your: 'Insira seu ',
      resend_code: 'Reenviar código',
      already_confirmed_by_link: 'Já confirmei pelo link',
      insert_the_code_sent: 'Insira o código enviado',
      validate_your_registration_below: 'Valide seu cadastro abaixo.',
      little_missing: 'Falta pouco!',
      little_missing_to_change_password:
        'Falta pouco para você alterar sua senha!',
      confirmation_code_not_provided: 'Código de confirmação não informado',
      request_another_code: 'Solicitar outro código',
      complete_registration: 'Complete o cadastro',
      and_start_to_operate_system: 'e comece a operar o sistema FanPDV!',
      insert_yout_details: 'Preencha seus dados.',
      insert_your_new_password: 'Insira sua nova senha',
      passwords_dont_match: 'As senhas digitadas são diferentes.',
      you_have_no_permission:
        'Você não tem permissão para acessar o recurso solicitado.',
      email_or_password_is_incorrect: 'Email e/ou senha está incorreto',
      field_already_registered:
        'Já existe uma conta vinculada ao $t({{context}}) informado',
    },
    integration: {
      title: 'Integração',
      plural_title: 'Integrações',
      image: 'Imagem da integração',
      zoop_configuration: 'Configuração do Zoop',
      seller_id: 'Seller ID',
      fanapp_configuration: 'Configuração do FanApp',
      enotas_configuration: 'Configuração do ENotas',
      access_token: 'Access token',
      disable: 'Desabilitar integração',
      disabled: 'Integração desabilitada',
      accounting_email: 'Email contábil',
      id: 'Id',
      code: 'Código',
      production: 'Produção',
      homologation: 'Homologação',
      nfe_serie: 'Serie NFe',
      nfe_sequence: 'Sequência NFe',
      show_csc_config: 'Exibir configurações de CSC',
      disable_confirmation:
        'Tem certeza que deseja desabilitar a integração/módulo {{integrationName}}?',
      stock: 'Estoque',
    },
    general: {
      configuration: 'Configuração',
      publish: 'Publicar',
      publishing: 'Publicando',
      done: 'Concluído',
      conclude: 'Concluir',
      access: 'Acessar',
      loading: 'Carregando',
      edit: 'Editar',
      configure: 'Configure',
      save: 'Salvar',
      saving: 'Salvando',
      enable: 'Habilitar',
      disable: 'Desabilitar',
      sending: 'Enviando',
      send: 'Enviar',
      resend: 'Reenviar',
      disconnect: 'Desconectar',
      price_prefix: 'R$',
      reject: 'Rejeitar',
      accept: 'Aceitar',
      fanpdv_logo: 'FanPDV logo',
      welcome: 'Bem vindo',
      ops: 'Ops',
      avatar: 'Avatar',
      show: 'Exibir',
      hide: 'Ocultar',
      today: 'Hoje',
      tomorrow: 'Amanhã',
      yesterday: 'Ontem',
      update: 'Atualizar',
      enter: 'Entrar',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      back: 'Voltar',
      alert_image: 'Imagem de alerta',
      success: 'Sucesso!',
      error: 'Erro!',
      actions: 'Ações',
      drop_image_here: 'Solte a imagem aqui',
      drag_and_drop_file_or_click:
        'Arraste e solte um arquivo ou clique para adicionar',
      enabled: 'ativado',
      disabled: 'desativado',
      added_to: 'adicionado aos',
      removed_from: 'removido dos',
      see_all: 'Ver tudo',
      search: 'Pesquisar',
      back_icon: 'Ícone de voltar',
      eg: '(Ex: {{eg}})',
      select: 'Selecione...',
    },
    general_messages: {
      request_error: 'Ocorreu um erro com a requisição. Tente novamente.',
      page_not_found: 'Página não encontrada',
      url_does_not_exist:
        'O endereço solicitado não existe ou não está mais disponível',
      code_successfully_sent: 'Código enviado com sucesso!',
      was_removed: '{{gender}} $t({{context}}) foi removido.',
      was_updated: 'O $t({{context}}) foi atualizado.',
      was_saved: 'O $t({{context}}) foi salvo com sucesso.',
    },
    validation_messages: {
      required_field: 'O campo $t({{field}}) é obrigatório',
      out_of_the_pattern: 'O campo $t({{field}}) está fora do padrão requerido',
      only_letters_and_symbols:
        'O campo $t({{field}}) deve conter apenas letras e símbolos',
      invalid_field: 'O campo $t({{field}}) é inválido',
      invalid_date: 'O campo $t({{field}}) é uma data inválida',
      max_characters:
        'O campo $t({{field}}) deve conter no máximo {{maxLength}} caracteres',
      min_characters:
        'O campo $t({{field}}) deve conter no mínimo {{minLength}} caracteres',
      between_characters:
        'O campo $t({{field}}) deve conter no mínimo {{minLength}} e no máximo {{maxLength}} caracteres',
      must_be_in_format:
        'O campo $t({{field}}) deve estar no formato {{format}}',
      must_have_only_letters:
        'O campo $t({{field}}) deve conter somente letras',
      must_be_a_number: 'O campo $t({{field}}) deve conter apenas números',
      exact_characters:
        'O campo $t({{field}}) deve conter exatamente {{length}} caracteres',
      please_use_format: 'Favor usar o formato {{format}}',
      number_must_be_grater_than:
        'O campo $t({{field}}) precisa ser um número maior que {{length}}!',
      number_must_be_grater_than_field:
        'O campo $t({{grater}}) precisa ser um número superior ao campo $t({{smaller}})!',
      date_must_be_grater_than_field:
        'O campo $t({{grater}}) precisa ser uma data posterior ao campo $t({{smaller}})!',
      date_must_be_grater_than_today:
        'O campo $t({{grater}}) precisa ser uma data posterior a data de hoje!',
      date_must_be_smaller_than_field:
        'O campo $t({{smaller}}) precisa ser uma data anterior ao campo $t({{grater}})!',
      file_max_size:
        'Por favor selecione um arquivo com menos de {{maxFileSize}}mb.',
      password_pattern:
        'O campo Senha deve conter uma letra maiúscula, uma letra minúscula e um número.',
    },
    masks: {
      full_phone: '(+55) (99) 99999-9999',
      document: '99.999.999/9999-99',
      partner_document: '999.999.999-99',
      postal_code: '99999-999',
      date: '99/99/9999',
      date_time: '99/99/9999 99:99',
    },
    icons: {
      beer: 'cerveja',
      bubble_gum: 'chiclete',
      burguer: 'hambúrguer',
      caipirinha: 'caipirinha',
      candy: 'doce',
      champagne: 'champanhe',
      cigarrette: 'cigarro',
      cocktail: 'coquetel',
      combo: 'combo',
      dessert: 'sobremesa',
      distilled: 'destilado',
      drink: 'bebida',
      energetic: 'energético',
      finger_food: 'petisco',
      food: 'comida',
      gin: 'gin',
      juice: 'suco',
      liquor: 'licor',
      main_dish: 'prato principal',
      martini: 'martini',
      meat: 'carne',
      other: 'outros',
      pasta: 'massa',
      pizza: 'pizza',
      popsicle: 'picolé',
      seafood: 'frutos do mar',
      snacks: 'lanches',
      soda: 'soda',
      starter: 'entrada',
      sweet: 'doce',
      vermouth: 'vermute',
      vodka: 'vodka',
      water: 'agua',
      whisky: 'uísque',
      wine: 'vinho',
    },
    category_base: {
      absinthe: 'Absinto',
      cereal_bar: 'Barra de cereal',
      savory_snacks: 'Salgadinho',
      caipirinha: 'Caipirinha',
      campari: 'Campari',
      martini: 'Martini',
      rum: 'Rum',
      vodka: 'Vodka',
      tequila: 'Tequila',
      whisky: 'Uísque',
      cereal: 'Cereal',
      burguer: 'Hambúrguer',
      pizza: 'Pizza',
      salgado: 'Salgado',
      barra: 'Barra de cereal',
      candy: 'Doce',
      cocktail: 'Coquetel',
      dairy_beverage: 'Bebida láctea',
      cookie: 'Biscoito',
      cake: 'Bolo',
      hot_dog: 'Cachorro-quente',
      broth: 'Caldo',
      coffe: 'Café',
      meat: 'Carne',
      beer: 'Cerveja',
      champagne: 'Champanhe',
      bubble_gum: 'Chiclete',
      cigarrette: 'Cigarro',
      brandy: 'Conhaque',
      distilled: 'Destilado',
      energetic: 'Energético',
      sparkling_wine: 'Vinho espumante',
      starter: 'Entrada',
      sweet: 'Doce',
      snacks: 'Lanches',
      sea_food: 'Frutos do mar',
      gin: 'Gin',
      fruit: 'Fruta',
      liquor: 'Licor',
      pasta: 'Massa',
      grilled: 'Grelhado',
      other: 'Outros',
      fried_pastry: 'Pastel',
      finger_food: 'Petisco',
      popsicle: 'Picolé',
      main_dish: 'Prato principal',
      soda: 'Soda',
      dessert: 'Sobremesa',
      sandwich: 'Sanduíche',
      juice: 'Suco',
      pie: 'Torta',
      vermouth: 'Vermute',
      smoothie: 'Smoothie',
      wine: 'Vinho',
      water: 'Água',
    },
  },
};
