import styled from 'styled-components';
import { Input as AntdInput, Select } from 'antd';
import NumberFormat from 'react-number-format';
import { Card as CD } from '../styles';

export const Card = styled(CD)`
  position: relative;

  span.value {
    color: #101116;
    font-weight: 700;
    font-size: 19px;

    &.disabled {
      color: #848589;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  button {
    position: absolute;
    right: 10px;
    top: 20px;

    img {
      width: 15px;
    }
  }
`;

export const Input = styled(AntdInput)`
  margin-top: 5px;
  height: 20px;
  font-weight: 600;
`;

export const PriceField = styled(NumberFormat)`
  margin-top: 5px;
  height: 20px;
  font-weight: 600;
`;

export const CustomSelect = styled(Select)`
  width: 100%;
`;

export const SwitchContainer = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;

  & + & {
    border-top: 1px solid #c6c9ce;
  }

  span {
    font-weight: 700;
    font-size: 16px;
    color: #101116;
  }

  & + & {
    margin-top: 10px;
  }
`;

export const IntegrationCard = styled.div`
  background-color: white;
  box-shadow: 0px 5px 20px -5px rgba(133, 133, 133, 0.15);
  border-radius: 3px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: block;

    &:first-of-type {
      font-size: 20px;
      font-weight: 700;
      color: #101116;
      line-height: 20px;
    }

    &:last-of-type {
      line-height: 20px;
    }
  }

  > button {
    background: #e3e4e7;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: 0;
    outline: 0;
  }
`;
