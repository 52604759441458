import { memo, useState, useEffect, useCallback } from 'react';
import {
  PlusOutlined,
  HeartOutlined,
  HeartFilled,
  EllipsisOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import ImagePlaceholderIcon from 'assets/svg/fanapp-icon-image-placeholder.svg';

import Page from 'components/Page';
import DataTable from 'components/DataTable';

import api from 'services/api';

import { EstablishmentState } from 'interfaces/establishment';
import { notification, Switch, Menu } from 'antd';

import { defaultPageSize } from 'constants/styles';
import { Product, ProductImage } from 'interfaces/product';
import { useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import ConfirmModal from 'components/ConfirmModal';
import { translate } from 'config/i18n';

import { usePermissions } from 'hooks/usePermissions';
import { Modules } from 'constants/modules';
import { Roles } from 'constants/roles';
import { getCurrencyByLocale } from 'helpers/stringHelper';
import {
  Header,
  Button,
  Content,
  ImagePlaceholder,
  ImageContent,
  SwitchContainer,
  Dropdown,
  MenuItem,
  TableHeader,
  EllipsisOutlinedRotaded,
  ExcelIcon,
} from './styles';
import AddStockModal from './AddStockModal';
import RemoveStockModal from './RemoveStockModal';
import UpdateStockModal from './UpdateStockModal';

function Products() {
  const history = useHistory();
  const { has } = usePermissions();
  const [products, setProducts] = useState<Array<any>>([]);
  const [rawProducts, setRawProducts] = useState<Array<Product>>([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationEnd, setPaginationEnd] = useState(0);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [addStockModalVisible, setAddStockModalVisible] = useState(false);
  const [removeStockModalVisible, setRemoveStockModalVisible] = useState(false);
  const [updateStockModalVisible, setUpdateStockModalVisible] = useState(false);
  const { currentEstablishment } = useSelector<ReduxStore, EstablishmentState>(
    state => state.establishment,
  );

  const columns: any = [
    {
      title: translate('product.image'),
      dataIndex: 'image',
      sorter: false,
    },
    {
      title: translate('product.name'),
      dataIndex: 'name',
    },
    {
      title: translate('product.category'),
      dataIndex: 'productCategory',
    },
    {
      title: translate('product.price'),
      dataIndex: 'price',
    },
    {
      title: translate('product.favorite'),
      dataIndex: 'favorite',
      sorter: false,
    },
    {
      title: translate('product.status'),
      dataIndex: 'status',
      sorter: false,
    },
  ];

  if (
    has(
      [Modules.STOCK],
      [Roles.STOCKIST, Roles.ADMIN, Roles.OPERATIONAL_MANAGER],
    )
  ) {
    columns.push({
      title: translate('product.quantity'),
      dataIndex: 'stock',
    });
  }

  function getProductImage(image: ProductImage | undefined) {
    return image ? (
      <ImageContent>
        <img src={image.location} alt={translate('product.product_image')} />
      </ImageContent>
    ) : (
      <ImagePlaceholder>
        <img
          src={ImagePlaceholderIcon}
          alt={translate('product.product_image')}
        />
      </ImagePlaceholder>
    );
  }

  const handleChangeTable = useCallback(
    async (page?: number | undefined, pageSize?: number | undefined) => {
      try {
        setLoading(true);
        const response = await api.get(
          `trade/establishments/${currentEstablishment?.id}/products?page=${
            page || 1
          }&pageSize=${pageSize || defaultPageSize}`,
        );

        const total = response.headers['x-total'] || 0;
        const start = (page ? page - 1 : 0) * (pageSize || defaultPageSize) + 1;
        const end = start + (pageSize || defaultPageSize) - 1;
        setPaginationStart(start);
        setPaginationEnd(end > total ? total : end);
        setPaginationTotal(total);

        setRawProducts(response.data.data);
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      } finally {
        setLoading(false);
      }
    },
    [currentEstablishment],
  );

  const confirmDeleteProduct = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete(`trade/products/${selectedProduct?.id}`);
      handleChangeTable();
      setConfirmModalVisible(false);
      notification.success({
        message: translate('general.success'),
        description: translate('general_messages.was_removed', {
          context: 'product.title',
          gender: 'O',
        }),
      });
    } catch {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    }
  }, [selectedProduct, handleChangeTable]);

  const handleDeleteProduct = useCallback((product: Product) => {
    setSelectedProduct(product);
    setConfirmModalVisible(true);
  }, []);

  const handleActivateDeactivateProduct = useCallback(
    async (product: Product) => {
      try {
        setLoading(true);
        await api.post(
          `trade/products/${product?.id}/${
            product.disabledAt ? 'activate' : 'deactivate'
          }`,
        );
        handleChangeTable();
        notification.success({
          message: translate('general.success'),
          description: translate('product.was_enabled_disabled', {
            action: product.disabledAt ? 'general.enabled' : 'general.disabled',
          }),
        });
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      }
    },
    [handleChangeTable],
  );

  const handleFavoriteProduct = useCallback(
    async (product: Product) => {
      try {
        setLoading(true);
        await api.post(
          `trade/products/${product?.id}/${
            product.favorite ? 'unfavorite' : 'favorite'
          }`,
        );
        handleChangeTable();
        notification.success({
          message: translate('general.success'),
          description: translate('product.was_favorited', {
            action: product.favorite
              ? 'general.removed_from'
              : 'general.added_to',
          }),
        });
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      }
    },
    [handleChangeTable],
  );

  const GeneralDropDownMenu = useCallback(() => {
    const exportTable = async () => {
      try {
        const { data } = await api.get(
          `/trade/establishments/${currentEstablishment?.id}/stock/products/download`,
        );

        const filePath = data.data.location;
        const a = document.createElement('a');
        a.href = filePath;
        const lastIndex = filePath.lastIndexOf('/') + 1;
        a.download = filePath.substr(lastIndex);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch {
        notification.error({
          message: translate('general.error'),
          description: translate('general_messages.request_error'),
        });
      }
    };

    return (
      <Menu>
        <MenuItem onClick={exportTable}>
          <ExcelIcon />
          {translate('product.export')}
        </MenuItem>
      </Menu>
    );
  }, [currentEstablishment?.id]);

  const DropdownMenu = useCallback(
    product => (
      <Menu>
        {has(
          [Modules.STOCK],
          [Roles.STOCKIST, Roles.ADMIN, Roles.OPERATIONAL_MANAGER],
        ) && (
          <MenuItem
            onClick={() => {
              setAddStockModalVisible(true);
              setSelectedProduct(product);
            }}
          >
            {translate('product.add_stock')}
          </MenuItem>
        )}
        {has(
          [Modules.STOCK],
          [Roles.STOCKIST, Roles.ADMIN, Roles.OPERATIONAL_MANAGER],
        ) && (
          <MenuItem
            onClick={() => {
              setSelectedProduct(product);
              setRemoveStockModalVisible(true);
            }}
          >
            {translate('product.remove_stock')}
          </MenuItem>
        )}
        {has(
          [Modules.STOCK],
          [Roles.STOCKIST, Roles.ADMIN, Roles.OPERATIONAL_MANAGER],
        ) && (
          <MenuItem
            onClick={() => {
              setSelectedProduct(product);
              setUpdateStockModalVisible(true);
            }}
          >
            {translate('product.update_stock')}
          </MenuItem>
        )}

        {has([Modules.FISCAL], [Roles.ADMIN, Roles.OPERATIONAL_MANAGER]) && (
          <MenuItem
            onClick={() => {
              history.push(`/products/${product.id}/taxes`);
            }}
          >
            {translate('product.tax_management')}
          </MenuItem>
        )}

        {has([], [Roles.ADMIN, Roles.OPERATIONAL_MANAGER]) && (
          <MenuItem
            onClick={() => {
              history.push(`/products/update/${product.id}`);
            }}
          >
            {translate('general.edit')}
          </MenuItem>
        )}

        {has([], [Roles.ADMIN, Roles.OPERATIONAL_MANAGER]) && (
          <MenuItem onClick={() => handleDeleteProduct(product)}>
            {translate('product.delete')}
          </MenuItem>
        )}
      </Menu>
    ),
    [history, handleDeleteProduct, has],
  );

  const updateStock = (quantity: number) => {
    setRawProducts(
      rawProducts.map(x =>
        x.id === selectedProduct?.id ? { ...x, stock: quantity } : x,
      ),
    );
    setSelectedProduct(null);
  };

  useEffect(() => {
    async function getProductsFromServer() {
      handleChangeTable();
    }

    getProductsFromServer();
  }, [currentEstablishment, handleChangeTable]);

  useEffect(() => {
    setProducts(
      rawProducts.map((x: Product) => ({
        ...x,
        image: getProductImage(x.image),
        productCategory: x.productCategory?.name,
        price: getCurrencyByLocale(x.price / 100),
        stock: `${x.stock} uni`,
        favorite: (
          <button
            type="button"
            className="btn-clean"
            onClick={() => handleFavoriteProduct(x)}
          >
            {x.favorite ? (
              <HeartFilled className="favorite-icon" />
            ) : (
              <HeartOutlined className="favorite-icon" />
            )}
          </button>
        ),
        status: (
          <SwitchContainer>
            <Switch
              checked={!x.disabledAt}
              onChange={() => handleActivateDeactivateProduct(x)}
            />
          </SwitchContainer>
        ),
        actions: (
          <>
            <Dropdown
              trigger={['click']}
              overlay={() => DropdownMenu(x)}
              placement="bottomLeft"
            >
              <EllipsisOutlined className="actions-icon" />
            </Dropdown>
          </>
        ),
      })),
    );
  }, [
    rawProducts,
    handleDeleteProduct,
    handleActivateDeactivateProduct,
    handleFavoriteProduct,
    DropdownMenu,
  ]);

  function navigateToNewProduct() {
    history.push('/products/create');
  }

  return (
    <Page
      displayDrawer
      title={translate('product.plural_title')}
      tabs={[
        {
          label: translate('product.plural_title'),
          route: '/products',
        },
        {
          label: translate('product.categories'),
          route: '/products/product-categories',
        },
      ]}
      SubHeader={
        <Header>
          <Button
            onClick={navigateToNewProduct}
            htmlType="button"
            type="primary"
            size="middle"
          >
            <PlusOutlined /> {translate('product.create')}
          </Button>
        </Header>
      }
    >
      <Content>
        <TableHeader>
          <div className="page-counter">
            {paginationStart}-{paginationEnd} de {paginationTotal}
          </div>
          <Dropdown
            trigger={['click']}
            overlay={GeneralDropDownMenu}
            placement="bottomLeft"
          >
            <EllipsisOutlinedRotaded className="actions-icon" />
          </Dropdown>
        </TableHeader>
        <DataTable
          total={paginationTotal}
          loading={loading}
          onChange={handleChangeTable}
          columns={columns}
          dataSource={products}
          rowKey="id"
        />

        <ConfirmModal
          loading={loading}
          visible={confirmModalVisible}
          setVisible={setConfirmModalVisible}
          confirmText={translate('product.remove_confirmation', {
            productName: selectedProduct?.name,
          })}
          confirmCallback={confirmDeleteProduct}
        />
      </Content>

      {has(
        [Modules.STOCK],
        [Roles.STOCKIST, Roles.ADMIN, Roles.OPERATIONAL_MANAGER],
      ) && (
        <>
          <AddStockModal
            product={selectedProduct as Product}
            visible={addStockModalVisible}
            setVisible={setAddStockModalVisible}
            callback={updateStock}
          />

          <RemoveStockModal
            product={selectedProduct as Product}
            visible={removeStockModalVisible}
            setVisible={setRemoveStockModalVisible}
            callback={updateStock}
          />

          <UpdateStockModal
            product={selectedProduct as Product}
            visible={updateStockModalVisible}
            setVisible={setUpdateStockModalVisible}
            callback={updateStock}
          />
        </>
      )}
    </Page>
  );
}

export default memo(Products);
