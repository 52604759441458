import { useCallback, useState } from 'react';
import { Form, Row, Input, Checkbox } from 'antd';
import { UserState } from 'interfaces/user';
import { login, LoginInput } from 'store/ducks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from 'interfaces/reduxStore';
import { Store } from 'antd/lib/form/interface';
import PhoneInput from 'react-phone-input-2';
import { useQuery } from 'hooks/useQuery';
import { translate } from 'config/i18n';
import { usePermissions } from 'hooks/usePermissions';
import { SubmitButton } from '../styles';
import { FormTitle, ForgetPasswordLink } from './styles';

function PhoneForm(): JSX.Element {
  const dispatch = useDispatch();
  const { addLoggedUser } = usePermissions();
  const { clearCurrentEstablishment } = usePermissions();
  const query = useQuery();
  const { loading } = useSelector<ReduxStore, UserState>(state => state.auth);
  const [redirectUrl] = useState<string | null>(query.get('redirect'));

  const handleForm = useCallback(
    ({ phone, password, persist }: Store) => {
      const input: LoginInput = {
        phone: `+${phone}`,
        password,
      };

      clearCurrentEstablishment();
      dispatch(
        login(input, persist, user => {
          if (redirectUrl) window.location.href = redirectUrl;
          addLoggedUser(user);
        }),
      );
    },
    [dispatch, redirectUrl, clearCurrentEstablishment, addLoggedUser],
  );

  return (
    <>
      <FormTitle>
        {translate('auth.log_in_using')}
        <strong>{translate('auth.phone')}</strong>
      </FormTitle>
      <Form onFinish={handleForm}>
        <Form.Item
          name="phone"
          validateFirst
          rules={[
            {
              required: true,
              message: translate('validation_messages.required_field', {
                field: 'auth.phone',
              }),
              validateTrigger: 'onBlur',
            },
            {
              min: 12,
              message: translate('auth.phone_min_length', { minLength: 12 }),
              validateTrigger: 'onBlur',
            },
          ]}
        >
          <PhoneInput country="br" inputClass="ant-input" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: translate('validation_messages.required_field', {
                field: 'auth.password',
              }),
              validateTrigger: 'onBlur',
            },
            {
              min: 6,
              message: translate('validation_messages.min_characters', {
                field: 'auth.password',
                minLength: 6,
              }),
              validateTrigger: 'onBlur',
            },
          ]}
        >
          <Input.Password placeholder="Senha" />
        </Form.Item>

        <Row justify="space-between" align="middle">
          <Form.Item valuePropName="checked" name="persist">
            <Checkbox>{translate('auth.keep_connected')}</Checkbox>
          </Form.Item>

          <ForgetPasswordLink href={`/forgot-password?redirect=${redirectUrl}`}>
            {translate('auth.forgot_password')}
          </ForgetPasswordLink>
        </Row>

        <Row justify="center">
          <SubmitButton
            disabled={loading}
            htmlType="submit"
            type="primary"
            size="large"
          >
            {loading
              ? translate('general.sending')
              : translate('general.enter')}
          </SubmitButton>
        </Row>
      </Form>
    </>
  );
}

export default PhoneForm;
