import { memo, useState, useCallback } from 'react';
import { Col, Input, notification, Row } from 'antd';

import api from 'services/api';
import { Product } from 'interfaces/product';
import { translate } from 'config/i18n';
import { gutter } from 'constants/styles';
import TextArea from 'antd/lib/input/TextArea';
import { Modal, Button, Label } from './styles';

interface ModalProps {
  product: Product;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  callback?: (quantity: number) => void;
}

function UpdateStockModal({
  product,
  visible,
  setVisible,
  callback,
}: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState('');
  const [note, setNote] = useState('');

  const resetFields = useCallback(() => {
    setQuantity('');
    setNote('');
  }, []);

  const handleSubmit = useCallback(async () => {
    let error = false;

    if (!quantity) {
      error = true;
      notification.error({
        message: translate('general.error'),
        description: translate('validation_messages.required_field', {
          field: 'product.unit_quantity',
        }),
      });
      return;
    }

    if (!note) {
      error = true;
      notification.error({
        message: translate('general.error'),
        description: translate('validation_messages.required_field', {
          field: 'product.note',
        }),
      });
    }

    if (error) return;

    try {
      setLoading(true);

      await api.post(`trade/products/${product.id}/stock/update`, {
        quantity,
        note,
      });

      if (callback) callback(Number(quantity));

      notification.success({
        message: translate('general.success'),
        description: translate('product.stock_success_message'),
      });
      setVisible(false);
    } catch (err) {
      notification.error({
        message: translate('general.error'),
        description: translate('general_messages.request_error'),
      });
    } finally {
      setLoading(false);
    }
  }, [setVisible, product, callback, quantity, note]);

  const setOnlyNumber = (
    e: React.FormEvent<HTMLInputElement>,
    callbackFN: (value: string) => void,
  ) => {
    const re = /^[0-9\b]+$/;

    if (e.currentTarget.value === '' || re.test(e.currentTarget.value)) {
      callbackFN(e.currentTarget.value);
    }
  };

  return (
    <Modal
      title=""
      footer={false}
      visible={visible}
      afterClose={resetFields}
      destroyOnClose
      closable
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="modal-header">{translate('product.update_stock')}</div>
      <div className="modal-content">
        <Row gutter={gutter}>
          {product?.image && (
            <Col sm={12}>
              <div className="image-container">
                <img
                  src={product?.image?.location}
                  alt={translate('product.product_image')}
                />
                <div>
                  <span>{product?.name}</span>
                  <span>{product?.productCategory?.name}</span>
                </div>
              </div>
            </Col>
          )}
          <Col sm={product?.image ? 12 : 24}>
            <Label>{translate('product.unit_quantity')}</Label>
            <Input
              placeholder={translate('product.unit_quantity')}
              value={quantity}
              onChange={e => {
                setOnlyNumber(e, setQuantity);
              }}
            />

            <Label>{translate('product.note')}</Label>
            <TextArea
              value={note}
              onChange={e => {
                setNote(e.target.value);
              }}
              maxLength={255}
            />
          </Col>
        </Row>
      </div>
      <div className="align-center">
        <Button
          onClick={handleSubmit}
          disabled={loading}
          htmlType="button"
          type="primary"
          size="large"
        >
          {loading
            ? `${translate('general.loading')}...`
            : translate('general.done')}
        </Button>
      </div>
    </Modal>
  );
}

export default memo(UpdateStockModal);
