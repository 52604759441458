import styled from 'styled-components';
import { Tabs as DefaultTabs } from 'antd';

export const TabBarItem = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
`;

export const Tabs = styled(DefaultTabs)`
  margin-top: 3rem;
  display: block;
  padding-bottom: 2.5rem;

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 100%;
    color: #c5c5c5;
    display: inline-block;
    text-align: center;
  }

  .ant-tabs-ink-bar-animated.ant-tabs-ink-bar {
    height: 3px;
  }

  .ant-tabs-tab-active > .ant-tabs-tab-btn {
    color: @heading-color;
  }

  .ant-tabs-nav::before {
    display: none;
  }
`;

export const FormTitle = styled.h2`
  font-weight: 300;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;
