import styled from 'styled-components';

export const CodeContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  justify-content: space-between;
`;

export const CharacterInput = styled.input.attrs({
  type: 'text',
  maxLength: 1,
})`
  border: none;
  text-transform: uppercase;
  border-bottom: 1px solid @text-color-secondary;
  padding-bottom: 4px;
  text-align: center;
  font-size: 2rem;
  width: 13%;
  &:focus {
    border-bottom: 1px solid @primary-color;
    outline: none;
  }
`;
