import { AxiosResponse } from 'axios';
import { notification } from 'antd';

interface IRequestError {
  status?: number;
  message: string;
  response?: AxiosResponse;
}

export const handleAPIError = (error: IRequestError | any): string => {
  const { response, message } = error;

  if (response?.data?.error) {
    return response.data.error.message;
  }

  if (!message) return 'Erro inesperado!';
  if (message === 'Network Error' || message.includes('timeout')) {
    return 'Não foi possível acessar o servidor, por favor verifique sua conexão';
  }

  return 'Erro inesperado!';
};

export const showError = (errorMessage: string): void => {
  notification.error({
    message: 'Erro',
    description: errorMessage,
  });
};
